import { MuiThemeProvider, createTheme } from "@material-ui/core";
import { Box } from "@mui/system";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearcheableSelect from "../form/formComponents/SearcheableSelect";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "../form/formComponents/DatePicker";
import Point from "../form/formComponents/Point";
import WsConnector from "../../utils/WsConnector";
import Crone from "../Crone";
import ModalDialog from "../meters/ModalDialog";

import CampaignMapContainer from "./CampaignMapContainer";
import { actions } from "../../redux/slices/iwdWebSocketSlice";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { v4 as uuidv4 } from "uuid";
import { store } from "../../redux/store";
import { size } from "lodash";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        if (i == 5 || i == 4) {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const algoritmsEnum = [{ const: "Spiral" }, { const: "GeoJson" }];

const CampaignCreate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [helperModal, setHelperModal] = useState(false);
  const muiTheme = createTheme({
    props: {
      MuiTextField: {
        variant: "outlined",
      },
    },
  });
  const schm = {
    title: "New Campaign",
    type: "object",
    properties: {
      digital_twin: {
        $id: "1",

        mapFn: (result) =>
          result?.data?.data?.map((item) => ({
            key: item.attributes?.id,
            label: `${item.attributes?.name} (${item.attributes?.version})`,
            value: item.attributes?.id,
          })),
        /*    stateSelector: classStateSelector, */

        url: "altior/digital_twin",
        type: "number",
        title: t("digital_twin"),
        /*   dispatched: true, */
      },
      name: {
        $id: "2",
        type: "string",
        title: t("name"),
      },
      insertion_date: {
        $id: "3",
        title: t("inserted_at"),
        type: "object",

        properties: {
          from: {
            type: "string",
          },
          to: {
            type: "string",
          },
        },
      },
      devices: {
        $id: "4",
        type: "number",
        title: t("device_num"),
      },
      /*       algoritm: {
        $id: "5",
        title: t("algoritm"),
        type: "string",
        oneOf: [...algoritmsEnum],
      }, */
      crone: {
        $id: "10",
        title: t("crone"),
        type: "string",
      },
      map: {
        type: "object",
        properties: {
          lat: {
            $id: "11",
            type: "number",
          },
          lng: {
            $id: "12",
            type: "number",
          },
          coverage: {
            $id: "13",
            typ: "number",
          },
        },
      },
    },
    required: [
      "digital_twin",
      "name",
      "insertion_date",
      "devices",
      "crone",
      "map",
    ],
  };
  const onHelpeIconClick = () => {
    setHelperModal(!helperModal);
  };
  const fields = {
    search: SearcheableSelect,
    datepicker: DatePicker,
    point: Point,
    crone: Crone,
    map: CampaignMapContainer,
  };
  const uiSchm = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    digital_twin: {
      "ui:field": "search",
    },
    "ui:submitButtonOptions": {
      submitText: t("create"),
    },
    insertion_date: {
      "ui:field": "datepicker",
    },
    center: {
      "ui:field": "point",
    },
    crone: {
      "ui:field": "crone",
    },
    map: {
      "ui:field": "map",
    },
  };

  const [formData, setFormData] = useState({});
  const [schema, setSchema] = useState(schm);
  const [isSubmited, setIsSubmited] = useState(false);
  const navigate = useNavigate();
  const [monthInterval, setMonthInterval] = useState(null);
  const [uiSchema, setUiSchema] = useState(uiSchm);
  const [id] = useState(uuidv4());
  const [postCampaign] = store.usePostCampaignMutation();
  const onSubmitClick = () => {
    const attributes = {
      algorithm: "spiral",
      digital_twin_id: formData.digital_twin,
      digital_twin_name: "nbiot_integra",
      name: formData.name,
      number_of_devices: formData.devices,
      push_scheduler: formData.crone,
      start_date_time: formData.insertion_date.from,
      end_date_time: formData.insertion_date.to,
      target_reach_time: 3600,
      position: { latitude: formData.map.lat, longitude: formData.map.lng },
      coverage: formData.map.coverage,
      uuid: id,
    };
    postCampaign(attributes);
    setIsSubmited(true);
  };

  const { data: dataResultWs } = useSelector(
    (state) => state.iwdWebSocketSlice
  );

  useEffect(() => {
    if (dataResultWs?.operation_result?.[1]?.id && isSubmited) {
      navigate(`/campaigns/${dataResultWs?.operation_result[1]?.id}`);

      setIsSubmited(false);
    }
  }, [dataResultWs?.operation_result?.[1]?.id, isSubmited]);

  useEffect(() => {}, [dataResultWs]);
  useEffect(() => {}, [formData]);
  useEffect(() => {
    const props = { ...schema.properties };
    if (formData.algoritm === "Spiral") {
      delete props["noname"];
      props["center"] = {
        $id: "6",
        type: "object",
        title: t("center"),
        properties: {
          lat: {
            $id: "7",
            type: "number",
            default: 0,
          },
          lng: {
            $id: "8",
            type: "number",
            default: 0,
          },
        },
      };
      props["coverage"] = {
        $id: "9",
        title: t("coverage"),
        type: "number",
      };
      const newSchema = { ...schema, properties: props };
      setSchema(newSchema);
    }
    if (formData.algoritm === "GeoJson") {
      delete props["center"];
      delete props["coverage"];
      props["noname"] = {
        $id: "10",
        title: "noname",
        type: "string",
      };
      const newSchema = { ...schema, properties: props };
      setSchema(newSchema);
    }
  }, [formData.algoritm]);
  const customValidate = (formData, errors) => {
    if (!formData?.map?.lat) {
      errors?.map?.lat?.addError("is a required property");
    }
    if (!formData?.map?.lng) {
      errors?.map?.lng?.addError("is a required property");
    }
    if (!formData.insertion_date.from) {
      errors?.insertion_date?.from?.addError("is a required property");
    }
    if (!formData.insertion_date.to) {
      errors?.insertion_date?.to?.addError("is a required property");
    }
    if (formData.insertion_date.from >= formData.insertion_date.to) {
      errors.insertion_date.to.addError(t("date_error"));
    }
    if (formData?.devices > 30) {
      errors.devices.addError("Max number of devices is 30");
    }
    if (monthInterval > process.env.REACT_APP_MONTH_INTERVAL) {
      errors.insertion_date.to.addError(
        `Interval between date of start and date of end can't be bigger than ${process.env.REACT_APP_MONTH_INTERVAL} month`
      );
    }
    return errors;
  };
  const monthDiff = (d1, d2) => {
    let months;
    const startDate = new Date(d1);
    const endDate = new Date(d2);
    months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months -= startDate.getMonth();
    months += endDate.getMonth();

    setMonthInterval(months);
  };
  useEffect(() => {
    if (formData?.insertion_date?.from && formData?.insertion_date?.to) {
      monthDiff(formData?.insertion_date?.from, formData?.insertion_date?.to);
    }
  }, [formData?.insertion_date?.to, formData?.insertion_date?.from]);

  useEffect(() => {
    return () => {
      dispatch(actions.cleanData("operation_result"));
    };
  }, []);

  return (
    <>
      <HelpOutlineIcon
        sx={{ float: "right", mb: 2 }}
        onClick={onHelpeIconClick}
      />
      <WsConnector
        style={{ float: "right", mr: 1 }}
        channelName={"plugin:campaigns"}
        channelParams={{ uuid: id }}
        eventName={"operation_result"}
      />

      <ModalDialog close={onHelpeIconClick} open={helperModal}>
        <ul>
          <li>
            <b>Devices:</b> Maximum number of devices is 30
          </li>
          <li>
            <b>Date:</b> Interval beetwen <b>Start date</b> and <b>End date</b>{" "}
            can't be bigger than 3 month
          </li>
        </ul>
      </ModalDialog>
      <ModalDialog open={isSubmited && !dataResultWs?.[1]?.id}>
        <Box>
          Campaign definition <CircularProgress sx={{ ml: 2 }} size={25} />
        </Box>
      </ModalDialog>
      <Box>
        <Form
          schema={schema}
          uiSchema={uiSchema}
          fields={fields}
          formData={formData}
          onSubmit={onSubmitClick}
          onChange={(changeEvent) => setFormData(changeEvent.formData)}
          validator={validator}
          customValidate={customValidate}
          showErrorList={false}
          noHtml5Validate
        />
      </Box>
    </>
  );
};
export default CampaignCreate;
