import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import { TextField, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { useTranslation } from "react-i18next";
import { setSeverity } from "../../redux/slices/snackbarSlice";
import { store } from "../../redux/store";
import FormControl from "@mui/material/FormControl";
import { useParams } from "react-router-dom";
import Select from "@mui/material/Select";
import { TextareaAutosize } from "@mui/base";

const MeterCommands = ({ digitalTwinId }) => {
  const commands = useSelector((state) => state?.meterSlice?.commands)
    ?.map((item) => ({
      label: item?.attributes?.name,
      type: item?.attributes?.type,
    }))
    ?.filter((cmd) => !window?.DISABLED_COMMANDS?.includes(cmd.label));
  const { t } = useTranslation();
  const gatewayTwinId = useSelector((state) => state?.gatewaySlice?.twinId);
  const [getCommands] = store.useLazyGetMeterAttributesQuery();
  const attributes = useSelector((state) => state?.meterSlice?.commands);
  const [postCommands, { isSuccess: postSuccess }] =
    store.usePostCommandsMutation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [command, setCommand] = React.useState("");
  const [commandValue, setCommandValue] = React.useState("");
  const smallField =
    command?.label === "set_valve_angle" ||
    command?.label == "set_valve_angle_unsafe";
  const handleChange = (e) => {
    setCommand(e.target.value);
  };
  const handleValueChange = (e) => {
    setCommandValue(e.target.value);
  };
  React.useEffect(() => {
    if (postSuccess) {
      dispatch(setSeverity("success"));
      dispatch(setMessage(t("demo_succ_msg")));
    }
  }, [postSuccess]);
  const validateJson = (obj) => {
    if (command?.type !== "void") {
      try {
        const jsonObject = JSON.parse(obj);
        const attributes = {
          values: JSON.parse(commandValue),
        };
        postCommands({
          attributes: attributes,
          commandName: command?.label,
          digitalTwinId: digitalTwinId ?? gatewayTwinId,
          digitalTwinInstance: id,
        });
      } catch (error) {
        console.error("Invalid JSON:", error.message);
        dispatch(setSeverity("warning"));
        dispatch(setMessage("JSON NOT VALID"));
      }
    } else {
      const attributes = {
        values: {},
      };
      postCommands({
        attributes: attributes,
        commandName: command?.label,
        digitalTwinId: digitalTwinId ?? gatewayTwinId,
        digitalTwinInstance: id,
      });
    }
  };
  const integerType = () => {
    const attributes = {
      values: commandValue,
    };
    postCommands({
      attributes: attributes,
      commandName: command?.label,
      digitalTwinId: digitalTwinId ?? gatewayTwinId,
      digitalTwinInstance: id,
    });
  };
  const onSubmitClick = () => {
    validateJson(commandValue);
  };
  React.useEffect(() => {
    getCommands(digitalTwinId ?? gatewayTwinId);
  }, [digitalTwinId]);
  React.useEffect(() => {}, [commands]);
  return (
    <>
      {commands && (
        <Box sx={{ mt: 4, height: "58vh" }}>
          <Autocomplete
            fullWidth
            disablePortal
            value={command}
            onChange={(event, newValue) => {
              setCommand(newValue);
            }}
            id="combo-box-demo"
            options={commands ?? []}
            renderInput={(params) => <TextField {...params} label="Command" />}
          />
          <Box
            sx={{
              height: smallField ? "8vh" : "48vh",
              overflowY: "auto",
              marginTop: 5,
            }}
          >
            {command?.label === "set_valve_angle" ||
            command?.label == "set_valve_angle_unsafe" ? (
              <TextField
                value={commandValue}
                onChange={(e) => handleValueChange(e)}
                fullWidth
                type="number"
                error={commandValue > 100}
                helperText={commandValue > 100 && t("percent_error")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
            ) : (
              <>
                {command?.type !== "void" && (
                  <TextareaAutosize
                    value={commandValue}
                    disabled={command?.type === "void"}
                    minRows={30}
                    onChange={(e) => handleValueChange(e)}
                    style={{
                      width: "100%",
                      marginTop: 5,

                      resize: "none",
                    }}
                    /*  placeholder="Command value" */
                    label="Command value"
                  />
                )}
              </>
            )}
          </Box>
          <Button
            disabled={!command || (smallField && commandValue > 100)}
            onClick={onSubmitClick}
            sx={{ float: "right" }}
            variant="contained"
          >
            {t("send_command")}
          </Button>
        </Box>
      )}
    </>
  );
};
export default MeterCommands;
