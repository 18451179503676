import { useState, useEffect } from "react";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { store } from "../redux/store";
import { Grid, Box, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import SingleDatePicker from "../components/form/formComponents/SingleDatePicker";
import { useMediaQuery } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import {
  getDateDifferenceInDays,
  getDifferenceInDays,
} from "../utils/api_params";
import theme from "../theme";
import MetersTableTab from "../components/form/formComponents/MetersTableTab";
import FormAccordion from "../components/form/formComponents/Accordion";
import Chip from "@mui/material/Chip";
import { StyledDataGrid } from "./StyledDataGrid";
import DraftsIcon from "@mui/icons-material/Drafts";
import IconButton from "@mui/material/IconButton";
import { IwdAuthWrapper } from "../utils/IwdAuthWrapper";
import { objectFieldTemplate } from "../utils";
import ModalDialog from "../components/meters/ModalDialog";
import IwdDataGrid from "./IwdDataGrid";
import BlockMetersTableTabs from "../components/form/formComponents/BlockMetersTableTabs";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
const ApartamentReadingTab = ({ condominiumId }) => {
  const [colorMeters, setColorMeters] = useState([]);
  const [waterSorts, setWaterSorts] = useState([]);
  const [heatMeters, setHeatMeters] = useState([]);
  const [coldMeters, setColdMeters] = useState([]);
  const [savedToDate, setSavedToDate] = useState(null);
  const [savedFromDate, setSavedFromDate] = useState(null);
  const [readySort, setReadySort] = useState(false);
  const [afsMeters, setAfsMeters] = useState([]);
  const [acsMeters, setAcsMeters] = useState([]);
  const [heatCostMeters, setHeatCostMeters] = useState([]);
  const [readyToShow, setReadyToShow] = useState(false);
  const subCondominiumIdentifier = useSelector(
    (state) => state?.condominiumSlice?.subCondominiumIdentifier
  );
  const [firstRender, setFirstRender] = useState(true);
  const [meterType, setMeterType] = useState(["AFS"]);
  const apartamentName = useSelector(
    (state) => state?.condominiumSlice?.apartamentName
  );
  const [filteredCondominiumReadings, setFilteredCondomoniumReadings] =
    useState();
  const condominiumIdentifier = useSelector(
    (state) => state?.condominiumSlice?.condominiumIdentifier
  );
  const subCondominiumName = useSelector(
    (state) => state?.condominiumSlice?.subCondominiumName
  );
  const is1280x720 = useMediaQuery(
    "(max-width: 1280px) and (max-height: 720px)"
  );
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const [messageModal, setMessageModal] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [message, setMessage] = useState("");
  const apartamentIdentifier = useSelector(
    (state) => state?.condominiumSlice?.apartamentIdentifier
  );
  const apartamentReadings = useSelector(
    (state) => state?.condominiumSlice?.apartamentReadings
  );
  const apartamaentReadingsCount = useSelector(
    (state) => state?.condominiumSlice?.apartamaentReadingsCount
  );
  /*   const apartamentReadingsSelector = () =>{
   switch(meterType){
    case "pisya"
   }
  } */

  const apartments = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )
    ?.children?.map((item) => item)
    ?.map((item) => item?.children)
    .flat();

  const mappedApartments = {};
  apartments.forEach((obj) => {
    const identifier = obj?.attributes?.identifier;
    mappedApartments[identifier] = obj;
  });
  const condominiumRadingsFilter = () => {
    /*     switch (meterType) {
      case meterType[0] === "CALORIE / FRIGORIE":
        return 
    } */
    if (meterType?.length > 0 && meterType?.[0] === "CALORIE / FRIGORIE") {
      const filteredReadings = apartamentReadings?.filter((item) =>
        colorMeters?.includes(item?.attributes?.values?.device_identifier)
      );
      setFilteredCondomoniumReadings(filteredReadings);
    }
    /*  if (
      meterType?.[0] === "CALORIE / FRIGORIE" &&
      meterType?.[1] === "Calorie"
    ) {
      const filteredHeatReadings = apartamentReadings?.filter((item) =>
        heatMeters?.includes(item?.attributes?.values?.device_identifier)
      );
      const filteredReadings = apartamentReadings?.filter((item) =>
        colorMeters?.includes(item?.attributes?.values?.device_identifier)
      );

      setFilteredCondomoniumReadings([
        ...filteredReadings,
        ...filteredHeatReadings,
      ]);
    }
    if (
      meterType?.[0] === "CALORIE / FRIGORIE" &&
      meterType?.[1] === "Frigorie"
    ) {
      const filteredColdReadings = apartamentReadings?.filter((item) =>
        coldMeters?.includes(item?.attributes?.values?.device_identifier)
      );
      const filteredReadings = apartamentReadings?.filter((item) =>
        colorMeters?.includes(item?.attributes?.values?.device_identifier)
      );
      setFilteredCondomoniumReadings([
        ...filteredReadings,
        ...filteredColdReadings,
      ]);
    } */
    if (meterType?.[0] === "ACS") {
      const filteredReadings = apartamentReadings?.filter((item) =>
        acsMeters?.includes(item?.attributes?.values?.device_identifier)
      );
      setFilteredCondomoniumReadings(filteredReadings);
    }
    if (meterType?.[0] === "AFS") {
      const filteredReadings = apartamentReadings?.filter((item) =>
        afsMeters?.includes(item?.attributes?.values?.device_identifier)
      );
      setFilteredCondomoniumReadings(filteredReadings);
    }
    if (meterType?.[0] === "RIPARTITORE") {
      const filteredReadings = apartamentReadings?.filter((item) =>
        heatCostMeters?.includes(item?.attributes?.values?.device_identifier)
      );
      setFilteredCondomoniumReadings(filteredReadings);
    }
  };
  const meters = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )
    ?.children?.map((item) => item)
    ?.map((item) => item?.children)
    .flat()
    ?.map((item) => item?.children)
    .flat();

  useEffect(() => {
    if (!apartamentReadings) {
      setFilteredCondomoniumReadings([]);
    } else {
      condominiumRadingsFilter();
    }
  }, [meterType, apartamentReadings]);
  const allMeters = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )
    ?.children?.map((item) => item)
    ?.map((item) => item?.children)
    .flat()
    ?.map((item) => item?.children)
    .flat();
  const mappedMeters = {};
  allMeters.forEach((obj) => {
    const identifier = obj?.attributes?.identifier;
    mappedMeters[identifier] = obj;
  });
  const allPulsMeter = allMeters
    ?.map((item) => item?.children)
    .flat()
    ?.filter((item) => item?.attributes?.serial_number.includes("-"));
  const pulsMetersIdId = allPulsMeter?.map(
    (item) => item?.attributes?.identifier
  );
  const meterIdCollector = () => {
    const combinedMeters = [...meters, ...allPulsMeter];

    setColorMeters(
      combinedMeters
        ?.filter(
          (item) =>
            item?.attributes?.meter_type === "CALORIE / FRIGORIE" ||
            item?.attributes?.meter_type === "CALORIE/FRIGORIE" ||
            item?.attributes?.meter_type === "Calorie" ||
            item?.attributes?.meter_type === "Frigorie"
        )
        ?.map((item) => item?.attributes?.identifier)
    );

    /*     setHeatMeters(
      combinedMeters
        ?.filter
(
(item) => item.attributes?.meter_type === "Calorie")
        ?.map((item) => item?.attributes?.identifier)
    );

    setColdMeters(
      combinedMeters
        ?.filter
(
(item) => item.attributes?.meter_type === "Frigorie")
        ?.map((item) => item?.attributes?.identifier)
    ); */
    setAcsMeters(
      combinedMeters
        ?.filter((item) => item?.attributes?.meter_type === "ACS")
        ?.map((item) => item?.attributes?.identifier)
    );
    setAfsMeters(
      combinedMeters
        ?.filter((item) => item?.attributes?.meter_type === "AFS")
        ?.map((item) => item?.attributes?.identifier)
    );
    setHeatCostMeters(
      combinedMeters
        ?.filter((item) => item?.attributes?.meter_type === "RIPARTITORE")
        ?.map((item) => item?.attributes?.identifier)
    );
  };
  useEffect(() => {
    meterIdCollector();
  }, []);
  const meterTypeSelector = (item) => {
    switch (item?.type) {
      case "08":
      case "8":
        if (item?.hasOwnProperty("last_reading")) {
          if (item?.last_reading?.value === null) {
            return item;
          } else return null;
        } else {
          return [];
        }
      case "6":
      case "06":
      case "7":
      case "07":
      case "21":
      case "15":
      case "22":
      case "16":
        if (item?.hasOwnProperty("last_reading")) {
          if (item?.last_reading?.value === null) {
            return item;
          } else return null;
        } else {
          return [];
        }
      case "10":
      case "0A":
      case "11":
      case "0B":
      case "12":
      case "0C":
      case "13":
      case "0D":
        if (
          item?.hasOwnProperty("cold_last_reading") ||
          item.hasOwnProperty("heat_last_reading")
        ) {
          if (
            item?.cold_last_reading?.value === null &&
            !item?.heat_last_reading?.value === null
          ) {
            return item;
          } else return null;
        } else {
          return [];
        }
    }
  };
  const nullMeter = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  )
    ?.children?.map((item) => item)
    ?.map((item) => item?.children)
    .flat()
    ?.map((item) => item?.children)
    .flat()
    ?.filter((item) => meterTypeSelector(item?.attributes))

    ?.map((item) => ({
      children: item?.children,
      attributes: {
        device_id: item?.attributes?.identifier,
        meter_type: item?.attributes?.meter_type,
        values: {
          serial_number: item?.attributes?.serial_number,

          values: {
            apartment_name: item?.attributes?.apartment_name,
            condominium_name: item?.attributes?.condominium_name,
            sub_condominium_name: item?.attributes?.sub_condominium_name,
            internal:
              mappedApartments[item?.attributes?.apartment_id]?.attributes
                ?.internal,
            flat: mappedApartments[item?.attributes?.apartment_id]?.attributes
              ?.flat,
            room: mappedMeters[item?.attributes?.identifier]?.attributes?.room,
          },
        },
      },
    }))
    ?.filter(
      (item) =>
        item?.attributes?.values?.values?.apartment_name === apartamentName
    );
  const nullPulsMeters = nullMeter
    ?.map((item) =>
      item?.children?.filter(
        (item) => item?.attributes?.meter_type && !item?.last_reading?.value
      )
    )
    .flat()
    ?.map((item) => {
      const actualMeter = nullMeter?.filter((nm) =>
        item?.attributes?.serial_number.includes(
          nm?.attributes?.values?.serial_number
        )
      );
      return {
        children: item?.children,
        attributes: {
          device_id: item?.attributes?.identifier,
          meter_type: item?.attributes?.meter_type,
          values: {
            serial_number: item?.attributes?.serial_number,
            values: {
              apartment_name:
                actualMeter?.[0]?.attributes?.values?.values?.apartment_name,
              condominium_name:
                actualMeter?.[0]?.attributes?.values?.values?.condominium_name,
              sub_condominium_name:
                actualMeter?.[0]?.attributes?.values?.values
                  ?.sub_condominium_name,
            },
          },
        },
      };
    });
  const filteredNullMeters = nullMeter?.filter((item) =>
    meterType.includes(item?.attributes?.meter_type)
  );
  const filteredNullPulsMeters = nullPulsMeters?.filter((item) =>
    meterType.includes(item?.attributes?.meter_type)
  );
  const mappedFilteredReadings = filteredCondominiumReadings?.map((item) => {
    if (
      item &&
      item.attributes &&
      item.attributes.values &&
      item.attributes.values.values
    ) {
      return {
        ...item,
        attributes: {
          ...item.attributes,
          values: {
            ...item.attributes.values,
            values: {
              ...item.attributes.values.values,
              internal:
                mappedApartments[item?.attributes?.values?.values?.apartment_id]
                  ?.attributes?.internal,
              flat: mappedApartments[
                item?.attributes?.values?.values?.apartment_id
              ]?.attributes?.flat,
              room: mappedMeters[item?.attributes?.values?.device_identifier]
                ?.attributes?.room,
            },
          },
        },
      };
    }
    // Возвращение оригинального объекта, если какие-то свойства отсутствуют
    return item;
  });
  const allMeterIds = allMeters?.map((item) => item?.attributes?.identifier);
  const filteredReadingsIds = filteredCondominiumReadings?.map(
    (item) => item?.attributes?.values?.device_identifier
  );
  const newNullMeterIds = allMeterIds?.filter(
    (item) => !filteredReadingsIds?.includes(item)
  );
  const newNullMeters = newNullMeterIds.reduce((acc, key) => {
    if (mappedMeters.hasOwnProperty(key)) {
      acc[key] = mappedMeters[key];
    }
    return acc;
  }, {});
  const newPulsMetersIds = pulsMetersIdId?.filter(
    (item) => !filteredReadingsIds?.includes(item)
  );
  const mappedPulsMeters = {};
  allPulsMeter.forEach((obj) => {
    const identifier = obj?.attributes?.identifier;
    mappedPulsMeters[identifier] = obj;
  });
  const newNullPulsMeters = newPulsMetersIds?.reduce((acc, key) => {
    if (mappedPulsMeters.hasOwnProperty(key)) {
      acc[key] = mappedPulsMeters[key];
    }
    return acc;
  }, {});
  const mappedNullPulsMeters =
    apartamentReadings &&
    Object.values(newNullPulsMeters)
      ?.map((item) => ({
        children: item?.children,
        attributes: {
          device_id: item?.attributes?.identifier,
          meter_type: item?.attributes?.meter_type,
          values: {
            serial_number: item?.attributes?.serial_number,

            values: {
              apartment_name: item?.attributes?.apartment_name,
              condominium_name: item?.attributes?.condominium_name,
              sub_condominium_name: item?.attributes?.sub_condominium_name,
              internal:
                mappedApartments[item?.attributes?.apartment_id]?.attributes
                  ?.internal,
              flat: mappedApartments[item?.attributes?.apartment_id]?.attributes
                ?.flat,
              room: mappedMeters[item?.attributes?.identifier]?.attributes
                ?.room,
            },
          },
        },
      }))
      ?.filter(
        (item) =>
          meterType.includes(item?.attributes?.meter_type) &&
          item?.attributes?.values?.values?.apartment_name === apartamentName
      );
  const mappedNullMeters =
    apartamentReadings &&
    Object.values(newNullMeters)
      ?.map((item) => ({
        children: item?.children,
        attributes: {
          device_id: item?.attributes?.identifier,
          meter_type: item?.attributes?.meter_type,
          values: {
            serial_number: item?.attributes?.serial_number,

            values: {
              apartment_name: item?.attributes?.apartment_name,
              condominium_name: item?.attributes?.condominium_name,
              sub_condominium_name: item?.attributes?.sub_condominium_name,
              internal:
                mappedApartments[item?.attributes?.apartment_id]?.attributes
                  ?.internal,
              flat: mappedApartments[item?.attributes?.apartment_id]?.attributes
                ?.flat,
              room: mappedMeters[item?.attributes?.identifier]?.attributes
                ?.room,
            },
          },
        },
      }))
      ?.filter(
        (item) =>
          meterType.includes(item?.attributes?.meter_type) &&
          item?.attributes?.values?.values?.apartment_name === apartamentName
      );
  const allReadings =
    apartamentReadings &&
    filteredCondominiumReadings &&
    nullMeter &&
    readyToShow
      ? [
          ...mappedNullMeters,
          ...mappedNullPulsMeters,
          ...mappedFilteredReadings,
        ]
      : [];
  useEffect(() => {}, [mappedNullMeters]);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const [getReadings, { status }] = store.useLazyGetApartamentReadingsQuery();
  const fields = {
    datepicker: SingleDatePicker,
  };
  const schema = {
    title: "Filter form",
    type: "object",
    properties: {
      serial_number: {
        id: 2,
        title: t("serial_number"),
        type: "string",
      },
      condominium_name: {
        id: 4,
        title: t("condominium_name"),
        type: "string",
      },
      sub_condominium_name: {
        id: 5,
        title: t("sub_condominium_name"),
        type: "string",
      },
      apartment_name: {
        id: 1,
        title: t("apartment_name"),
        type: "string",
      },
      insertion_date: {
        id: 3,
        title: t("inserted_at"),
        type: "object",
        properties: {
          to_date: {
            type: "string",
            default: now.toISOString(),
          },
        },
      },
      /*  read_time: {
        id: 6,
        title: t("read_time"),
        type: "string",
        label: "read_time",
      }, */
    },
  };
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("search"),
    },
    "ui:ObjectFieldTemplate": objectFieldTemplate,
    insertion_date: {
      "ui:field": "datepicker",
    },
    read_time: {
      "ui:field": "datepicker",
    },
  };

  useEffect(() => {
    if (condominiumId && subCondominiumIdentifier && waterSorts?.length == 0) {
      const to = new Date();
      const toDate = new Date(
        Date.UTC(to.getFullYear(), to.getMonth(), to.getDate() + 1)
      );

      getReadings({
        condominium_id:
          window.READING_ID_PARAM == "id"
            ? condominiumId
            : condominiumIdentifier,
        read_time: savedFromDate && savedFromDate,
        to_date: savedToDate ?? toDate.toISOString(),
        type: meterType,
        /*    no_gateway: true, */
        sub_condominium_id: subCondominiumIdentifier,
        apartment_id: apartamentIdentifier,
        /*  nearest_message: true, */
        most_recent_2: true,
        message_type: "reading",
      });
      setFirstRender(false);
      setReadySort(true);
    }
  }, [
    condominiumId,
    subCondominiumIdentifier,
    apartamentIdentifier,
    meterType,
    waterSorts,
  ]);
  const onSubmitClick = (obj) => {
    setWaterSorts([]);
    const messagesDateTo = new Date(formData.insertion_date.to_date);
    messagesDateTo.setDate(messagesDateTo.getDate() + 1);
    setSavedToDate(messagesDateTo.toISOString());
    setSavedFromDate(obj.read_time?.read_time);
    getReadings({
      ...obj,
      condominium_id:
        window.READING_ID_PARAM == "id" ? condominiumId : condominiumIdentifier,
      /*     no_gateway: true, */
      to_date: messagesDateTo.toISOString(),
      sub_condominium_id: subCondominiumIdentifier,
      apartment_id: apartamentIdentifier,
      /*     nearest_message: true, */
      most_recent_2: true,
      message_type: "reading",
      type: meterType,
    });
  };
  useEffect(() => {
    if (!firstRender && readySort) {
      const insertionDate = new Date(formData.insertion_date.to_date);
      const messagesDateTo = new Date(insertionDate);
      messagesDateTo.setUTCDate(insertionDate.getUTCDate() + 1);
      getReadings({
        ...formData,
        condominium_id: condominiumIdentifier,
        /*  no_gateway: true, */
        page: page,
        to_date: messagesDateTo.toISOString(),
        most_recent_2: true,
        message_type: "reading",
        apartment_id: apartamentIdentifier,
        /*     nearest_message: true, */
        meter_types: JSON.stringify(meterType),
        sorts: waterSorts,
      });
    }
  }, [waterSorts]);
  /* useEffect(() => {
    if (!firstRender) {
      const messagesDateTo = new Date(formData.insertion_date.to_date);
      messagesDateTo.setDate(messagesDateTo.getDate() + 1);
      getReadings({
        ...formData,
        condominium_id: condominiumIdentifier,
        no_gateway: true,
        to_date: messagesDateTo.toISOString(),
        sub_condominium_id: subCondominiumIdentifier,
        apartment_id: apartamentIdentifier,
        nearest_message: true,
        type: meterType,
      });
    }
  }, [firstRender, meterType]); */
  const readingColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,

      flex: 1,
      hide: true,
    },
    {
      field: "sub_condominium_name",
      headerName: t("sub_condominium_name"),
      type: "string",
      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "flat",
      headerName: t("floor"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "internal",
      headerName: t("internal"),
      type: "string",

      editable: false,
      flex: 4,
      editable: false,
    },
    {
      field: "apartment_name",
      headerName: t("apartment_name"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "serial_number",
      headerName: t("serial_number"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "metrological_serial",
      headerName: t("metrological_serial"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "read_time",
      headerName: t("read_time"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "last_reading",
      headerName: t("last_reading_value"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "unit",
      headerName: t("unit"),
      editable: false,
      nosort: true,
      flex: 2,
    },

    {
      field: "alarms",
      headerName: t("alarms"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 5,
      editable: false,
      renderCell: (params) => {
        if (params?.value?.alarms && params.value.alarms.length > 0) {
          if (
            !params?.value?.mapped_alarms ||
            params.value.mapped_alarms.length === 0
          ) {
            return (
              <Chip
                label={t("in_progress")}
                sx={{
                  background: theme.palette.error.light,
                  color: theme.palette.error.contrastText,
                }}
              />
            );
          }
          if (params.value.mapped_alarms.length > 0) {
            return (
              <Box display={"flex"} flexWrap="wrap" width={"100%"}>
                {params.value.mapped_alarms?.map((item, index) => (
                  <Tooltip title={item}>
                    <Chip
                      key={index}
                      sx={{
                        background: theme.palette.error.light,
                        color: theme.palette.error.contrastText,
                        mt: 1,
                      }}
                      label={item}
                    />
                  </Tooltip>
                ))}
              </Box>
            );
          }
        }
        return "";
      },
    },
    {
      field: "anomalies",
      headerName: t("anomalies"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 6,
      editable: false,
      renderCell: (params) => {
        return (
          <Box display={"flex"} flexWrap="wrap" width={"100%"}>
            {params?.value?.map((item, index) => (
              <Chip
                key={index}
                sx={{
                  background: theme.palette.error.light,
                  color: theme.palette.error.contrastText,
                  mt: 1,
                }}
                label={item}
              />
            ))}
          </Box>
        );
      },
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "string",
      sortable: false,
      editable: false,
      nosort: true,
      flex: 4,
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems="center">
            <IwdAuthWrapper
              children={
                <IconButton
                  disabled={!params?.value?.values?.values?.value}
                  onClick={() =>
                    onMailClick(params?.value?.values?.values?.value)
                  }
                >
                  <DraftsIcon sx={{ fill: "red" }} />
                </IconButton>
              }
              section={"block_msg_btn"}
            />
            {params?.value?.device_id && (
              <IwdAuthWrapper
                children={
                  <IconButton sx={{ mt: 0.5 }}>
                    <Link
                      to={`/meters/${params?.value?.device_id}`}
                      target="_blank"
                    >
                      <VisibilityIcon
                        sx={{ fill: theme.palette.primary.main }}
                      />
                    </Link>
                  </IconButton>
                }
                section={"block_show_btns"}
              />
            )}
          </Box>
        );
      },
    },
  ];
  const readingRows = allReadings?.map((item, i) => {
    let id = uuidv4();
    return {
      id: id,
      serial_number: item?.attributes?.values?.serial_number,
      metrological_serial: item?.attributes?.extra?.metrological_serial,
      last_reading:
        item?.attributes?.values?.values?.value?.last_reading &&
        item?.attributes?.values?.values?.value?.last_reading?.value !== null
          ? t("number", {
              val: item?.attributes?.values?.values?.value?.last_reading?.value,
            })
          : "--",
      unit:
        item.attributes?.values?.values.value?.last_reading?.unit == "m^3"
          ? "m³"
          : item.attributes?.values?.values.value?.last_reading?.unit ?? "--",
      read_time: item?.attributes?.values?.read_time
        ? t("date_val", { val: new Date(item?.attributes?.values?.read_time) })
        : "--",
      alarms: {
        alarms: item?.attributes?.values?.values?.value?.alarms,
        mapped_alarms: item?.attributes?.values?.values?.value?.mapped_alarms,
      },
      anomalies:
        item?.attributes?.values?.values?.value?.mapped_anomalies &&
        item?.attributes?.values?.values?.value?.mapped_anomalies?.length > 0 &&
        item?.attributes?.values?.values?.value?.mapped_anomalies,
      apartment_name: item?.attributes?.values?.values?.apartment_name,
      condominium_name: item?.attributes?.values?.values?.condominium_name,
      internal: item?.attributes?.values?.values?.internal ?? "--",
      flat: item?.attributes?.values?.values?.flat ?? "--",
      sub_condominium_name:
        item?.attributes?.values?.values?.sub_condominium_name,
      actions: item?.attributes,
      dayDiff:
        /*  getDateDifferenceInDays(item?.attributes?.values?.read_time) */ getDifferenceInDays(
          item?.attributes?.values?.read_time,
          formData?.insertion_date?.to_date
        ),
    };
  });

  const heatColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "sub_condominium_name",
      headerName: t("sub_condominium_name"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "flat",
      headerName: t("floor"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "internal",
      headerName: t("internal"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "apartment_name",
      headerName: t("apartment_name"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "serial_number",
      headerName: t("serial_number"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "metrological_serial",
      headerName: t("metrological_serial"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "read_time",
      headerName: t("read_time"),
      type: "string",

      editable: false,
      flex: 4,
      editable: false,
    },
    {
      field: "heat_last_reading",
      headerName: t("last_reading_heat_val"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "cold_last_reading",
      headerName: t("last_reading_cold_val"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "unit",
      headerName: t("unit"),
      editable: false,
      nosort: true,
      editable: false,
      flex: 2,
    },

    {
      field: "alarms",
      headerName: t("alarms"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 5,
      editable: false,
      renderCell: (params) => {
        if (params?.value?.alarms && params.value.alarms.length > 0) {
          if (
            !params?.value?.mapped_alarms ||
            params.value.mapped_alarms.length === 0
          ) {
            return (
              <Chip
                label={t("in_progress")}
                sx={{
                  background: theme.palette.error.light,
                  color: theme.palette.error.contrastText,
                }}
              />
            );
          }
          if (params.value.mapped_alarms.length > 0) {
            return (
              <Box display={"flex"} flexWrap="wrap" width={"100%"}>
                {params.value.mapped_alarms?.map((item, index) => (
                  <Tooltip title={item}>
                    <Chip
                      key={index}
                      sx={{
                        background: theme.palette.error.light,
                        color: theme.palette.error.contrastText,
                        mt: 1,
                      }}
                      label={item}
                    />
                  </Tooltip>
                ))}
              </Box>
            );
          }
        }
        return "";
      },
    },
    {
      field: "anomalies",
      headerName: t("anomalies"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 6,
      editable: false,
      renderCell: (params) => {
        return (
          <Box display={"flex"} flexWrap="wrap" width={"100%"}>
            {params?.value?.map((item, index) => (
              <Chip
                key={index}
                sx={{
                  background: theme.palette.error.light,
                  color: theme.palette.error.contrastText,
                  mt: 1,
                }}
                label={item}
              />
            ))}
          </Box>
        );
      },
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "string",
      sortable: false,
      editable: false,
      nosort: true,
      headerAlign: "right",
      flex: 4,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems="center">
            <IwdAuthWrapper
              children={
                <IconButton
                  disabled={!params?.value?.values?.values?.value}
                  onClick={() =>
                    onMailClick(params?.value?.values?.values?.value)
                  }
                >
                  <DraftsIcon sx={{ fill: "red" }} />
                </IconButton>
              }
              section={"block_msg_btn"}
            />
            {params?.value?.device_id && (
              <IwdAuthWrapper
                children={
                  <IconButton sx={{ mt: 0.5 }}>
                    <Link
                      to={`/meters/${params?.value?.device_id}`}
                      target="_blank"
                    >
                      <VisibilityIcon
                        sx={{ fill: theme.palette.primary.main }}
                      />
                    </Link>
                  </IconButton>
                }
                section={"block_show_btns"}
              />
            )}
          </Box>
        );
      },
    },
  ];
  const heatRows = allReadings?.map((item, i) => {
    let id = uuidv4();
    return {
      id: id,
      serial_number: item?.attributes?.values?.serial_number,
      metrological_serial: item?.attributes?.extra?.metrological_serial,
      cold_last_reading:
        item?.attributes?.values?.values?.value?.cold_last_reading?.value !==
          null &&
        typeof item?.attributes?.values?.values?.value?.cold_last_reading ===
          "object" &&
        Object.keys(item?.attributes?.values?.values?.value?.cold_last_reading)
          .length !== 0
          ? /* t("number", {
              val:
                item?.attributes?.values?.values?.value?.cold_last_reading
                  ?.value / 1000,
            }) */ item?.attributes?.values?.values?.value?.cold_last_reading
              ?.value / 1000
          : "--",
      heat_last_reading:
        item?.attributes?.values?.values?.value?.heat_last_reading?.value !==
          null &&
        typeof item?.attributes?.values?.values?.value?.heat_last_reading ===
          "object" &&
        Object.keys(item?.attributes?.values?.values?.value?.heat_last_reading)
          .length !== 0
          ? /* t("number", {
              val:
                item?.attributes?.values?.values?.value?.heat_last_reading
                  ?.value / 1000,
            }) */ item?.attributes?.values?.values?.value?.heat_last_reading
              ?.value / 1000
          : "--",
      unit:
        item?.attributes?.values?.values?.value?.heat_last_reading?.unit ===
        "Wh"
          ? "kWh"
          : item?.attributes?.values?.values?.value?.heat_last_reading?.unit ??
            "--",
      read_time: item?.attributes?.values?.read_time
        ? t("date_val", { val: new Date(item?.attributes?.values?.read_time) })
        : "--",
      alarms: {
        alarms: item?.attributes?.values?.values?.value?.alarms,
        mapped_alarms: item?.attributes?.values?.values?.value?.mapped_alarms,
      },
      anomalies:
        item?.attributes?.values?.values?.value?.mapped_anomalies &&
        item?.attributes?.values?.values?.value?.mapped_anomalies?.length > 0 &&
        item?.attributes?.values?.values?.value?.mapped_anomalies,
      apartment_name: item?.attributes?.values?.values?.apartment_name,
      condominium_name: item?.attributes?.values?.values?.condominium_name,
      internal: item?.attributes?.values?.values?.internal ?? "--",
      flat: item?.attributes?.values?.values?.flat ?? "--",
      sub_condominium_name:
        item?.attributes?.values?.values?.sub_condominium_name,
      actions: item?.attributes,
      dayDiff:
        /* getDateDifferenceInDays(item?.attributes?.values?.read_time) */ getDifferenceInDays(
          item?.attributes?.values?.read_time,
          formData?.insertion_date?.to_date
        ),
    };
  });
  const heatCostColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "sub_condominium_name",
      headerName: t("sub_condominium_name"),
      type: "string",

      editable: false,
      flex: 4,
      editable: false,
    },
    {
      field: "flat",
      headerName: t("floor"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "internal",
      headerName: t("internal"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "apartment_name",
      headerName: t("apartment_name"),
      type: "string",

      editable: false,
      flex: 4,
      editable: false,
    },
    {
      field: "room",
      headerName: t("room"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "serial_number",
      headerName: t("serial_number"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "metrological_serial",
      headerName: t("metrological_serial"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "read_time",
      headerName: t("read_time"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "last_unconverted_reading",
      headerName: t("last_unconverted_reading"),
      type: "string",

      editable: false,
      flex: 4,
      editable: false,
    },
    {
      field: "k",
      headerName: t("K"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "last_reading",
      headerName: t("parameterized_reading"),
      type: "string",

      editable: false,
      flex: 4,
      editable: false,
    },

    {
      field: "anomalies",
      headerName: t("anomalies"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 6,
      editable: false,
      renderCell: (params) => {
        return (
          <Box display={"flex"} flexWrap="wrap" width={"100%"}>
            {params?.value?.map((item, index) => (
              <Chip
                key={index}
                sx={{
                  background: theme.palette.error.light,
                  color: theme.palette.error.contrastText,
                  mt: 1,
                }}
                label={item}
              />
            ))}
          </Box>
        );
      },
    },
    {
      field: "alarms",
      headerName: t("alarms"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 5,
      editable: false,
      renderCell: (params) => {
        if (params?.value?.alarms && params.value.alarms.length > 0) {
          if (
            !params?.value?.mapped_alarms ||
            params.value.mapped_alarms.length === 0
          ) {
            return (
              <Chip
                label={t("in_progress")}
                sx={{
                  background: theme.palette.error.light,
                  color: theme.palette.error.contrastText,
                }}
              />
            );
          }
          if (params.value.mapped_alarms.length > 0) {
            return (
              <Box display={"flex"} flexWrap="wrap" width={"100%"}>
                {params.value.mapped_alarms?.map((item, index) => (
                  <Tooltip title={item}>
                    <Chip
                      key={index}
                      sx={{
                        background: theme.palette.error.light,
                        color: theme.palette.error.contrastText,
                        mt: 1,
                      }}
                      label={item}
                    />
                  </Tooltip>
                ))}
              </Box>
            );
          }
        }
        return "";
      },
    },

    /* {
      field: "condominium_name",
      headerName: t("condominium_name"),
      type: "string",

      editable: false,
      flex: 3,
      editable: false,
    }, */

    {
      field: "actions",
      headerName: t("actions"),
      type: "string",
      sortable: false,
      editable: false,
      nosort: true,
      headerAlign: "right",
      flex: 4,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems="center">
            <IwdAuthWrapper
              children={
                <IconButton
                  disabled={!params?.value?.values?.values?.value}
                  onClick={() =>
                    onMailClick(params?.value?.values?.values?.value)
                  }
                >
                  <DraftsIcon sx={{ fill: "red" }} />
                </IconButton>
              }
              section={"block_msg_btn"}
            />
            {params?.value?.device_id && (
              <IwdAuthWrapper
                children={
                  <IconButton sx={{ mt: 0.5 }}>
                    <Link
                      to={`/meters/${params?.value?.device_id}`}
                      target="_blank"
                    >
                      <VisibilityIcon
                        sx={{ fill: theme.palette.primary.main }}
                      />
                    </Link>
                  </IconButton>
                }
                section={"block_show_btns"}
              />
            )}
          </Box>
        );
      },
    },
  ];
  const resetFilter = () => {
    setWaterSorts([]);
    setReadySort(false);
  };
  const heatCostRows = allReadings?.map((item, i) => {
    let id = uuidv4();
    return {
      id: id,
      serial_number: item?.attributes?.values?.serial_number,
      metrological_serial: item?.attributes?.extra?.metrological_serial,
      last_reading:
        item?.attributes?.values?.values?.value?.last_reading &&
        item?.attributes?.values?.values?.value?.last_reading?.value !== null &&
        item?.attributes?.values?.values?.value?.last_reading?.value !== null
          ? /*  t("number", {
              val: item?.attributes?.values?.values?.value?.last_reading?.value,
            }) */ Math.round(
              item?.attributes?.values?.values?.value?.last_reading?.value
            )
          : "--",
      last_unconverted_reading:
        item?.attributes?.values?.values?.value?.last_unconverted_reading
          ?.value !== null &&
        typeof item?.attributes?.values?.values?.value
          ?.last_unconverted_reading === "object" &&
        item?.attributes?.values?.values?.value?.last_unconverted_reading !==
          null &&
        Object.keys(
          item?.attributes?.values?.values?.value?.last_unconverted_reading
        ).length !== 0
          ? /*  t("number", {
              val: item?.attributes?.values?.values?.value
                ?.last_unconverted_reading?.value,
            }) */ Math.round(
              item?.attributes?.values?.values?.value?.last_unconverted_reading
                ?.value
            )
          : "--",
      /*   unit: item.attributes?.values?.values.value?.last_reading?.unit ?? "--", */
      k:
        item?.attributes?.values?.values?.value?.k !== null
          ? t("number", {
              val: item?.attributes?.values?.values?.value?.k,
            })
          : "--",
      read_time:
        t("date_val", { val: new Date(item?.attributes?.values?.read_time) }) ??
        "--",
      alarms: {
        alarms: item?.attributes?.values?.values?.value?.alarms,
        mapped_alarms: item?.attributes?.values?.values?.value?.mapped_alarms,
      },
      anomalies:
        item?.attributes?.values?.values?.value?.mapped_anomalies &&
        item?.attributes?.values?.values?.value?.mapped_anomalies?.length > 0 &&
        item?.attributes?.values?.values?.value?.mapped_anomalies,
      apartment_name: item?.attributes?.values?.values?.apartment_name,
      internal: item?.attributes?.values?.values?.internal ?? "--",
      flat: item?.attributes?.values?.values?.flat ?? "--",
      room: item?.attributes?.values?.values?.room ?? "--",
      condominium_name: item?.attributes?.values?.values?.condominium_name,
      sub_condominium_name:
        item?.attributes?.values?.values?.sub_condominium_name,
      actions: item?.attributes,
      dayDiff:
        /*  getDateDifferenceInDays(item?.attributes?.values?.read_time), */ getDifferenceInDays(
          item?.attributes?.values?.read_time,
          formData?.insertion_date?.to_date
        ),
    };
  });
  const openMessageModal = () => {
    setMessageModal(true);
  };
  const closeMessageModal = () => {
    setMessageModal(false);
    setMessage("");
  };
  const onMailClick = (msg) => {
    setMessage(msg);
    openMessageModal();
  };
  const styleSelector = (params) => {
    const datastr = params.row.readTime;
    const date = new Date();

    if (params?.row?.alarms?.length > 0 && params?.row?.alarms !== "--") {
      return `super-app-theme--Orange custom-colored`;
    }
    if (params?.row?.dayDiff > 5) {
      return `super-app-theme--PartiallyFilled custom-colored`;
    }
    if (params?.row?.last_reading == "--") {
      return `super-app-theme--Rejected custom-colored`;
    }
    if (params?.row?.anomalies?.length > 0) {
      return `super-app-theme--anomaly custom-colored`;
    }
  };
  const heatStyleSelector = (params) => {
    const datastr = params.row.readTime;
    const date = new Date();

    if (params?.row?.alarms?.length > 0 && params?.row?.alarms !== "--") {
      return `super-app-theme--Orange custom-colored`;
    }
    if (params?.row?.dayDiff > 5) {
      return `super-app-theme--PartiallyFilled custom-colored`;
    }
    if (params?.row?.anomalies?.length > 0) {
      return `super-app-theme--anomaly custom-colored`;
    }
    if (
      params?.row?.heat_last_reading == "--" &&
      params?.row?.cold_last_reading == "--"
    ) {
      return `super-app-theme--Rejected custom-colored`;
    }
  };
  const dinamicWidthSelector = () => {
    if (is1280x720 && appBarIsOpen) {
      return "62vw";
    }
    if (is1280x720 && !appBarIsOpen) {
      return "74vw";
    }
    if (!is1280x720 && appBarIsOpen) {
      return "67vw";
    }
    if (!is1280x720 && !appBarIsOpen) {
      return "79vw";
    }
  };
  useEffect(() => {
    if (status === "fulfilled") {
      setReadyToShow(true);
    }
  }, [status]);
  useEffect(() => {
    if (!firstRender) {
      setReadyToShow(false);
    }
  }, [apartamentIdentifier]);
  return (
    <>
      <ModalDialog
        open={messageModal}
        close={() => closeMessageModal()}
        title={"Message"}
      >
        <Box>
          <Typography variant="subtitle2">
            <pre
              style={{
                overflow: "auto",
                background: "#c0c0c0",
                padding: "9px",
              }}
            >
              {JSON.stringify(message, null, 10)}
            </pre>
          </Typography>
        </Box>
      </ModalDialog>
      <FormAccordion width={() => dinamicWidthSelector()}>
        <Form
          schema={schema}
          fields={fields}
          formData={formData}
          showErrorList={false}
          onChange={(changeEvent) => setFormData(changeEvent.formData)}
          onSubmit={(values) => onSubmitClick(values.formData)}
          validator={validator}
          uiSchema={uiSchema}
        />
      </FormAccordion>
      {allReadings && (
        <Box sx={{ height: 600, width: dinamicWidthSelector(), mt: 2 }}>
          <BlockMetersTableTabs
            resetFilter={resetFilter}
            afs={
              <Box sx={{ height: 610 }}>
                {readyToShow && (
                  <IwdDataGrid
                    columns={readingColumns}
                    perPage={perPage}
                    setPage={setPage}
                    rows={readingRows}
                    paginationMode={"client"}
                    page={page}
                    count={readingRows?.length}
                    setSorts={setWaterSorts}
                    sorts={waterSorts}
                    height={600}
                    styles={styleSelector}
                  />
                )}
              </Box>
            }
            heatMeter={
              <Box sx={{ height: 610 }}>
                {readyToShow && (
                  <IwdDataGrid
                    columns={heatCostColumns}
                    perPage={perPage}
                    setPage={setPage}
                    paginationMode={"client"}
                    rows={heatCostRows}
                    page={page}
                    count={heatCostRows?.length}
                    setSorts={setWaterSorts}
                    sorts={waterSorts}
                    height={600}
                    styles={styleSelector}
                  />
                )}
              </Box>
            }
            colorMeter={
              <Box sx={{ height: 610 }}>
                {readyToShow && (
                  <IwdDataGrid
                    columns={heatColumns}
                    perPage={perPage}
                    paginationMode={"client"}
                    setPage={setPage}
                    rows={heatRows}
                    page={page}
                    count={heatRows?.length}
                    setSorts={setWaterSorts}
                    sorts={waterSorts}
                    height={600}
                    styles={heatStyleSelector}
                  />
                )}
              </Box>
            }
            setMeterType={setMeterType}
            waterVisibility={acsMeters?.length > 0 || afsMeters?.length > 0}
            hcaVisibility={heatCostMeters?.length > 0}
            colorVisibility={colorMeters?.length > 0}
            status={status}
          />
        </Box>
      )}
    </>
  );
};
export default ApartamentReadingTab;
