import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { store } from "../../redux/store";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { CircularProgress, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import debounce from "lodash.debounce";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
const BlockAutocomlete = ({ userId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [getCondominumList] = store.useLazyGetCondominiumListQuery();
  const condominumList = useSelector(
    (state) => state?.userSlice?.condominumList
  );
  const [associateAdmin, { isSuccess: assocSuccess }] =
    store.useUserCondAssocMutation();
  const [getUserShow, { isSuccess: userSuccess }] =
    store.useLazyGetUserShowQuery();
  const updating = useSelector((state) => state?.widgetSlice?.updating);
  const blocks = useSelector((state) => state?.condominiumSlice?.condominiums);
  const options = blocks?.map((item) => ({
    title: item?.attributes?.fields?.name,
    value: item?.id,
  }));

  const [getCondominiums] = store.useLazyCondominiumIndexQuery();
  const [value, setValue] = useState([]);
  const [loading, setLoading] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const top100Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
    { title: "The Dark Knight", year: 2008 },
    { title: "12 Angry Men", year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: "Pulp Fiction", year: 1994 },
  ];
  /* const data = props?.schema?.data;
  useEffect(() => {
    if (value) {
      props.onChange(value.value);
    }
  }, [value]);
  useEffect(() => {
    
    if (props?.formData && data.length > 0) {
      const filteredData = data?.filter(

        (item) => item.value === props?.formData
      );
      
      setValue(filteredData[0]);
    }
  }, [props?.formData, data]); */
  const debValue = debounce((e) => {
    setSearchValue(e.target.value);
  }, 500);
  useEffect(() => {
    if (value && searchValue) {
      setSearchValue("");
      getCondominiums({ page: 1, per_page: 10 });
    }
  }, [value]);
  const associatedBlocks = value?.map((item) => item?.value);
  useEffect(() => {
    if (condominumList) {
      const selectedBlocks = condominumList?.map((item) => ({
        title: item?.attributes?.fields?.name,
        value: item?.id,
      }));
      setValue(selectedBlocks);
    }
  }, [condominumList]);
  useEffect(() => {
    getCondominiums({ page: 1, per_page: 10 });
  }, []);
  useEffect(() => {
    if (assocSuccess) {
      dispatch(setMessage(t("assoc_succ")));
      getCondominumList(userId);
    }
  }, [assocSuccess]);
  useEffect(() => {}, [options]);
  useEffect(() => {
    if (searchValue) {
      setLoading(true);
      getCondominiums({ name: searchValue }).then(() => {
        setLoading(false);
      });
    }
  }, [searchValue]);

  useEffect(() => {}, [searchValue]);
  useEffect(() => {}, [value]);

  const onAssocClick = () => {
    const condIds = value?.map((item) => item.value);
    associateAdmin({
      id: userId,
      attributes: {
        realtion_type: "building_administrator",
        device_ids: condIds ? [...condIds] : [],
      },
    });
  };

  return (
    <>
      <Autocomplete
        multiple
        limitTags={2}
        value={value}
        id="multiple-limit-tags"
        options={
          options
            ? options?.filter((item) => !associatedBlocks.includes(item.value))
            : []
        }
        getOptionLabel={(option) => option.title}
        onChange={(e, newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => {
          return (
            <>
              <TextField
                onChange={debValue}
                {...params}
                value={value}
                /*     error={props?.rawErrors?.length > 0}
                required={props.required}
                label={props.schema.title} */
                label={t("search_block")}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      {loading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                onClick={onAssocClick}
                variant="contained"
                sx={{ mt: 2, mb: 2, float: "right" }}
              >
                {t("assoc")}
              </Button>
            </>
          );
        }}
      />
    </>
  );
};
export default BlockAutocomlete;
