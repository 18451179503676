import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { store } from "../../redux/store";
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import theme from "../../theme";
import ColorPickerWidget from "./ColorPickerWidget";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import ImageUpload from "./ImageUpload";
import { onChange } from "@jsonforms/examples";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      rowSpacing={3}
      spacing={2}
    >
      {props.properties?.map((element, i) => {
        if (element.name === "colors") {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const CreateConfig = () => {
  const [createConfig] = store.useCreateConfigurationMutation();
  const dispatch = useDispatch();
  const [createdId, setCreatedId] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const schema = {
    type: "object",
    properties: {
      name: {
        $id: "1",
        type: "string",
        title: t("name"),
      },
      url: {
        $id: "1",
        type: "string",
        title: t("url"),
      },

      logo_id: {
        type: ["number", "string"],
        title: t("logo"),
      },
      favicon_id: {
        type: ["number", "string"],
        title: t("fav icon"),
      },
      colors: {
        type: "object",
        properties: {
          primary: {
            type: "string",
          },
          secondary: {
            type: "string",
          },
        },
      },
    },
    required: ["name"],
  };
  const fields = {
    colorpicker: ColorPickerWidget,
    img: ImageUpload,
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("create"),
    },
    colors: {
      "ui:field": "colorpicker",
    },
    logo_id: {
      "ui:field": "img",
    },
    favicon_id: {
      "ui:field": "img",
    },
  };
  useEffect(() => {
    dispatch(setBreadCrumbs("enduser_config/create"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  useEffect(() => {}, [formData]);
  const onSubmitClick = () => {
    const attributes = {
      logo_id: formData?.logo_id,
      favicon_id: formData?.favicon_id,
      name: formData?.name,
      url: formData?.url,
      colors: {
        primary: formData?.colors?.primary,
        secondary: formData?.colors?.secondary,
      },
    };
    createConfig(attributes)
      .unwrap()
      .then((data) => setCreatedId(data?.data?.attributes?.id))
      ?.catch((error) => {
        console.error("Error occurred:", error);
      });
  };
  const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-.\/?%&=]*)?$/;
  const customValidate = (formData, errors) => {
    if (!urlRegex.test(formData?.url)) {
      errors?.url?.addError("not valid url");
    }
    return errors;
  };
  useEffect(() => {
    if (createdId) {
      navigate(`/enduser/${createdId}`);
    }
  }, [createdId]);
  return (
    <>
      <Typography
        color={theme.palette.primary.main}
        variant="h4"
        sx={{ mt: 2, mb: 3 }}
      >
        {t("create_config")}
      </Typography>
      <Form
        schema={schema}
        formData={formData}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        onSubmit={onSubmitClick}
        validator={validator}
        uiSchema={uiSchema}
        /* widgets={widgets} */
        showErrorList={false}
        fields={fields}
        customValidate={customValidate}
        noHtml5Validate
      />
    </>
  );
};

export default CreateConfig;
