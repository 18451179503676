import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { store } from "../../redux/store";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { deleteWidget } from "../../redux/slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { setPreferenceDashboardId } from "../../redux/slices/userPreferenceSlice";

import {
  deleteFromDashboardList,
  setWidgetsToRestore,
  restoreWidgets,
} from "../../redux/slices/dashboardSlice";
import { useTranslation } from "react-i18next";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

const DashboardModal = ({
  trashModal,
  setTrashModal,
  trashId,
  removeDashboard,
  dashboardId,
  deletePreference,
  onChangePreverence,
  updatePreferenceSuccess,
  firstDashboardIdFromList,
}) => {
  const { t } = useTranslation();
  let title;
  let text;
  if (removeDashboard) {
    title = t("delete_dashboard");
    text = t("delete_dash_text");
  } else {
    title = t("delete_widget");
    text = t("delete_wid_text");
  }
  const widgets = useSelector((state) => state?.dashboardSlice?.widgets);
  const tempWidgets = useSelector(
    (state) => state?.dashboardSlice?.tempWidgets
  );
  const dispatch = useDispatch();
  const [deleteDashboard] = store.useDeleteDashboardMutation();
  const [open, setOpen] = React.useState(false);
  const [readyForRefresh, setReady] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setTrashModal(false);

  const refreshPage = () => {
    window.location.reload();
  };
  React.useEffect(() => {});
  const deletePreferenceControll = () => {
    if (deletePreference) {
      dispatch(
        setPreferenceDashboardId(
          firstDashboardIdFromList ? firstDashboardIdFromList : null
        )
      );
    }
  };
  React.useEffect(() => {}, [deletePreference]);
  const onConfirmClick = (id) => {
    if (removeDashboard) {
      Promise.all([
        deleteDashboard({ id: dashboardId }),
        deletePreferenceControll(),
        dispatch(deleteFromDashboardList(dashboardId)),
      ])
        .then(() => setReady(true))
        ?.catch((error) => {
          console.error("Error occurred:", error);
        });
    } else {
      dispatch(deleteWidget(id));
      const widgetToRestore = widgets?.filter(
        (item) => item?.id == id && !tempWidgets.includes(item.id)
      );
      if (widgetToRestore[0]) {
        dispatch(setWidgetsToRestore(widgetToRestore[0]));
      }
    }

    handleClose();
  };

  React.useEffect(() => {
    if (!deletePreference && readyForRefresh) {
      refreshPage();
    }
  }, [deletePreference, readyForRefresh]);

  React.useEffect(() => {
    if (deletePreference && updatePreferenceSuccess) {
      refreshPage();
    }
  }, [deletePreference, updatePreferenceSuccess]);

  return (
    <Box>
      <Modal open={trashModal} onClose={handleClose}>
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            {title}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            align="center"
          >
            {text}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", pt: 2 }}>
            <Button onClick={() => onConfirmClick(trashId)}>{t("yes")}</Button>
            <Button onClick={handleClose}>{t("no")}</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DashboardModal;
