import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import WorklistEditorSearchBar from "./WorklistEditorSearchBar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setMessage } from "../../redux/slices/snackbarSlice";
import WorklistEditorTable from "./WorklistEditorTable";
import { store } from "../../redux/store";
import { Typography, Button } from "@mui/material";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { worklistActions } from "../../redux/slices/worklistSlice";
import theme from "../../theme";
const WorklistEditorIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const worklistEditorTrashId = useSelector(
    (state) => state?.worklistSlice?.worklistEditorTrashId
  );
  const [getWorklistEditors] = store.useLazyGetWorklistEditorIndexQuery();
  const [deleteWorkListEditor, { isSuccess: deleteSuccess }] =
    store.useDeleteWorkListEditorMutation();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const params = {
    page: page,
    perPage: perPage,
  };
  useEffect(() => {
    dispatch(worklistActions.cleanWorklistData());
  }, []);
  useEffect(() => {
    if (worklistEditorTrashId) {
      deleteWorkListEditor(worklistEditorTrashId);
    }
  }, [worklistEditorTrashId]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("delete_success")));
      getWorklistEditors(params);
    }
  }, [deleteSuccess]);
  useEffect(() => {
    getWorklistEditors(params);
  }, []);
  useEffect(() => {
    dispatch(setBreadCrumbs("worklisteditors"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  return (
    <>
      <Typography
        variant="h4"
        sx={{ color: theme.palette.primary.main, mb: 2 }}
      >
        {t("worklist_editor")}
      </Typography>
      <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
        <Button
          onClick={() => navigate("/worklisteditors/create")}
          sx={{ mt: 2, mb: 1 }}
        >
          {t("create_worklist_editor")}
        </Button>
      </Box>
      <WorklistEditorSearchBar params={params} />
      <WorklistEditorTable perPage={perPage} page={page} setPage={setPage} />
    </>
  );
};
export default WorklistEditorIndex;
