import { Form } from "@rjsf/mui";
import { useParams } from "react-router-dom";
import validator from "@rjsf/validator-ajv6";
import { useState, useEffect, useMemo } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { IwdAuthWrapper } from "../utils/IwdAuthWrapper";
import { useTranslation } from "react-i18next";
import { loadingOff, loadingOn } from "../redux/slices/loadingSlice";
import { useSelector, useDispatch } from "react-redux";
import { setGatewayMode, setMaxAppPos } from "../redux/slices/condominiumSlice";
import { useNavigate } from "react-router-dom";
import { setMessage } from "../redux/slices/snackbarSlice";
import { setBlockTabName } from "../redux/slices/condominiumSlice";
import { condominiumApi } from "../redux/slices/condominiumApi";
import {
  setTreeItem,
  setBtnDetector,
  setAppElemPositions,
} from "../redux/slices/condominiumSlice";
import { TreeItem, TreeView } from "@mui/lab";
import { setPosDirection } from "../redux/slices/condominiumSlice";
import { setAppIndex } from "../redux/slices/condominiumSlice";
import LongNameWrapper from "../utils/LongNameWrapper";
import { ChevronRightOutlined, ExpandMoreOutlined } from "@material-ui/icons";
import { setSubCondominiumIdentifier } from "../redux/slices/condominiumSlice";
import GatewayShow from "../components/gateway/GatewayShow";
import { store } from "../redux/store";
import { setBreadCrumbs } from "../redux/slices/breadCrumbMeterSlice";
import ApartamentConsumtionsTab from "./ApartamentConsumtionsTab";
import { cleanMeterId } from "../redux/slices/condominiumSlice";
import {
  setAppTopAllowed,
  setAppBotAllowed,
} from "../redux/slices/condominiumSlice";

import {
  setApartametnMeterModal,
  setApartamentIdentifier,
  setApartamentName,
  setActualAppPos,
  setSubConName,
} from "../redux/slices/condominiumSlice";
import { setTreeExpanded } from "../redux/slices/condominiumSlice";
import { setGateway } from "../redux/slices/gatewaySlice";
import { CondominiumTree } from "../components/condominium/CondominiumTree";
import ApartamentMeter from "../components/condominium/ApartamentMeter";
import ModalDialog from "../components/meters/ModalDialog";
import CondominiumTabs from "../components/condominium/CondominiumTabs";
import ApartamentReadingTab from "./ApartamentReadingTab";
const sortChildrenByPosition = (children) => {
  return [...children]?.sort((a, b) => {
    const positionA = a.attributes?.position;
    const positionB = b.attributes?.position;

    if (positionA === undefined) return 1;
    if (positionB === undefined) return -1;

    return positionA - positionB;
  });
};

const ObjectFieldTemplate = (props) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      rowSpacing={3}
      spacing={2}
    >
      {props.properties?.map((element, i) => {
        /* if (i == 5 || i == 4) {
                    return (
                      <Grid key={element.id} alignItems="center" item xs={12}>
                        {element.content}
                      </Grid>
                    );
                  } */
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const flutEnums = [
  { const: "Piano interrato -2" },
  { const: "Piano interrato -1" },
  { const: "Piano terra" },
  { const: "Piano marciapiede" },
  { const: "Piano 1" },
  { const: "Piano 2" },
  { const: "Piano 3" },
  { const: "Piano 4" },
  { const: "Piano 5" },
  { const: "Piano 6" },
  { const: "Piano 7" },
  { const: "Piano 8" },
  { const: "Piano 9" },
  { const: "Piano 10" },
];
/**
 * A custom component for displaying and managing sub-condominium details.
 *
 * @component TextFieldNumber
* @returns {JSX.Element}.
 
 *
 */
export const ApartmentShow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [topElement, setTopElement] = useState(null);
  const [newAppUpd, { isSuccess: appUdpSuccess }] =
    store.useUpdateApartmentMutation();
  const apartamentIdentifier = useSelector(
    (state) => state.condominiumSlice.apartamentIdentifier
  );
  const [botElement, setBotElement] = useState(null);
  const [selectedElement, setSelctedElement] = useState(null);
  const [getMeterId] = store.useLazyGetMetertIdQuery();
  const [getSubconId] = store.useLazyGetSubCondominiumIdQuery();
  const topAppPos = useSelector((state) => state?.condominiumSlice?.topAppPos);
  const botAppPos = useSelector((state) => state?.condominiumSlice?.botAppPos);
  const [readyForNavigate, setReadyFornavigate] = useState(false);
  const actualAppPos = useSelector(
    (state) => state?.condominiumSlice?.actualAppPos
  );
  const [
    updateApartament,
    { isSuccess: updateApSuccess, isLoading: updateLoading },
  ] = store.useUpdateCondominiumIfnoMutation();
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const posDirection = useSelector(
    (state) => state?.condominiumSlice?.posDirection
  );
  const apartamentMeter = useSelector(
    (state) => state.condominiumSlice.apartamentMeter
  );
  const condominiumShow = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  );
  const apartamentMeterModal = useSelector(
    (state) => state.condominiumSlice.apartamentMeterModal
  );
  const maxAparamentPosition = useSelector(
    (state) => state?.condominiumSlice?.maAxppPos
  );
  const apartamentIndex = useSelector(
    (state) => state?.condominiumSlice?.apartamentIndex
  );
  const nextPos = maxAparamentPosition + 1;
  const [firstRender, setFirstRender] = useState(false);
  const autoLoading = useSelector((state) => state?.loadingSlice?.autoLoading);
  const loading = useSelector((state) => state?.loadingSlice?.loading);
  const meterId = useSelector((state) => state?.condominiumSlice?.meterId);
  const [formData, setFormData] = useState({});
  const [readyForCreate, setReadyForCreate] = useState(false);
  const subCondominiumIdentifier = useSelector(
    (state) => state.condominiumSlice.subCondominiumIdentifier
  );
  const treeExpanded = useSelector(
    (state) => state?.condominiumSlice?.treeExpanded
  );
  const condominiumTreeData = useSelector(
    (state) => state?.condominiumSlice?.condominiumTreeData
  );
  const data = useSelector((state) => state?.condominiumSlice?.condominiumShow);
  const subConName = useSelector(
    (state) => state?.condominiumSlice?.subCondominiumName
  );
  const [createApartament, { isSuccess: createApartamentSuccess }] =
    store.useCreateApartmentMutation();
  const [getCondominiumShow, { isFetching }] =
    store.useLazyCondominiumShowQuery();
  const { id, subCondominumName, apartmentName } = useParams();
  /*   const { getCond } = store.useLazyCondominiumShowQuery(id); */

  const subCondominiumId = useSelector(
    (state) => state?.condominiumSlice?.subCondominiumId
  );
  const condominiumId = useSelector(
    (state) => state?.condominiumSlice?.condominiumId
  );

  const regex = /^gateway$/i;
  const subcondominium = useMemo(() => {
    return (data?.children?.filter(
      (c) => c.attributes?.name === subCondominumName
    ) ?? [])[0];
  }, [data, subCondominumName]);
  useEffect(() => {}, [data]);
  let sortedApartments =
    subcondominium && sortChildrenByPosition(subcondominium.children);
  useEffect(() => {
    sortedApartments =
      subcondominium && sortChildrenByPosition(subcondominium.children);
  }, [condominiumShow]);
  const apartment = useMemo(() => {
    return (subcondominium?.children?.filter(
      (c) => c.attributes?.name === decodeURIComponent(apartmentName)
    ) ?? [])[0];
  }, [apartmentName, subcondominium]);
  const gateway = useMemo(() => {
    return (subcondominium?.children?.filter(
      (c) =>
        regex.test(c?.attributes?.class || "") &&
        c.attributes.name === apartmentName
    ) ?? [])[0];
  }, [apartmentName, subcondominium]);
  useEffect(() => {}, [sortedApartments]);
  useEffect(() => {
    dispatch(setSubConName(subCondominumName));
  }, [subCondominumName]);
  useEffect(() => {
    if (appUdpSuccess || updateApSuccess) {
      getCondominiumShow(id);
    }
  }, [appUdpSuccess, updateApSuccess]);

  useEffect(() => {
    if (appUdpSuccess) {
      dispatch(setMessage(t("update_success")));
      dispatch(loadingOff());
    }
  }, [appUdpSuccess]);
  useEffect(() => {
    if (!condominiumShow) {
      getCondominiumShow(id);
    }
  }, [condominiumShow]);

  useEffect(() => {
    if (apartment) {
      dispatch(
        setBreadCrumbs({
          id: condominiumShow?.name,
          subCondominumName: subcondominium?.attributes?.name,
          apartmentName: apartment?.attributes?.name,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [apartment]);

  /*   useEffect(() => {
    if (gateway && !meterId) {
      getMeterId(gateway?.attributes?.identifier);
      
    }
  }, [gateway, meterId]); */
  const gatewasik = useSelector((state) => state?.gatewaySlice?.gateway);
  useEffect(() => {}, [apartment]);
  useEffect(() => {
    if (subcondominium) {
      dispatch(
        setSubCondominiumIdentifier(subcondominium?.attributes.identifier)
      );
    }
  }, [subcondominium]);
  useEffect(() => {
    if (apartment) {
      setFormData(apartment?.attributes);
      dispatch(setApartamentIdentifier(apartment?.attributes?.identifier));
      dispatch(setActualAppPos(+apartment?.attributes?.position));
    }
  }, [apartment]);
  useEffect(() => {
    dispatch(setApartamentName(apartmentName));
  }, [apartmentName]);
  useEffect(() => {
    if (createApartamentSuccess) {
      if (readyForNavigate) {
        setTimeout(() => {
          getCondominiumShow(condominiumId);
          const newPath = window.location.pathname.replace(
            "new",
            formData.name
          );
          navigate(newPath);
          dispatch(setTreeExpanded([...treeExpanded, subConName]));
          dispatch(loadingOff());
          dispatch(setMaxAppPos(maxAparamentPosition + 1));
        }, 500);
      } else {
        dispatch(loadingOff());
      }
    }
  }, [createApartamentSuccess, readyForNavigate]);
  useEffect(() => {
    setSelctedElement(sortedApartments?.[apartamentIndex]);
    setTopElement(sortedApartments?.[apartamentIndex - 1]);
    setBotElement(sortedApartments?.[apartamentIndex + 1]);
  }, [sortedApartments]);
  useEffect(() => {}, [subcondominium]);
  useEffect(() => {}, [subCondominumName]);
  const { t } = useTranslation();
  useEffect(() => {
    if (meterId) {
      dispatch(setBtnDetector(6));
    }
  }, [meterId]);

  /*   useEffect(() => {
    if (
      apartment?.attributes?.class == "gateway" ||
      apartment?.attributes?.class == "Gateway"
    ) {
      const fields = {};
      dispatch(setGateway({ fields: { ...apartment?.attributes } }));
    }
  }, [apartment]);
 */
  const location = window.location.href;
  const ownercheck =
    apartment?.attributes?.owner_name ===
      apartment?.attributes?.occupant_name &&
    apartment?.attributes?.owner_mail ===
      apartment?.attributes?.occupant_mail &&
    apartment?.attributes?.owner_phone_number ===
      apartment?.attributes?.occupant_phone_number;
  const schema = {
    title: "New apartment",
    type: "object",
    properties: {
      flat: {
        $id: "1",
        type: "string",
        title: t("flat"),
      },
      internal: {
        $id: "11",
        type: "string",
        title: t("internal"),
      },
      name: {
        $id: "2",
        type: "string",
        title: t("name"),
      },
      occupant_mail: {
        $id: "3",
        type: "string",
        title: t("occupant_mail"),
      },
      occupant_name: {
        $id: "4",
        type: "string",
        title: t("occupant_name"),
      },
      owner_mail: {
        $id: "5",
        type: "string",
        title: t("owner_mail"),
      },
      owner_name: {
        $id: "6",
        type: "string",
        title: t("owner_name"),
      },

      owner_phone_number: {
        $id: "9",
        type: "string",
        title: t("owner_phone_number"),
      },
      occupant_phone_number: {
        $id: "10",
        type: "string",
        title: t("occupant_phone_number"),
      },
      occupant_is_owner: {
        id: 7,
        type: "boolean",
        title: t("occupant_is_owner"),
      },
    },
    required: ["name", "occupant_name"],
  };
  /*   useEffect(() => {
    getSubconId("95f41a24-ef0d-11ed-89ce-40b076d8dbd4");
  }, []); */
  useEffect(() => {}, [apartment]);
  /**
   * Function that handles the "Create" or "Update" action for an apartment in the condominium.
   * If an existing apartment is being updated, it updates the apartment details using the 'updateApartament' function.
   * If a new apartment is being created, it prepares the attributes and sets 'readyForCreate' to true.
   */
  const onCreateClick = () => {
    dispatch(loadingOn());

    if (apartment) {
      /*  const attributes = {
        fields: {
          occupant_is_owner: formData?.occupant_is_owner,
          active_network_adapters:
            apartment?.attributes?.active_network_adapters,
          class: apartment?.attributes.class,
          coordinates: apartment?.attributes?.coordinates,
          flat: formData.flat,
          identifier: apartment?.attributes?.identifier,
          identifier_type: apartment?.attributes?.identifier_type,
          master_key: apartment?.attributes?.master_key,
          name: formData?.name,
          serial_number:
            apartment?.attributes?.[apartment?.attributes?.identifier_type],
          network_adapters: apartment?.attributes?.network_adapters,
          occupant_mail: formData?.occupant_mail,
          occupant_name: formData?.occupant_name,
          occupant_phone_number: formData?.occupant_phone_number,
          owner_mail: formData?.owner_mail,
          owner_name: formData?.owner_name,
          owner_phone_number: formData?.owner_phone_number,
          parent: apartment?.attributes?.parent,
          security: apartment?.attributes?.security,
          status: apartment?.attributes?.status,
          tag: apartment?.attributes?.tag,
          sub_condominium_id: apartment?.attributes?.identifier,
          internal: formData?.internal,
      
        },
        meta_data: {},
      }; */
      const attributes = {
        fields: {
          occupant_is_owner: formData?.occupant_is_owner,
          active_network_adapters:
            apartment?.attributes?.active_network_adapters,
          class: apartment?.attributes.class,
          coordinates: apartment?.attributes?.coordinates,
          flat: formData.flat,
          identifier: apartment?.attributes?.identifier,
          identifier_type: apartment?.attributes?.identifier_type,
          master_key: apartment?.attributes?.master_key,
          name: formData?.name,
          /*    serial_number: apartamentMeter?.attributes?.serial_number, */
          network_adapters: apartment?.attributes?.network_adapters,
          occupant_mail: formData?.occupant_mail,
          occupant_name: formData?.occupant_name,
          occupant_phone_number: formData?.occupant_phone_number,
          owner_mail: formData?.owner_mail,
          owner_name: formData?.owner_name,
          owner_phone_number: formData?.owner_phone_number,
          parent: apartment?.attributes?.parent,
          security: apartment?.fields?.security,
          status: apartment?.fields?.status,
          tag: apartment?.attributes?.tag,
          /*   sub_condominium_id: apartamentMeter?.id, */
          internal: formData?.internal,
        },
        meta_data: {},
      };
      newAppUpd({
        id: apartment?.attributes?.identifier,
        attributes: attributes,
      });
      navigate(
        `/condominiums/${id}/subcondominiums/${subCondominumName}/apartments/${formData?.name}`
      );
    } else {
      getSubconId(subCondominiumIdentifier);
      setReadyForCreate(true);
    }
  };
  useEffect(() => {}, [botElement, topElement, selectedElement]);
  useEffect(() => {}, [apartment, apartamentMeter]);
  useEffect(() => {
    if (sortedApartments?.length > 0) {
      const actualIndex = sortedApartments?.findIndex(
        (item) =>
          item?.attributes?.identifier === apartment?.attributes?.identifier
      );
      if (actualIndex !== -1) {
        dispatch(setAppIndex(actualIndex));
      }
    }
  }, [sortedApartments]);
  useEffect(() => {
    if (apartamentIdentifier) {
      dispatch(setTreeItem(apartamentIdentifier));
    }
  }, [apartamentIdentifier]);
  useEffect(() => {
    if (subCondominiumId && readyForCreate) {
      const data = {
        ...formData,
        sub_condominium_id: +subCondominiumId,
        position: nextPos?.toString(),
        subcondominium_name: subConName,
      };
      createApartament(data)
        ?.unwrap()
        ?.then((data) => {
          if (data) {
            dispatch(setTreeItem(data.data.attributes.fields.identifier));
            dispatch(setBtnDetector(3));
            setReadyFornavigate(true);
          } else {
            dispatch(setBlockTabName("block_approve"));
            dispatch(loadingOff());
          }
        })
        ?.catch((error) => {
          console.error("Error occurred:", error);
          dispatch(loadingOff());
        });
      setReadyForCreate(false);
    }
  }, [subCondominiumId, readyForCreate]);
  useEffect(() => {
    dispatch(setBtnDetector(3));
  }, []);
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("save"),
      classNames: "submit-button",
    },
    "ui:order": [
      "flat",
      "internal",
      "name",
      "occupant_name",
      "occupant_mail",
      "occupant_phone_number",
      "occupant_is_owner",
      "owner_name",
      "owner_mail",
      "owner_phone_number",
    ],
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
  };
  useEffect(() => {}, [formData]);
  /*  useEffect(() => {
    return () => {
      window.sessionStorage.setItem("bcName", "");
    };
  }, []); */
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const customValidate = (formData, errors) => {
    if (formData.owner_mail !== "" && formData.owner_mail) {
      if (!emailRegex.test(formData.owner_mail)) {
        errors?.owner_mail?.addError(t("invalid_email"));
      }
    }
    if (formData.occupant_mail) {
      if (!emailRegex.test(formData.occupant_mail)) {
        errors?.occupant_mail?.addError(t("invalid_email"));
      }
    }
    return errors;
  };
  useEffect(() => {
    if (
      apartment?.attributes?.class === "gateway" ||
      apartment?.attributes?.class === "Gateway"
    ) {
      dispatch(setGatewayMode(true));
    } else {
      dispatch(setGatewayMode(false));
    }
  }, [apartment]);
  /**
   * Function that updates the details of the apartment in the upward direction.
   * If the 'updateLoading' flag is true, the function returns early without performing any updates.
   * Otherwise, it prepares the necessary attribute objects for the actual apartment and the one above it.
   * It then calls the 'updateApartament' function twice in sequence to update the attributes of both apartments.
   *
   * The 'updateApartament' function takes two parameters: 'twinId' and 'instanceId'.
   * - 'twinId' represents the digital twin ID associated with the apartment.
   * - 'instanceId' is the identifier of the specific instance of the apartment.
   *
   * The 'actualAttribuste' object contains the updated attributes for the current apartment:
   * - 'position': The current position value of the apartment is decreased by 1.
   * - 'coordinates': The coordinates of the apartmentMeter.
   * - 'serial_number': The serial number of the apartmentMeter.
   * - 'class': The class of the apartmentMeter.
   *
   *
   * The 'topAttributes' object contains the updated attributes for the apartment above the current one:
   * - 'position': The position of the apartment above is increased by 1.
   * - 'coordinates': The coordinates of the topElement.
   * - 'serial_number': The serial number of the topElement.
   * - 'class': The class of the topElement.
   *
   * After preparing the attribute objects, the function performs the updates asynchronously using promises.
   * Once the updates are completed, the function returns.
   */

  useEffect(() => {}, [apartment, apartamentMeter]);
  const topDirectionFunc = () => {
    if (updateLoading) {
      return;
    } else {
      const actualAttribuste = {
        fields: {
          position: topAppPos?.toString(),
          coordinates: apartment?.attributes?.coordinates,
          serial_number:
            apartment?.attributes?.[apartment?.attributes?.identifier_type],

          class: apartment?.attributes?.class,
        },
        meta_data: {},
      };
      const topAttributes = {
        fields: {
          position: actualAppPos?.toString(),
          coordinates: topElement?.attributes?.coordinates,
          serial_number: topElement?.attributes?.identifier,
          class: topElement?.attributes?.class,
        },
        meta_data: {},
      };
      updateApartament({
        twinId: apartment?.attributes?.digital_twin_id,
        instanceId: apartment?.attributes?.identifier,
        attributes: actualAttribuste,
      })
        .then(() => {
          updateApartament({
            twinId: apartment?.attributes?.digital_twin_id,
            instanceId: topElement?.attributes?.identifier,
            attributes: topAttributes,
          });
        })
        .then(() => {
          dispatch(setAppIndex(apartamentIndex - 1));
        });
    }
  };
  /**
   * Function that updates the details of the apartment in the downward direction.
   * If the 'updateLoading' flag is true, the function returns early without performing any updates.
   * Otherwise, it prepares the necessary attribute objects for the actual apartment and the one below it.
   * It then calls the 'updateApartament' function twice in sequence to update the attributes of both apartments.
   *
   * The 'updateApartament' function takes two parameters: 'twinId' and 'instanceId'.
   * - 'twinId' represents the digital twin ID associated with the apartment.
   * - 'instanceId' is the identifier of the specific instance of the apartment.
   *
   * The 'actualAttribuste' object contains the updated attributes for the current apartment:
   * - 'position': The current position value of the apartment is increased by 1.
   * - 'coordinates': The coordinates of the apartmentMeter.
   * - 'serial_number': The serial number of the apartmentMeter.
   * - 'class': The class of the apartmentMeter.
   *
   *
   * The 'botAttributes' object contains the updated attributes for the apartment below the current one:
   * - 'position': The position of the apartment below is decreased by 1.
   * - 'coordinates': The coordinates of the botElement.
   * - 'serial_number': The serial number of the botElement.
   * - 'class': The class of the botElement.
   *
   * After preparing the attribute objects, the function performs the updates asynchronously using promises.
   * Once the updates are completed, the function returns.
   */
  const botDirectionFunc = () => {
    if (updateLoading) {
      return;
    } else {
      const actualAttribuste = {
        fields: {
          position: botAppPos?.toString(),
          coordinates: apartment?.attributes?.coordinates,
          serial_number:
            apartment?.attributes?.[apartment?.attributes?.identifier_type],

          class: apartment?.attributes?.class,
        },
        meta_data: {},
      };
      const botAttributes = {
        fields: {
          position: actualAppPos?.toString(),
          coordinates: botElement?.attributes?.coordinates,
          serial_number: botElement?.attributes?.identifier,
          class: botElement?.attributes?.class,
        },
        meta_data: {},
      };

      updateApartament({
        twinId: apartment?.attributes?.digital_twin_id,
        instanceId: apartment?.attributes?.identifier,
        attributes: actualAttribuste,
      })
        .then(() => {
          updateApartament({
            twinId: apartment?.attributes?.digital_twin_id,
            instanceId: botElement?.attributes?.identifier,
            attributes: botAttributes,
          });
        })
        .then(() => {
          dispatch(setAppIndex(apartamentIndex + 1));
        });
    }
  };
  useEffect(() => {
    if (posDirection === "appTop") {
      topDirectionFunc();
      dispatch(setPosDirection(null));
    }
    if (posDirection === "appDown") {
      botDirectionFunc();
      dispatch(setPosDirection(null));
    }
  }, [posDirection]);
  useEffect(() => {
    if (
      !topElement ||
      selectedElement?.attributes?.class == "gateway" ||
      topElement?.attributes?.position == undefined
    ) {
      dispatch(setAppTopAllowed(false));
    } else {
      dispatch(setAppTopAllowed(true));
    }
  }, [topElement]);
  useEffect(() => {
    dispatch(
      setAppElemPositions({
        bot: botElement?.attributes?.position,

        top: topElement?.attributes?.position,
      })
    );
  }, [botElement, topElement]);
  useEffect(() => {
    if (
      !botElement ||
      selectedElement?.attributes?.class == "gateway" ||
      botElement?.attributes?.position == undefined
    ) {
      dispatch(setAppBotAllowed(false));
    } else {
      dispatch(setAppBotAllowed(true));
    }
  }, [botElement]);
  useEffect(() => {}, [formData.owner_mail]);
  useEffect(() => {
    if (formData.occupant_is_owner && formData.occupant_name) {
      setFormData((prev) => ({
        ...prev,
        owner_name: formData.occupant_name,
      }));
    }
    if (formData.occupant_is_owner && formData.occupant_mail) {
      setFormData((prev) => ({
        ...prev,
        owner_mail: formData.occupant_mail,
      }));
    }
    if (formData.occupant_is_owner && formData.occupant_phone_number) {
      setFormData((prev) => ({
        ...prev,
        owner_phone_number: formData.occupant_phone_number,
      }));
    }
    if (
      formData.occupant_is_owner &&
      formData.occupant_name?.length == undefined
    ) {
      setFormData((prev) => ({
        ...prev,
        owner_name: "",
      }));
    }
    if (
      formData.occupant_is_owner &&
      formData.occupant_mail?.length == undefined
    ) {
      setFormData((prev) => ({
        ...prev,
        owner_mail: "",
      }));
    }
    if (
      formData.occupant_is_owner &&
      formData.occupant_phone_number?.length == undefined
    ) {
      setFormData((prev) => ({
        ...prev,
        owner_phone_number: "",
      }));
    }
    /* if (!formData.occupant_is_owner) {
      setFormData((prev) => ({
        ...prev,
        owner_name: "",
        owner_mail: "",
        owner_phone_number: "",
      }));
    } */
  }, [
    formData.occupant_mail,
    formData.occupant_name,
    formData.occupant_phone_number,
    formData.occupant_is_owner,
  ]);
  useEffect(() => {
    if (apartment && apartment && ownercheck) {
      setFormData((prev) => ({
        ...prev,
        occupant_is_owner: true,
      }));
    }
  }, [apartment, ownercheck]);

  return (
    <>
      {!apartment && (
        <Typography sx={{ mb: 4 }} variant="h4">
          {t("new_apartament")}
        </Typography>
      )}
      {apartment && (
        <LongNameWrapper
          text={decodeURIComponent(apartmentName)}
          size={appBarIsOpen ? "75vw" : "95vw"}
          variant={"h4"}
          maxLength={100}
        />
      )}
      <ModalDialog
        title={"Associazione meter"}
        open={apartamentMeterModal}
        close={() => dispatch(setApartametnMeterModal(false))}
      >
        <ApartamentMeter open={apartamentMeterModal} />
      </ModalDialog>
      <Grid container>
        <Grid item xs={2}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            {condominiumTreeData && (
              <CondominiumTree
                condominium={condominiumTreeData}
                subcondominiumName={subCondominumName}
                apartmentName={apartmentName}
                apartment={apartment}
                subcondominium={subcondominium}
              />
            )}
          </Box>
        </Grid>
        {apartment?.attributes?.class !== "gateway" && (
          <Grid item xs={10}>
            <CondominiumTabs
              mainTab={
                <Form
                  schema={schema}
                  uiSchema={uiSchema}
                  //fields={fields}
                  formData={formData}
                  onSubmit={onCreateClick}
                  onChange={(changeEvent) => setFormData(changeEvent.formData)}
                  validator={validator}
                  showErrorList={false}
                  customValidate={customValidate}
                  noHtml5Validate
                  children={true}
                >
                  <IwdAuthWrapper
                    children={
                      <Box
                        sx={{ mt: 2 }}
                        display={"flex"}
                        justifyContent={"flex-end"}
                      >
                        <Button
                          disabled={!formData?.name || !formData?.occupant_name}
                          onClick={onCreateClick}
                          variant="contained"
                        >
                          {t("save")}
                        </Button>
                      </Box>
                    }
                    section={"block_save_btns"}
                  />
                </Form>
              }
              readings={<ApartamentReadingTab condominiumId={id} />}
              consume={<ApartamentConsumtionsTab condominiumId={id} />}
            />
          </Grid>
        )}
        {(apartment?.attributes?.class === "gateway" ||
          apartment?.attributes?.class === "Gateway") && (
          <Grid itex xs={9} sx={{ ml: 1 }}>
            <GatewayShow idFromCondominium={gateway?.attributes?.identifier} />
          </Grid>
        )}
      </Grid>
    </>
  );
};
