import validator from "@rjsf/validator-ajv6";
import { useRef } from "react";
import { Form } from "@rjsf/mui";
import { ErrorListProps } from "@rjsf/utils";
import Point from "./formComponents/Point";
import React from "react";
import { store } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader";
import SecurityWidget from "./formComponents/customWidgets/SecurityWidget";
import ImpulsiveInput from "./formComponents/ImpulsiveInput";
import MuiAlert from "@mui/material/Alert";
import { unSetDigitalTwin } from "../../redux/slices/digitalTwinSlice";
import Snackbar from "@mui/material/Snackbar";
import theme from "../../theme";
import DigitalTwinImage from "./formComponents/DigitalTwinImage";
import { setRestTwin } from "../../redux/slices/actionsSlice";
import HeatMeterForm from "./formComponents/HeatMeterForm";
import { IwdFormAuthWrapper } from "../../utils/IwdFormAuthWrapper";
import {
  setBlockTabName,
  setCreatedMeterSerial,
} from "../../redux/slices/condominiumSlice";
import { Button, Grid, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { logout } from "../../redux/slices/logoutSlice";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import SearcheableSelect from "./formComponents/SearcheableSelect";
import { setCreatedMeter } from "../../redux/slices/condominiumSlice";
import SearchOnServer from "./formComponents/SearchOnServer";
import ListItemText from "@mui/material/ListItemText";
import { setApartametnMeterModal } from "../../redux/slices/condominiumSlice";
import ErrorsHandler from "../../utils/ErrorsHandler";
import DtwinCustomField from "./formComponents/DtwinCustomField";
/* import { postDigitalTwinInstance } from "../../redux/slices/ditigalTwinInstance"; */

import { Box } from "@mui/system";

import { useEffect, useState } from "react";
import { setSelectedMeter } from "../../redux/slices/meterSlice";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        if (element.name === "security") {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        if (i == 12 && props.properties.length === 13) {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        /*   if (i == 13 && props.properties.length === 13) {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        } */
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const excludedMeterClasses = [
  "gateway",
  "Gateway",
  "condominium",
  "Condominium",
  "sub_condominium",
  "Sub_condominium",
  "apartment",
  "Apartment",
];
const CreateMeterFromBlock = () => {
  const fromRef = useRef(null);
  const navigate = useNavigate();
  const [getCondominiumShow, { isSuccess: showSuccess }] =
    store.useLazyCondominiumShowQuery();
  const [postCommands, { isSuccess: postSuccess }] =
    store.usePostCommandsMutation();
  const [meterId, setMeterId] = useState();
  const dispatch = useDispatch();
  const security = IwdFormAuthWrapper({ section: "security" });
  const [createAssocMeter, isSuccess] = store.useCreateAssocMeterMutation();
  const [
    postDigitalTwinInstance,
    { data, isLoading, error, isSuccess: createSuccess },
  ] = store.usePostDigitalTwinInstanceMutation();

  const { id } = useParams();
  const apartmentId = useSelector(
    (state) => state?.condominiumSlice?.apartamentIdentifier
  );
  const condominiumId = useSelector(
    (state) => state?.condominiumSlice?.condominiumIdentifier
  );
  const subcondId = useSelector(
    (state) => state?.condominiumSlice?.subCondominiumIdentifier
  );
  const condName = useSelector(
    (state) => state?.condominiumSlice?.condominiumName
  );
  const subcondName = useSelector(
    (state) => state?.condominiumSlice?.subCondominiumName
  );
  const apartmentName = useSelector(
    (state) => state?.condominiumSlice?.apartamentName
  );
  const [open, setOpen] = useState(false);
  const [openErr, setOpenErr] = useState(false);
  const { t } = useTranslation();
  const selectedMeter = useSelector(
    (state) => state?.meterSlice?.selectedMeter
  );
  const digitalTwinName = useSelector(
    (state) => state?.digitalTwinSlice?.digitalTwin?.[0]?.attributes?.name
  );
  const condominiumShow = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  );
  const oldSerial = useSelector(
    (state) => state?.meterSlice?.meterShow?.attributes?.serial_number
  );
  const loraMeterId = useSelector((state) => state?.gatewaySlice?.loraMeterId);
  const [mappedProfile, setMappedProfile] = useState([]);
  const selectedMeterClass =
    selectedMeter?.attributes?.digital_twin_fields?.class?.split(":")?.[2];
  const meterClass = selectedMeter?.attributes?.digital_twin_fields?.class;
  const meterType = useSelector(
    (state) =>
      state?.meterSlice?.selectedMeter?.attributes?.digital_twin_fields?.type
  );
  const [heatValidation, setHeatValidation] = useState("waiting");
  const loraProfiles = useSelector(
    (state) => state?.gatewaySlice?.loraProfiles
  );
  const checkValidation = () => {
    fromRef.current.submit();
  };
  const [formData, setFormData] = useState({});
  const diameter = useSelector(
    (state) => state.digitalTwinSlice.digitalTwin
  )?.[0]?.attributes?.digital_twin_fields?.diameter;
  const identifireType = useSelector(
    (state) => state.digitalTwinSlice.digitalTwin
  )?.[0]?.attributes?.digital_twin_fields?.identifier_type;
  const digitalTwinId = useSelector(
    (state) => state.digitalTwinSlice.digitalTwin
  )?.[0]?.id;
  const imageId =
    useSelector((state) => state.digitalTwinSlice.digitalTwin)?.[0]?.attributes
      ?.digital_twin_fields?.image_id ?? null;
  const classStateSelector = ["classesSlice", "classes"];
  useEffect(() => {}, [meterClass]);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  /*   useEffect(() => {
    if (isSuccess) {
      dispatch(setCreatedMeterSerial(formData?.serial));
    }
  }, [isSuccess]); */
  const meterTypeBackendWrapper = (type) => {
    switch (type) {
      case "HEAT/COLD":
      case "CALORIE/FRIGORIE":
        return "CALORIE/FRIGORIE";
      case "Heat":
      case "Calorie":
        return "Calorie";
      case "Cold":
      case "Frigorie":
        return "Frigorie";
      case "HCA":
      case "RIPARTITORE":
        return "RIPARTITORE";
      case "AFS":
        return "AFS";
      case "ACS":
        return "ACS";
    }
  };
  const meterTypeEnum = (type) => {
    switch (type) {
      case "08":
      case "8":
        return [{ const: t("distributor") }];
        break;
      case "6":
      case "06":
      case "7":
      case "07":
      case "21":
      case "15":
      case "22":
      case "16":
        return [{ const: "AFS" }, { const: "ACS" }];
        break;
      case "10":
      case "0A":
      case "11":
      case "0B":
      case "12":
      case "0C":
      case "13":
      case "0D":
        return [
          { const: t("cal_fri") /* "CALORIE / FRIGORIE" */ },
          { const: t("calories") },
          { const: t("frigories") },
        ];
        break;
      default:
        return [
          { const: "AFS" },
          { const: "ACS" },
          { const: t("distributor") },
          { const: t("cal_fri") /* "CALORIE / FRIGORIE" */ },
          { const: t("calories") },
          { const: t("frigories") },
          /* { const: t("AFS_ACS") }, */
        ];
        break;
    }
  };
  const muiTheme = createTheme({
    props: {
      MuiTextField: {
        variant: "outlined",
      },
    },
  });

  useEffect(() => {}, [digitalTwinName]);
  useEffect(() => {
    if (loraProfiles) {
      setMappedProfile(
        loraProfiles?.map((item) => ({
          const: item?.name,
        }))
      );
    }
  }, [loraProfiles]);
  useEffect(() => {
    /* if (selectedMeterClass == "08") {
      setFormData((prev) => ({
        ...prev,
        meter_type: t("distributor"),
      }));
    }
    if (selectedMeterClass == "07") {
      setFormData((prev) => ({
        ...prev,
        meter_type: t("AFS"),
      }));
    } */

    switch (selectedMeterClass) {
      case "08":
      case "8":
        return setFormData((prev) => ({
          ...prev,
          meter_type: t("distributor"),
        }));
      case "6":
      case "06":
      case "7":
      case "07":
      case "21":
      case "15":
      case "22":
      case "16":
        return setFormData((prev) => ({
          ...prev,
          meter_type: t("AFS"),
        }));
      case "10":
      case "0A":
      case "11":
      case "0B":
      case "12":
      case "0C":
      case "13":
      case "0D":
        return setFormData((prev) => ({
          ...prev,
          meter_type: t("cal_fri"),
        }));
      default:
        return setFormData((prev) => ({
          ...prev,
          meter_type: t("AFS"),
        }));
    }
  }, [selectedMeterClass]);

  const defaultrequired = [
    "serial",

    "coordinates",

    "time_zone",
    "digital_twin",
  ];
  const defaultSchema = {
    serial: {
      $id: "1",
      type: "string",
      length: "10",
      title: t("serial"),
    },
    key: {
      $id: "2",
      type: "string",
      title: t("key"),
    },
    meter_type: {
      type: "string",
      oneOf: meterTypeEnum(selectedMeterClass),
      title: t("meter_type"),
      default: t("distributor"),
    },
    pod: {
      $id: "3",
      type: "string",
      title: t("POD"),
    },
    address: {
      $id: "4",
      type: "string",
      title: t("street_address"),
    },
    /* 
    dataConfigurazione: {
      $id: "5",
      type: "string",
      format: "date-time",
      title: t("configuration_date"),
    }, */

    time_zone: {
      $id: "6",
      type: "string",
      title: t("time_zone"),
      url: "time_zone",
      defaultTimeZone: true,
      default: "Europe/Rome",

      mapFn: (result) =>
        result?.data?.data?.map((item) => ({
          key: item?.attributes?.key,
          label: item?.attributes?.name,
          value: item?.attributes?.id,
        })),
    },
    digital_twin: {
      $id: "7",

      mapFn: (result) =>
        result?.data?.data
          ?.map((item) => ({
            key: item.attributes?.id,
            label: `${item.attributes?.name} (${item.attributes?.version})`,
            value: `${item.attributes?.name} (${item.attributes?.version})`,
            tags: item?.attributes?.digital_twin_fields?.tags,
          }))
          ?.filter(
            (item) =>
              item?.tags?.includes("Meter") || item?.tags?.includes("meter")
          ),
      /*    stateSelector: classStateSelector, */

      url: "altior/digital_twin",
      type: "string",

      title: t("digital_twin"),
      dispatched: true,
      checkbox: true,
    },
    reverse: {
      $id: "8",
      type: "boolean",
      title:
        meterClass === "MAD:00:0D"
          ? "Installato in mandata"
          : t("mounted_reverse"),
      visible: false,
    },

    tags: {
      $id: "10",
      title: t("tag"),
      type: "array",
      url: "tag",
      multiple: true,

      properties: {
        tags: {
          type: "number",
        },
      },
    },
    coordinates: {
      $id: "12",
      type: "object",
      properties: {
        lat: {
          $id: "28",
          type: "number",
          default: 0,
        },
        lng: {
          $id: "29",
          type: "number",
          default: 0,
        },
      },
    },
    note: {
      $id: "13",
      type: "string",
      title: t("notes"),
    },
    diameter: {
      $id: "11",
      type: "string",
      title: t("diameter"),
      readOnly: true,
    },
  };
  const schm = {
    title: "New Device",
    type: "object",
    properties: {
      serial: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("serial"),
      },
      key: {
        $id: "2",
        type: "string",
        title: t("key"),
      },
      meter_type: {
        type: "string",
        title: t("meter_type"),
        oneOf: meterTypeEnum(selectedMeterClass),
        default: t("AFS"),
      },
      pod: {
        $id: "3",
        type: "string",
        title: t("POD"),
      },
      address: {
        $id: "4",
        type: "string",
        title: t("street_address"),
      },

      /*     dataConfigurazione: {
        $id: "5",
        type: "string",
        format: "date-time",
        title: t("configuration_date"),
      }, */

      time_zone: {
        $id: "6",
        type: "string",
        title: t("time_zone"),
        url: "time_zone",
        defaultTimeZone: true,
        default: "Europe/Rome",

        mapFn: (result) =>
          result?.data?.data?.map((item) => ({
            key: item?.attributes?.key,
            label: item?.attributes?.name,
            value: item?.attributes?.id,
          })),
      },
      digital_twin: {
        $id: "7",

        mapFn: (result) =>
          result?.data?.data
            ?.map((item) => ({
              key: item.attributes?.id,
              label: `${item.attributes?.name} (${item.attributes?.version})`,
              value: `${item.attributes?.name} (${item.attributes?.version})`,
              tags: item?.attributes?.digital_twin_fields?.tags,
            }))
            ?.filter((item) => item?.tags?.includes("Meter")),
        /*    stateSelector: classStateSelector, */

        url: "altior/digital_twin",
        type: "string",
        title: t("digital_twin"),
        dispatched: true,
        checkbox: true,
      },
      reverse: {
        $id: "8",
        type: "boolean",
        title: t("mounted_reverse"),
        visible: false,
      },

      tags: {
        $id: "10",
        title: t("tag"),
        type: "array",
        url: "tag",
        multiple: true,

        properties: {
          tags: {
            type: "number",
          },
        },
      },
      coordinates: {
        $id: "12",
        type: "object",
        properties: {
          lat: {
            $id: "28",
            type: "number",
            default: 0,
          },
          lng: {
            $id: "29",
            type: "number",
            default: 0,
          },
        },
      },
      note: {
        $id: "13",
        type: "string",
        title: t("notes"),
      },
      diameter: {
        $id: "11",
        type: "string",
        title: t("diameter"),
        readOnly: true,
      },
    },
    required: [
      "serial",
      "meter_type",

      "coordinates",

      "time_zone",
      "digital_twin",
    ],
  };
  const uiSchm = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:order": [
      "digital_twin",
      "name",
      "serial",
      "meter_type",
      "key",
      "pod",
      "address",
      "dataConfigurazione",
      "time_zone",
      "coordinates",

      "tags",
      "diameter",
      "reverse",

      "note",
      "image",
      "security",
      "lora",
    ],

    "ui:submitButtonOptions": {
      submitText: t("create"),

      props: {
        className: "bntListSearch",
      },
    },
    coordinates: {
      "ui:field": "point",
    },
    time_zone: {
      "ui:field": "search",
    },
    id_type: {
      "ui:field": "search",
    },
    note: {
      "ui:options": {
        widget: "textarea",
        minRows: 12,
      },
    },
    digital_twin: {
      "ui:field": "dtwin",
    },
    security: {
      "ui:field": "security",
    },
    tags: {
      "ui:field": "serverSearch",
    },
    image: {
      "ui:field": "image",
    },
    input_1: {
      "ui:field": "ImpulsiveInput",
    },
    input_2: {
      "ui:field": "ImpulsiveInput",
    },
    input_3: {
      "ui:field": "ImpulsiveInput",
    },
    lora: {
      "ui:field": "search",
    },
  };

  const [uiSchema, setUiSchema] = useState(uiSchm);
  const [schema, setSchema] = useState(schm);
  const customValidate = (formData, errors) => {
    /*    if (formData?.tags?.length < 1) {
     
      errors?.tags?.addError("is a required property");
    } */

    return errors;
  };
  useEffect(() => {}, [schm]);

  useEffect(() => {}, [error]);
  const errors = {};
  function mergeObjects(obj1, obj2) {
    if (obj1 !== undefined && obj2 !== undefined) {
      const result = Object.values(obj1);

      obj2.forEach((item2) => {
        const matchingIndex = result.findIndex(
          (item1) => item1.name === item2.name
        );

        if (matchingIndex !== -1) {
          result[matchingIndex] = { ...result[matchingIndex], ...item2 };
        } else {
          result.push({ ...item2 });
        }
      });

      return result;
    } else {
      return [];
    }
  }

  const meterSecurity =
    security && formData?.security?.length > 0
      ? {
          acl: [...formData?.security],
          groups: ["/network_adapter_manager_router", "/codec_manager"],
        }
      : {
          acl: [
            {
              name: "/admin",
              scopes: ["Elixir.show", "Elixir.update"],
            },
          ],
          groups: ["/admin"],
        };
  const onSubmitClick = (formData) => {
    /*    delete formData.meter_type; */

    customValidate(formData, formData.errors);
    const body = {
      data: {
        attributes: {
          condominium_id: id,
          apartment_id: apartmentId,
          /*     old_device_serial: oldSerial, */
          fields: {
            class: "",
            apartment_id: apartmentId,
            apartment_name: apartmentName,
            condominium_id: condominiumId,
            condominium_name: condName,
            sub_condominium_id: subcondId,
            sub_condominium_name: subcondName,
            active_network_adapters: [],
            network_adapters: [],
            identifier_type: identifireType,
            digital_twin_id: digitalTwinId?.toString(),
            roles: ["admin"],
            ...formData,
            meter_type: meterTypeBackendWrapper(formData?.meter_type),
            security: {
              ...meterSecurity,
              acl: mergeObjects(
                meterSecurity?.acl,
                condominiumShow?.security?.acl
              ),
            },

            coordinates: {
              coordinates: [
                formData?.coordinates?.lng,
                formData?.coordinates?.lat,
              ],
              type: "Point",
            },
          },
        },
        type: "plugin",
      },
    };
    if (formData.meter_type === t("distributor")) {
      setHeatValidation("validation");
    } else {
      createAssocMeter(body)
        ?.unwrap()
        ?.then((data) => {
          debugger;
          if (data) {
            dispatch(
              setCreatedMeterSerial(data?.data?.attributes?.serial_number)
            );
          } else {
            /*   dispatch(setCreatedMeterSerial("no_content")); */
            dispatch(setBlockTabName("block_approve"));
            dispatch(setApartametnMeterModal(false));
          }
          setFormData({});
          dispatch(setRestTwin(true));
        })
        ?.catch((error) => {
          console.error("Error occurred:", error);
        });
      setOpenErr(true);
    }
    if (digitalTwinName == "Maddalena ArrowWan V2 - Lora") {
      const attributes = {
        values: {
          command: "create_device",
          content: {
            app_key: formData?.app_key,
            profile: formData?.profile,
            node: formData?.node,
            dev_eui: formData?.dev_eui,
            app_eui: formData?.app_eui,
          },
        },
      };
      postCommands({
        attributes: attributes,
        commandName: "configure",
        digitalTwinId: window.LORA,
        digitalTwinInstance: loraMeterId,
      });
    }
  };
  /*   const testFunc = (obj) => {
    if (formData.meter_type === t("distributor")) {
    
      setHeatValidation("validation");
      
    } else {
      
    }
  }; */

  useEffect(() => {
    if (heatValidation === "validated") {
      const body = {
        data: {
          attributes: {
            condominium_id: id,
            apartment_id: apartmentId,
            /*   old_device_serial: oldSerial, */
            fields: {
              class: "",
              apartment_id: apartmentId,
              apartment_name: apartmentName,
              condominium_id: condominiumId,
              condominium_name: condName,
              sub_condominium_id: subcondId,
              sub_condominium_name: subcondName,
              active_network_adapters: [],
              network_adapters: [],
              identifier_type: identifireType,
              digital_twin_id: digitalTwinId?.toString(),
              roles: ["admin"],
              ...formData,
              meter_type: meterTypeBackendWrapper(formData?.meter_type),
              security: {
                ...meterSecurity,
                acl: mergeObjects(
                  meterSecurity?.acl,
                  condominiumShow?.security?.acl
                ),
              },
              coordinates: {
                coordinates: [
                  formData?.coordinates?.lng,
                  formData?.coordinates?.lat,
                ],
                type: "Point",
              },
            },
          },
          type: "plugin",
        },
      };
      createAssocMeter(body)
        ?.unwrap()
        ?.then((data) => {
          debugger;
          if (data) {
            dispatch(
              setCreatedMeterSerial(data?.data?.attributes?.serial_number)
            );
          } else {
            /*   dispatch(setCreatedMeterSerial("no_content")); */
            dispatch(setBlockTabName("block_approve"));
            dispatch(setApartametnMeterModal(false));
          }
          setFormData({});
          dispatch(setRestTwin(true));
        })
        ?.catch((error) => {
          console.error("Error occurred:", error);
        });
      setOpenErr(true);
    }
  }, [heatValidation]);

  useEffect(() => {}, [identifireType]);

  useEffect(() => {
    if (formData?.digital_twin) {
      const props = { ...defaultSchema };
      const req = [...defaultrequired, identifireType];
      const required = [...new Set(req)];
      setFormData((prev) => ({
        ...prev,
        diameter: diameter,
      }));

      if (identifireType) {
        if (meterType === "08") {
          delete props.reverse;
          delete props.diameter;
        }

        props[identifireType] = {
          $id: "16",
          type: "string",
          title: t(identifireType),
        };
        props["image"] = {
          $id: "17",
          type: "string",
          imageId: imageId,
        };

        const newSchema = { ...schema, properties: props, required };
        setSchema(newSchema);
      }
      if (
        formData?.digital_twin &&
        (meterClass === "MAD:00:0D" || meterClass == "EFE:00:0D")
      ) {
        props["input_1"] = {
          type: "object",
          properties: {
            meter_type: {
              type: "string",
            },
            unit_of_reading: {
              type: "string",
            },
          },
        };
        props["input_2"] = {
          type: "object",
          properties: {
            meter_type: {
              type: "string",
            },
            unit_of_reading: {
              type: "string",
            },
          },
        };
        props["input_3"] = {
          type: "object",
          properties: {
            meter_type: {
              type: "string",
            },
            unit_of_reading: {
              type: "string",
            },
          },
        };
        const newSchema = { ...schema, properties: props, required };
        setSchema(newSchema);
      }
      if (security) {
        props["security"] = {
          type: "array",
          title: t("security"),
        };
        const newSchema = { ...schema, properties: props, required };
        setSchema(newSchema);
      }
      if (formData?.digital_twin && meterType === "08") {
        props["room"] = {
          type: "string",
          title: t("room"),
        };
        const newSchema = { ...schema, properties: props, required };
        setSchema(newSchema);
      }
      if (digitalTwinName === "Maddalena ArrowWan V2 - Lora") {
        props["lora"] = {
          mapFn: (result) =>
            result?.data?.data?.map((item) => ({
              key: item?.id,
              label: `${item.attributes?.fields?.name} `,
              value: `${item.attributes?.fields?.name}`,
            })),

          url: "altior/device?tag=lorawan_server",
          type: "string",
          title: t("lora"),
          lora: true,
        };
        props["node"] = {
          type: "string",
          title: t("node"),
          readOnly: loraProfiles ? false : true,
        };
        props["app_key"] = {
          type: "string",
          title: t("app_key"),
          readOnly: loraProfiles ? false : true,
        };
        props["app_eui"] = {
          type: "string",
          title: t("app_eui"),
          readOnly: loraProfiles ? false : true,
        };
        props["profile"] = {
          type: "string",
          title: t("profile"),
          oneOf:
            loraProfiles && mappedProfile.length > 0 ? [...mappedProfile] : [],
          readOnly: loraProfiles ? false : true,
        };
        const newSchema = { ...schema, properties: props, required };
        setSchema(newSchema);
      }
    }
  }, [
    formData?.digital_twin,
    identifireType,
    meterType,
    meterClass,
    security,
    digitalTwinName,
    loraProfiles,
    mappedProfile,
  ]);

  useEffect(() => {
    if (formData.meter_type !== t("distributor")) {
      delete formData.height;
      delete formData.weight;
      delete formData.depth;
      delete formData.fc;
      delete formData.c;
      delete formData.k;
      delete formData.kq;
      delete formData.kc;
    }
  }, [formData.meter_type]);

  useEffect(() => {
    if (identifireType) {
      const order = { ...uiSchema };
      order["ui:order"] = [
        "digital_twin",
        identifireType,

        "serial",

        "meter_type",
        "key",
        "pod",
        "room",
        "address",
        "dataConfigurazione",
        "time_zone",
        "coordinates",

        "tags",
        "diameter",
        "reverse",

        "note",
        "image",
        "input_1",
        "input_2",
        "input_3",
        "security",
        "lora",
        "profile",
        "node",
        "app_key",
        "app_eui",
      ];
      const newUiSchema = { ...uiSchema, ...order };

      setUiSchema(newUiSchema);
    }
  }, [identifireType]);
  useEffect(() => {}, [schema]);

  useEffect(() => {
    if (createSuccess) {
      dispatch(unSetDigitalTwin());
    }
  }, [createSuccess]);

  useEffect(() => {
    if (error && openErr) {
      setOpen(true);
    }
  }, [error]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setOpenErr(false);
  };
  useEffect(() => {}, [formData]);
  /*   useEffect(() =>{
    if(formData.meter_type === "RIPARTITORE"){

    }
  },[]) */

  /*   const widgets = {
    point: Point,
    search: SearcheableSelect,
    serverSearch: SearchOnServer,
  }; */
  const fields = {
    point: Point,
    search: SearcheableSelect,
    dtwin: DtwinCustomField,
    serverSearch: SearchOnServer,
    image: DigitalTwinImage,
    heatmeter: HeatMeterForm,
    ImpulsiveInput: ImpulsiveInput,
    security: SecurityWidget,
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography
        sx={{ mb: 4, color: theme.palette.primary.main }}
        variant="h4"
      >
        Nuovo Meter
      </Typography>
      <Form
        id="pisya"
        ref={fromRef}
        schema={schema}
        fields={fields}
        formData={formData}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        onSubmit={(values) => onSubmitClick(values.formData)}
        validator={validator}
        uiSchema={uiSchema}
        /* widgets={widgets} */
        showErrorList={false}
        customValidate={customValidate}
        noHtml5Validate

        /*  templates={{ ErrorListTemplate }} */
      />
      {formData.meter_type === t("distributor") && (
        <HeatMeterForm
          setFormData={setFormData}
          formData={formData}
          heatValidation={heatValidation}
          setHeatValidation={setHeatValidation}
        />
      )}

      {/* <Button
        variant="contained"
        sx={{ float: "right", mt: 2 }}
        onClick={checkValidation}
      >
        {t("create")}
      </Button> */}
    </Box>
  );
};

export default CreateMeterFromBlock;
