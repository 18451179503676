import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Box } from "@mui/material";

import { store } from "../../../redux/store";
import { useSearchParams } from "react-router-dom";
import ModalDialog from "../../meters/ModalDialog";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/slices/iwdWebSocketSlice";
import { loadingOn, loadingOff } from "../../../redux/slices/loadingSlice";
/* const url = "http://192.168.1.94:4006/api/v1/altior/device/export/download?"; */

const ExportDexButton = ({ styleProp, dex }) => {
  const url = `${window.BASE_URL}/api/v1/dex/execute/export/download?`;
  const bearer = localStorage.getItem("user_token");
  const [refresh, setReresh] = useState(false);
  const [readyToConnect, setReadyToConnect] = useState(false);
  const options = ["Export csv", "Export XLSX"];
  const loading = useSelector((state) => state?.loadingSlice?.loading);
  const [downloadType, setType] = useState(null);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [uuid, setUUID] = useState(uuidv4());
  const [startDownload, setStartDownload] = useState(false);
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  useEffect(() => {}, [url]);
  const [
    startExportDevice,
    {
      isLoading: isDeviceExportLoading,
      isSuccess: isDeviceExportSuccess,
      isError: isDeviceExportError,
      data: resultStartExport,
      isFetching,
      requestId,
    },
  ] = store.useExportDexMutation();
  const [
    downloadDevice,
    {
      isSuccess: isDeviceDownloadSuccess,
      isError: isDeviceDownloadError,
      data: resultStartDownload,
      reset: resetStartDownload,
    },
  ] = store.useDownloadMutation();
  const {
    isWSConnected,
    subscribedChannels,
    data,
    error,
    channels,
    cashedData,
  } = useSelector((state) => state.iwdWebSocketSlice);

  useEffect(() => {
    dispatch(loadingOff());
  }, [isDeviceDownloadSuccess, isDeviceDownloadError]);

  useEffect(() => {
    if (isWSConnected && !channels["export_status"] && readyToConnect) {
      dispatch(
        actions.joinChannel({
          name: "export_status",
          params: { uuid: uuid },
        })
      );
    }
  }, [isWSConnected, readyToConnect]);

  useEffect(() => {
    if (
      data &&
      isWSConnected &&
      ((data?.data_update?.[1]?.uuid == uuid &&
        data?.data_update?.[1]?.status == "ready") ||
        (cashedData?.[1]?.uuid == uuid && cashedData?.[1]?.status == "ready"))
    ) {
      const copySearchParams = new URLSearchParams(searchParams);
      copySearchParams.append("download_type", downloadType);
      copySearchParams.append("uuid", uuid);
      copySearchParams.append("token", bearer);
      window.open(`${url}${copySearchParams.toString()}`, "_blank");

      dispatch(loadingOff());
      setReresh(true);
    }
  }, [data, data?.data_update, cashedData]);

  useEffect(() => {
    if (
      channels["export_status"] &&
      (!subscribedChannels["export_status"] ||
        !subscribedChannels["export_status"]["data_update"]) &&
      readyToConnect
    ) {
      dispatch(
        actions.subscribeChannel({
          channelName: "export_status",
          eventName: "data_update",
        })
      );

      if (startDownload) {
        const copySearchParams = new URLSearchParams(searchParams);

        copySearchParams.append("download_type", downloadType);
        copySearchParams.append("uuid", uuid);
        const attributes = {
          ...dex,
          download_type: downloadType,
          uuid: uuid,
          page: 1,
          per_page: 1,
        };
        startExportDevice(attributes);
        setStartDownload(false);
      }
    }
  }, [channels]);

  useEffect(() => {
    if (!isFetching && !isDeviceExportLoading) {
      switch (resultStartExport) {
        case "already_created":
          const copySearchParams = new URLSearchParams(searchParams);
          copySearchParams.append("download_type", downloadType);
          copySearchParams.append("uuid", uuid);
          copySearchParams.append("token", bearer);
          window.open(`${url}${copySearchParams.toString()}`, "_blank");

          dispatch(loadingOff());
          setReresh(true);
          break;
        case "creating":
          dispatch(loadingOn());
          break;
        default:
          break;
      }
    }
    resetStartDownload();
  }, [
    isDeviceExportSuccess,
    isDeviceExportLoading,
    requestId,
    resultStartExport,
    isFetching,
  ]);
  useEffect(() => {
    if (refresh) {
      dispatch(actions.cleanData("data_update"));
      setReresh(false);
    }
  }, [refresh]);
  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
    dispatch(loadingOn());
    setReadyToConnect(true);
    switch (selectedIndex) {
      case 0:
        if (!isWSConnected) {
          dispatch(actions.connect());
          setType("csv");
          setStartDownload(true);
        } else {
          const copySearchParams = new URLSearchParams(searchParams);
          setType("csv");
          copySearchParams.append("download_type", "csv");
          copySearchParams.append("uuid", uuid);
          resetStartDownload();
          const attributes = {
            ...dex,
            download_type: "csv",
            uuid: uuid,
            page: 1,
            per_page: 1,
          };
          startExportDevice(attributes);
        }

        break;
      case 1:
        if (!isWSConnected) {
          dispatch(actions.connect());
          setType("excel");
          setStartDownload(true);
        } else {
          setType("excel");
          const copySearchParams = new URLSearchParams(searchParams);
          copySearchParams.append("download_type", "excel");
          copySearchParams.append("uuid", uuid);
          resetStartDownload();
          const attributes = {
            ...dex,
            download_type: "excel",
            uuid: uuid,
            page: 1,
            per_page: 1,
          };
          startExportDevice(attributes);
        }

        break;
      default:
        alert("Nothing to do");
        break;
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    return () => {
      if (channels["export_status"]) {
        dispatch(actions.cleanData("data_update"));

        dispatch(actions.leaveChannel("export_status"));
      }
    };
  }, [channels]);

  return (
    <>
      {bearer && (
        <>
          <ModalDialog open={loading}>
            <Box>Data is creating</Box>
          </ModalDialog>
          <Box sx={styleProp}>
            <ButtonGroup
              disabled={!dex.logic}
              variant="contained"
              ref={anchorRef}
              aria-label="split button"
            >
              <Button onClick={handleClick}>{options[selectedIndex]}</Button>
              <Button
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {options?.map((option, index) => (
                          <MenuItem
                            key={option}
                            selected={index === selectedIndex}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </>
      )}
    </>
  );
};

export default ExportDexButton;
