import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { store } from "../../redux/store";
import { useParams } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import CustomSnackbar from "../../utils/Snackbars";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import {
  Tabs,
  Tab,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import theme from "../../theme";
const VisibilityHandler = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [getUsers] = store.useLazyGetUsersQuery();
  const [getRoles] = store.useLazyGetAllrolesQuery();
  const [adminVisibility, setAdminVisibility] = useState(
    id ? props?.schema?.properties?.comdominium_admin?.default : false
  );
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const [addNewUser, setAddNewUser] = useState(false);
  const userList = useSelector((state) => state?.userSlice?.users);
  const roles = useSelector((state) => state?.aegisSlice?.roles);
  const mappedUserList = userList?.map((item) => ({
    userName: `${item?.attributes?.first_name} ${item?.attributes?.last_name}`,
    userId: item?.id,
  }));
  const [addNewGroup, setAddNewGroup] = useState(false);
  const [userParams, setUserParams] = useState(
    props?.schema?.properties?.users?.default ?? {}
  );
  const [groupParams, setGroupParams] = useState(
    props?.schema?.properties?.groups?.default ?? {}
  );
  const [userKey, setUserKey] = useState("");
  const [userValue, setUserValue] = useState("");
  const [groupKey, setGroupKey] = useState("");
  const [groupValue, setGroupValue] = useState("");
  const adminVisHandler = (e) => {
    setAdminVisibility(e.target.value);
  };
  const mappedGroups = roles?.map((item) => ({
    groupName: item,
    groupId: item,
  }));
  const userValuesHandler = (e, name) => {
    setUserParams((prev) => ({
      ...prev,
      [name]: e?.target?.value,
    }));
  };
  useEffect(() => {
    if (props?.schema?.properties?.groups?.default) {
      setGroupParams({ ...props?.schema?.properties?.groups?.default });
    }
  }, []);
  useEffect(() => {
    props.onChange({
      users: { ...userParams },
      groups: props?.formData?.groups ?? {},
      comdominium_admin: props?.formData?.comdominium_admin ?? false,
    });
  }, [userParams]);
  useEffect(() => {
    props.onChange({
      users: props?.formData?.users ?? {},
      groups: { ...groupParams },
      comdominium_admin: props?.formData?.comdominium_admin ?? false,
    });
  }, [groupParams]);
  useEffect(() => {
    props.onChange({
      comdominium_admin: adminVisibility,
      users: props?.formData?.users ?? {},
      groups: props?.formData?.groups ?? {},
    });
  }, [adminVisibility]);
  useEffect(() => {
    if (!userList) {
      getUsers();
    }
  }, [userList]);
  useEffect(() => {
    if (!roles) {
      getRoles();
    }
  }, []);
  useEffect(() => {}, [props]);
  const userKeyHandler = (e) => {
    setUserKey(e.target.value);
  };
  const userValueHandler = (e) => {
    setUserValue(e.target.value);
  };
  const groupKeyHandler = (e) => {
    setGroupKey(e.target.value);
  };
  const groupValueHandler = (e) => {
    setGroupValue(e.target.value);
  };
  const onUserSave = () => {
    const newUser = {
      [userKey]: userValue,
    };
    setUserParams((prev) => ({
      ...prev,
      ...newUser,
    }));
    setUserKey("");
    setUserValue("");
    setAddNewUser(false);
  };
  const onGroupSave = () => {
    const newGroup = {
      [groupKey]: groupValue,
    };
    setGroupParams((prev) => ({
      ...prev,
      ...newGroup,
    }));
    setGroupKey("");
    setGroupValue("");
    setAddNewGroup(false);
  };
  const onDeleteuserClick = (key) => {
    const updatedParams = { ...userParams };
    delete updatedParams[key];
    setUserParams(updatedParams);
  };
  const users = Object?.entries(userParams);
  useEffect(() => {}, [props]);
  const mappedUsers = users?.map(([key, value]) => {
    return (
      <>
        <Box display={"flex"} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            sx={{ mr: 1 }}
            label={t("user")}
            disabled
            value={
              mappedUserList?.filter((user) => user?.userId == key)?.[0]
                ?.userName
            }
          />

          <FormControl fullWidth sx={{ ml: 4 }}>
            <InputLabel id="demo-simple-select-label">{t("value")}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              label={t("value")}
              onChange={(e) => userValuesHandler(e, key)}
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </FormControl>

          <Tooltip title={"delete"}>
            <IconButton onClick={() => onDeleteuserClick(key)}>
              <DeleteIcon sx={{ fill: theme.palette.error.main }} />
            </IconButton>
          </Tooltip>
        </Box>
      </>
    );
  });

  const notifies = Object?.entries(groupParams);
  const groupValuesHandler = (e, name) => {
    setGroupParams((prev) => ({
      ...prev,
      [name]: e?.target?.value,
    }));
  };
  const onDeleteGroupClick = (key) => {
    const updatedParams = { ...groupParams };
    delete updatedParams[key];
    setGroupParams(updatedParams);
  };
  const mappedNotifies = notifies?.map(([key, value]) => (
    <>
      <Box display={"flex"} sx={{ mt: 2 }}>
        <TextField
          fullWidth
          label={t("group")}
          InputLabelProps={{ shrink: true }}
          sx={{ mr: 1 }}
          disabled
          value={
            mappedGroups?.filter((group) => group?.groupId == key)?.[0]
              ?.groupName
          }
        />
        <FormControl fullWidth sx={{ ml: 4 }}>
          <InputLabel id="demo-simple-select-label">{t("value")}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            label={t("value")}
            onChange={(e) => groupValuesHandler(e, key)}
          >
            <MenuItem value={true}>True</MenuItem>
            <MenuItem value={false}>False</MenuItem>
          </Select>
        </FormControl>

        <Tooltip title={"delete"}>
          <IconButton onClick={() => onDeleteGroupClick(key)}>
            <DeleteIcon sx={{ fill: theme.palette.error.main }} />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  ));

  useEffect(() => {}, [props]);
  useEffect(() => {}, [mappedNotifies]);

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{t(props.name)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Grid container>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Condominium admin</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      display={"flex"}
                      width={appBarIsOpen ? 1460 : 1700}
                      sx={{ mb: 3 }}
                    >
                      <TextField
                        value={"Condominium admin"}
                        fullWidth
                        disabled
                        sx={{ mr: 4.5 }}
                      />
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {t("value")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={adminVisibility}
                          label={t("value")}
                          onChange={adminVisHandler}
                        >
                          <MenuItem value={true}>True</MenuItem>
                          <MenuItem value={false}>False</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} sx={{ mt: 5 }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      {props.name === "visibility"
                        ? t("user_visibility")
                        : t("user_notify")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {mappedUsers}
                    {addNewUser && (
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={6}>
                          <FormControl
                            sx={{
                              mr: 3,
                              width: appBarIsOpen ? "34vw" : "40vw",
                            }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              {t("user")}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={userKey}
                              label={t("user")}
                              onChange={(e) => userKeyHandler(e)}
                            >
                              {mappedUserList?.map((item) => (
                                <MenuItem value={item.userId}>
                                  {item.userName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              {t("value")}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={userValue}
                              label="Value"
                              onChange={(e) => userValueHandler(e)}
                            >
                              <MenuItem value={true}>True</MenuItem>
                              <MenuItem value={false}>False</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                          <Box
                            display={"flex"}
                            width={"100%"}
                            justifyContent={"flex-end"}
                            sx={{ mt: 1 }}
                          >
                            <Tooltip title={"save"}>
                              <IconButton
                                onClick={onUserSave}
                                disabled={!userKey}
                              >
                                <DoneIcon
                                  sx={{
                                    fill:
                                      userValue && theme.palette.primary.main,
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={"cancell"}>
                              <IconButton onClick={() => setAddNewUser(false)}>
                                <CloseIcon
                                  sx={{
                                    fill: theme.palette.primary.main,
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Grid>
                      </Grid>
                    )}

                    {!addNewUser && (
                      <Tooltip title={t("add_user")}>
                        <IconButton
                          onClick={() => setAddNewUser(true)}
                          sx={{ float: mappedUsers.length > 0 && "right" }}
                        >
                          <AddIcon sx={{ fill: theme.palette.primary.main }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} sx={{ mt: 5 }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      {props?.name == "visibility"
                        ? t("group_visibility")
                        : t("group_notify")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {mappedNotifies}
                    {addNewGroup && (
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={6}>
                          <FormControl
                            sx={{
                              mr: 3,
                              width: appBarIsOpen ? "34vw" : "40vw",
                            }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              {t("group")}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={groupKey}
                              label={t("value")}
                              onChange={(e) => groupKeyHandler(e)}
                            >
                              {mappedGroups?.map((item) => (
                                <MenuItem value={item.groupId}>
                                  {item.groupName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Value
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={groupValue}
                              label="Value"
                              onChange={(e) => groupValueHandler(e)}
                            >
                              <MenuItem value={true}>True</MenuItem>
                              <MenuItem value={false}>False</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                          <Box
                            display={"flex"}
                            width={"100%"}
                            justifyContent={"flex-end"}
                            sx={{ mt: 1 }}
                          >
                            <Tooltip title={"save"}>
                              <IconButton
                                onClick={onGroupSave}
                                disabled={!groupKey || groupValue === ""}
                              >
                                <DoneIcon
                                  sx={{
                                    fill:
                                      userValue &&
                                      userKey &&
                                      theme.palette.primary.main,
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={"save"}>
                              <IconButton onClick={() => setAddNewGroup(false)}>
                                <CloseIcon
                                  sx={{
                                    fill: theme.palette.primary.main,
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Grid>
                      </Grid>
                    )}
                    {!addNewGroup && (
                      <Tooltip title={t("add_group")}>
                        <IconButton
                          onClick={() => setAddNewGroup(true)}
                          sx={{ float: mappedNotifies.length > 0 && "right" }}
                        >
                          <AddIcon sx={{ fill: theme.palette.primary.main }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default VisibilityHandler;
