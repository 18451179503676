import React, { useEffect, useState } from "react";
import {
  Query,
  Builder,
  BasicConfig,
  Utils as QbUtils,
} from "react-awesome-query-builder";

// For MUI 4/5 widgets only:
// import MaterialConfig from "react-awesome-query-builder/lib/config/material";
import MuiConfig from "react-awesome-query-builder/lib/config/mui";
import "react-awesome-query-builder/lib/css/styles.css";
import "react-awesome-query-builder/lib/css/compact_styles.css"; //optional, for more compact styles
import Box from "@mui/material/Box";
import { setDexTrashid } from "../redux/slices/dexDataSourceSlice";
import { Button, TextField, Typography } from "@mui/material";
import { store } from "../redux/store";
import useFetch from "use-http";
import DexTable from "../components/dex/DexTable";
import Loader from "../components/Loader";
import DeleteConfirmModal from "../utils/DeleteConfirmModal";
import { useNavigate } from "react-router-dom";
import BasicSelect from "../components/dex/DexDataSource";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { useExecuteSavedDexMutation } from "../redux/slices/dexApi";
import { useSelector, useDispatch } from "react-redux";
import { setDexAttributes } from "../redux/slices/dexDataSourceSlice";
import { useParams } from "react-router-dom";
import theme from "../theme";
import { setDataSource } from "../redux/slices/dexDataSourceSlice";
import { setMessage } from "../redux/slices/snackbarSlice";
import { useTranslation } from "react-i18next";
import { queryOff } from "../redux/slices/dexDataSourceSlice";
import { useCreateDexMutation } from "../redux/slices/dexApi";
import { TextareaAutosize } from "@mui/base";
import { setBreadCrumbs } from "../redux/slices/breadCrumbMeterSlice";
import { useUpdateDexMutation } from "../redux/slices/dexApi";
import { usePostDexMutation } from "../redux/slices/dexApi";
import ExportDexButton from "../components/form/formComponents/ExportDexButton";
import ModalDialog from "../components/meters/ModalDialog";
import { useLazyGetDexShowQuery } from "../redux/slices/dexApi";
import { setMessages } from "../redux/slices/messageSlice";

const InitialConfig = MuiConfig;

const operators = {
  ...InitialConfig.operators,

  sum: {
    label: "Sum",
    reversedOp: null,
    labelForFormat: "",
    cardinality: 0,
    formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
      `${field} ${opDef.labelForFormat} ${value}`,
    mongoFormatOp: (field, op, value) => ({ [field]: { $eq: value } }),
    sqlFormatOp: (field, op, value) => `SUM(${field})`,
    jsonLogic: (field, op, value, opDef, operatorOptions, fieldDef) => {
      let key = `${op}`;
      var result = {};
      result[key] = [{ var: field.var }];
      return result;
    },
  },

  count_distinct: {
    label: "Count Distinct",
    reversedOp: null,
    labelForFormat: "",
    cardinality: 0,
    formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
      `${field} ${opDef.labelForFormat} ${value}`,
    mongoFormatOp: (field, op, value) => ({ [field]: { $eq: value } }),
    sqlFormatOp: (field, op, value) => `count(distinct(${field}))`,
    jsonLogic: (field, op, value, opDef, operatorOptions, fieldDef) => {
      let key = `${op}`;
      var result = {};
      result[key] = [{ var: field.var }];
      return result;
    },
  },
  count: {
    label: "Count",
    reversedOp: null,
    labelForFormat: "",
    cardinality: 0,
    formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
      `${field} ${opDef.labelForFormat} ${value}`,
    mongoFormatOp: (field, op, value) => ({ [field]: { $eq: value } }),
    sqlFormatOp: (field, op, value) => `count(${field})`,
    jsonLogic: (field, op, value, opDef, operatorOptions, fieldDef) => {
      let key = `${op}`;
      var result = {};
      result[key] = [{ var: field.var }];
      return result;
    },
  },
  average: {
    label: "Average",
    reversedOp: null,
    labelForFormat: "",
    cardinality: 0,
    formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
      `${field} ${opDef.labelForFormat} ${value}`,
    mongoFormatOp: (field, op, value) => ({ [field]: { $eq: value } }),
    sqlFormatOp: (field, op, value) => `average(${field})`,
    jsonLogic: (field, op, value, opDef, operatorOptions, fieldDef) => {
      let key = `${op}`;
      var result = {};
      result["AVG"] = [{ var: field.var }];
      return result;
    },
  },
  like: {
    label: "LIKE",
    reversedOp: null,
    labelForFormat: "LIKE*",
    cardinality: 1,

    jsonLogic: (field, op, value, opDef, operatorOptions, fieldDef) => {
      let key = `${op}`;
      var result = {};
      result[key] = [{ var: field.var }, value];
      return result;
    },
  },
};

const viewFields = {
  device_id: {
    label: "DeviceId",
    type: "number",
    fieldSettings: {
      min: 0,
    },
    valueSources: ["value"],
    operators: ["not_equal", "equal"],
  },
  parent_device_id: {
    label: "Parent Device Id",
    type: "number",
    fieldSettings: {
      min: 0,
    },
    valueSources: ["value"],
    operators: ["not_equal", "equal"],
  },
  in_error: {
    label: "In Error",
    type: "boolean",

    valueSources: ["value"],
  },
  inserted_at: {
    label: "Date system entry",
    type: "datetime",

    valueSources: ["value"],
  },
  message_date: {
    label: "Reading Date",
    type: "datetime",
    valueSources: ["value"],
  },
  unit: {
    label: "Unit",
    type: "string",
    valueSources: ["value"],
  },

  volume: {
    label: "Volume",
    type: "number",
    valueSources: ["value"],
    operators: [
      "sum",
      "count",
      "average",
      "not_equal",
      "equal",
      "less",
      "less_or_equal",
      "greater",
      "greater_or_equal",
    ],
  },
};

const config = {
  ...InitialConfig,
  operators: operators,

  showNot: false,
  fields: {},
};

const JsonLogicQueryBuilder = ({ jsonLogic, renderBuilder }) => {
  const prefillTree = QbUtils.loadFromJsonLogic(jsonLogic, config);

  return (
    <Query
      {...config}
      value={prefillTree}
      onChange={(immutableTree, config) => {}}
      renderBuilder={renderBuilder}
    />
  );
};

const testTree = {
  id: QbUtils.uuid(),
  type: "group",
  children1: [
    {
      type: "rule",
      id: "9b88b8a8-cdef-4012-b456-718fdd5a49ee",
      properties: {
        field: "device_id",
        operator: "equal",
        value: [555],
        valueSrc: ["value"],
        valueType: ["number"],
      },
    },
    {
      type: "rule",
      id: "8b898aa9-89ab-4cde-b012-318fdd5a5e6a",
      properties: {
        field: "in_error",
        operator: "equal",
        value: [true],
        valueSrc: ["value"],
        valueType: ["boolean"],
      },
    },
    {
      type: "group",
      id: "9b888b98-4567-489a-bcde-f18fdd5a7282",
      properties: {
        conjunction: "AND",
        not: false,
      },
      children1: [
        {
          type: "rule",
          id: "bb8b99b9-0123-4456-b89a-b18fdd5a7282",
          properties: {
            field: "battery_level",
            operator: "equal",
            value: [100],
            valueSrc: ["value"],
            valueType: ["number"],
          },
        },
      ],
    },
  ],
};
const queryValue = { id: QbUtils.uuid(), type: "group" };
const DexShow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [deleteModal, setDeleteModal] = useState(false);
  const savedValue = useSelector(
    (state) => state.dexDataSourceSlice?.savedValue
  );
  const [deleteDex, { isSuccess: deleteSuccess }] =
    store.useDeleteDexMutation();
  const [state, setState] = useState(null);
  const trashId = useSelector((state) => state?.dexDataSourceSlice?.trashId);
  const [saveDexModal, setModal] = useState(false);
  const [query, setQuery] = useState(null);
  const [updateDex, { isSuccess: updateDexSuccess }] =
    store.useUpdateDexMutation();
  const [firstRender, setFirstRender] = useState(true);

  const [executeSavedDex] = store.useExecuteSavedDexMutation();
  const [page, setPage] = useState(1);

  const [perPage] = useState(5);

  const [name, setName] = useState();
  const [description, setDescription] = useState("");
  const [postDex] = store.usePostDexMutation();
  const [getDexShow] = store.useLazyGetDexShowQuery();
  const [jsonTree, setJsonTree] = useState(null);
  const dexShow = useSelector((state) => state?.dexDataSourceSlice?.dexShow);
  const dexAttributes = useSelector(
    (state) => state?.dexDataSourceSlice?.dexAttributes
  );
  const precompiledQuery = useSelector(
    (state) => state.dexDataSourceSlice.precompiledQuery
  );
  const dataSources = useSelector(
    (state) => state.dexDataSourceSlice.dataSources
  );

  const postOrUpdate = useSelector(
    (state) => state?.dexDataSourceSlice?.postOrUpdate
  );
  const dataSource = useSelector(
    (state) => state.dexDataSourceSlice.dataSource
  );
  const nameHandle = (e) => {
    setName(e.target.value);
  };
  const descHandle = (e) => {
    setDescription(e.target.value);
  };
  const { t } = useTranslation();
  const { post, loading, error, del, patch } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}`,
    (globalOptions) => {
      globalOptions.cachePolicy = "no-cache";
      return globalOptions;
    }
  );
  const openModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };

  const [ready, setReady] = useState(false);
  useEffect(() => {}, [dexShow]);
  useEffect(() => {
    if (dexShow) {
      dispatch(
        setBreadCrumbs({
          id: dexShow?.name,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [dexShow]);
  useEffect(() => {
    if (updateDexSuccess) {
      closeModal();
      dispatch(setMessage(t("update_success")));
      getDexShow(id);
    }
  }, [updateDexSuccess]);

  useEffect(() => {
    if (dexShow) {
      const dexValue = dataSources?.filter(
        (item) => item?.name === dexShow?.table_name
      );
      dispatch(setDataSource(dexValue?.[0]));
    }
  }, [dexShow]);

  useEffect(() => {
    if (dexShow?.logic && firstRender && dataSource?.fields) {
      const newConfig = { ...config, fields: dataSource?.fields };
      const immutableTree = QbUtils.loadTree(dexShow?.tree, newConfig);

      setState({
        tree: immutableTree,
        config: newConfig,
      });
      setFirstRender(false);
    }
  }, [dexShow, dataSource]);
  const testFn = () => {
    const newConfig = { ...config, fields: dataSource?.fields };
    const immutableTree = QbUtils.loadTree(
      JSON.parse(localStorage.getItem("treevalue")),
      newConfig
    );
    const newState = {
      tree: immutableTree,
      config: newConfig,
    };
    setState(newState);
  };

  useEffect(() => {
    if (state) {
      setReady(true);
    }
  }, [state]);
  useEffect(() => {
    if (ready) {
    }
  }, [ready]);
  useEffect(() => {
    if (state?.tree) {
    }
  }, [state]);
  useEffect(() => {}, [state]);
  const executeDex = async () => {
    setPage(1);
    const attributes = {
      logic: QbUtils.jsonLogicFormat(state.tree, config).logic,
      data_source: dataSource.name,
      page: 1,
      per_page: perPage,
    };

    postDex(attributes);
  };
  useEffect(() => {
    if (!firstRender) {
      const attributes = {
        logic: QbUtils.jsonLogicFormat(state.tree, config).logic,
        data_source: dataSource.name,
        page: page,
        per_page: perPage,
      };
      postDex(attributes);
    }
  }, [page]);
  useEffect(() => {
    if (dexShow) {
      setName(dexShow?.name);
      setDescription(dexShow.description);
    }
  }, [dexShow]);

  useEffect(() => {
    if (id) {
      getDexShow(id);
    }
  }, [id]);

  useEffect(() => {}, [state]);

  const onChange = (immutableTree, config) => {
    dispatch(queryOff());
    setState({ tree: immutableTree, config: config });
    setJsonTree(QbUtils.getTree(immutableTree));
  };

  const renderBuilder = (props) => {
    if (state) {
      return (
        <div className="query-builder-container" style={{ padding: "10px" }}>
          <div className="query-builder qb-lite">
            <Builder {...props} />
          </div>
        </div>
      );
    }
  };

  const onSaveClick = () => {
    const attributes = {
      logic:
        QbUtils.jsonLogicFormat(state.tree, config).logic ?? dexShow?.logic,
      tree: QbUtils.getTree(state.tree),
      data_source: dataSource?.name ?? dexShow?.table_name,
      page: page,
      per_page: perPage,
      name: name ?? dexShow?.name,
      description: description ?? dexShow?.description,
    };
    updateDex({ id: id, attributes: attributes });
  };

  useEffect(() => {
    if (trashId) {
      deleteDex(trashId);
      dispatch(setDexTrashid(null));
    }
  }, [trashId]);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      navigate(`/dex`);
    }
  }, [deleteSuccess]);

  return (
    <>
      <DeleteConfirmModal
        trashModal={deleteModal}
        setTrashModal={setDeleteModal}
        text={t("dex_delete_text")}
        title={t("dex_delete_title")}
        deleteFunc={() => setDexTrashid(id)}
      />
      {ready && state.tree && (
        <Box>
          <Typography
            sx={{ color: theme?.palette?.primary?.main }}
            variant="h4"
          >{`Dex: ${dexShow?.name}`}</Typography>
          <Typography
            sx={{ color: theme?.palette?.primary?.main, mt: 2 }}
            variant="h5"
          >{`Description: ${dexShow?.description}`}</Typography>

          <ModalDialog
            open={saveDexModal}
            title={t("save_dex")}
            close={closeModal}
            cancell={false}
          >
            <Box sx={{ width: 500, mt: 2 }}>
              <TextField
                onChange={(e) => nameHandle(e)}
                value={name}
                label={t("name")}
                fullWidth
              />
              <TextField
                sx={{ mt: 2 }}
                rows={5}
                value={description}
                multiline
                onChange={(e) => descHandle(e)}
                label={t("description")}
                fullWidth
              />
              <Box
                display={"flex"}
                width={"100%"}
                justifyContent={"space-between"}
                sx={{ mt: 2 }}
              >
                <Button onClick={closeModal} variant="contained">
                  {t("close")}
                </Button>
                <Button onClick={onSaveClick} variant="contained">
                  {t("save")}
                </Button>
              </Box>
            </Box>
          </ModalDialog>
          <h3 style={{ marginBottom: 20 }}> Dex - Data explorer</h3>
          {loading ? <Loader /> : <></>}
          <Box>
            <BasicSelect showMode={true} />
          </Box>
          <Box>
            <Query
              {...state.config}
              value={state.tree}
              onChange={onChange}
              renderBuilder={renderBuilder}
            />
            {/* <JsonLogicQueryBuilder
              jsonLogic={dexShow?.logic}
              renderBuilder={renderBuilder}
            /> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 4,
            }}
          >
            <Button onClick={executeDex} endIcon={<SettingsSuggestIcon />}>
              {t("execute")}
            </Button>
            <Box display="flex">
              <ExportDexButton
                dex={{
                  logic:
                    QbUtils.jsonLogicFormat(state.tree, config).logic ??
                    dexShow?.logic,

                  data_source: dataSource?.name ?? dexShow?.table_name,
                }}
              />
              <Button
                variant="contained"
                sx={{ mr: 1, ml: 1 }}
                onClick={openModal}
              >
                {t("update_dex")}
              </Button>
              <Button
                onClick={() => setDeleteModal(true)}
                sx={{
                  background: theme.palette.error.main,
                  "&:hover": {
                    backgroundColor: theme.palette.error.main,
                  },
                }}
              >
                {t("delete_dex")}
              </Button>
            </Box>
          </Box>
          <DexTable page={page} setPage={setPage} perPage={perPage} />
        </Box>
      )}
    </>
  );
};

export default DexShow;
