import { store } from "../../redux/store";
import { useState, useEffect, act } from "react";
import { Grid } from "@mui/material";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import theme from "../../theme";

import { setMessage } from "../../redux/slices/snackbarSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import ModalDialog from "../meters/ModalDialog";
import { useTranslation } from "react-i18next";
import {
  setFromBlock,
  setFromBlockId,
  setRefreshActions,
} from "../../redux/slices/actionsSlice";
import { setBlockTabName } from "../../redux/slices/condominiumSlice";
import { useParams } from "react-router-dom";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";

const ActionShow = () => {
  const [getCindominiumShow] = store.useLazyCondominiumShowQuery();
  const [approveAction, { isSuccess }] = store.useApproveActionsMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [getAction] = store.useLazyGetActionsShowQuery();
  const [modal, setModal] = useState(false);
  const [approve, setApprove] = useState(null);
  const action = useSelector((state) => state?.actionsSlice?.actionShow);
  const fromBlock = useSelector((state) => state?.actionsSlice?.fromBlock);
  const fromBlockId = useSelector((state) => state?.actionsSlice?.fromBlockId);
  const user =
    action?.attributes?.meta_data?.user ??
    action?.attributes?.meta_data?.current_user;
  const params =
    action?.attributes?.meta_data?.params?.data?.attributes?.fields;

  useEffect(() => {
    if (id) {
      getAction({
        id: id,
      });
    }
  }, [id]);

  useEffect(() => {
    if (action) {
      dispatch(
        setBreadCrumbs({
          id:
            params?.condominium_name ??
            action?.attributes?.meta_data?.condominium_name,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [action]);

  const onApproveClick = () => {
    setModal(true);
    setApprove(true);
  };
  const onDisapproveClick = () => {
    setModal(true);
    setApprove(false);
  };

  const closeModal = () => {
    setModal(false);
    setApprove(null);
  };

  const onSubmitClick = () => {
    const attributes = {
      id: +id,
      approve: approve,
    };
    approveAction(attributes);
  };

  useEffect(() => {
    if (isSuccess) {
      if (approve === true) {
        dispatch(setMessage(t("approve_success")));
      }
      if (approve === false) {
        dispatch(setMessage(t("disaprove_success")));
      }
      closeModal();
      if (fromBlock) {
        setTimeout(() => {
          dispatch(setBlockTabName("block_approve"));
          navigate(-1);
          getCindominiumShow(fromBlockId);
          dispatch(setFromBlock(false));
          dispatch(setFromBlockId(null));
          dispatch(setRefreshActions(true));
        }, [500]);
      } else {
        navigate(-1);
        dispatch(setRefreshActions(true));
      }
    }
  }, [isSuccess]);

  const handleBackClick = () => {
    if (fromBlock) {
      dispatch(setBlockTabName("block_approve"));
      navigate(-1);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <ModalDialog open={modal} cancell={false} close={closeModal} title={" "}>
        <Box>
          <Typography>
            {approve ? t("approve_msg") : t("disapprove_msg")}
          </Typography>
          <Box display={"flex"} justifyContent={"space-between"} sx={{ mt: 2 }}>
            <Button onClick={onSubmitClick} variant="contained">
              {t("yes")}
            </Button>
            <Button onClick={closeModal} variant="contained">
              {t("no")}
            </Button>
          </Box>
        </Box>
      </ModalDialog>
      <Typography
        sx={{ mb: 4, color: theme.palette.primary.main }}
        variant="h4"
      >
        {`${t("action")}`}
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="subtitle2">{t("action_type")}:</Typography>
              <Typography variant="subtitle1">
                {action?.attributes?.action_type
                  ? t(action?.attributes?.action_type)
                  : "--"}
              </Typography>
            </Grid>
            {action?.attributes?.meta_data?.params?.name && (
              <Grid item xs={2}>
                <Typography variant="subtitle2">
                  {t("created_apartment")}:
                </Typography>
                <Typography>{`${t("name")}: ${
                  action?.attributes?.meta_data?.params?.name
                }`}</Typography>
              </Grid>
            )}
            {action?.attributes?.approved_at && (
              <Grid item xs={2}>
                <Typography variant="subtitle2">{t("approved_at")}:</Typography>

                <Typography variant="subtitle1">
                  {t("dt", {
                    val: new Date(action?.attributes?.approved_at),
                  })}
                </Typography>
              </Grid>
            )}
            {action?.attributes?.not_approved_at && (
              <Grid item xs={2}>
                <Typography variant="subtitle2">
                  {t("disapproved_at")}:
                </Typography>

                <Typography variant="subtitle1">
                  {t("dt", {
                    val: new Date(action?.attributes?.not_approved_at),
                  })}
                </Typography>
              </Grid>
            )}
            <Grid item xs={2}>
              <Typography variant="subtitle2">{t("condominium")}:</Typography>
              <Typography variant="subtitle1">
                {params?.condominium_name ??
                  action?.attributes?.meta_data?.condominium_name ??
                  "--"}
              </Typography>
            </Grid>
            {params?.sub_condominium_name && (
              <Grid item xs={2}>
                <Typography variant="subtitle2">
                  {t("subcondominum")}:
                </Typography>
                <Typography variant="subtitle1">
                  {params?.sub_condominium_name ?? "--"}
                </Typography>
              </Grid>
            )}
            {action?.attributes?.meta_data?.params?.subcondominium_name && (
              <Grid item xs={2}>
                <Typography variant="subtitle2">
                  {t("subcondominum")}:
                </Typography>
                <Typography variant="subtitle1">
                  {action?.attributes?.meta_data?.params?.subcondominium_name ??
                    "--"}
                </Typography>
              </Grid>
            )}
            {params?.apartment_name && (
              <Grid item xs={2}>
                <Typography variant="subtitle2">{t("apartament")}:</Typography>
                <Typography variant="subtitle1">
                  {params?.apartment_name ?? "--"}
                </Typography>
              </Grid>
            )}

            <Grid item xs={2}>
              <Typography variant="subtitle2">{t("created_by")}:</Typography>
              <Typography variant="subtitle1">
                {user?.first_name
                  ? `${user?.first_name} ${user?.last_name}`
                  : "--"}
              </Typography>
            </Grid>
            {params?.serial && (
              <Grid item xs={2}>
                <Typography variant="subtitle2">
                  {t("created_device")}:
                </Typography>
                <Typography>{`${t("serial")}: ${params?.serial}`}</Typography>
                <Typography>{`${t("meter_type")}: ${
                  params?.meter_type
                }`}</Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          sx={{ mt: 1 }}
        >
          <Button variant="contained" onClick={handleBackClick}>
            {t("back")}
          </Button>
          {!action?.attributes?.approved_at &&
            !action?.attributes?.not_approved_at && (
              <IwdAuthWrapper section={"approve_btns"}>
                <Box display="flex" justifyContent="flex-end" width="100%">
                  <Button
                    onClick={onDisapproveClick}
                    sx={{ mr: 2 }}
                    variant="contained"
                  >
                    {t("disapprove")}
                  </Button>
                  <Button onClick={onApproveClick} variant="contained">
                    {t("approve")}
                  </Button>
                </Box>
              </IwdAuthWrapper>
            )}
        </Box>
      </Box>
    </>
  );
};
export default ActionShow;
