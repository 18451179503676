import { useEffect, useState } from "react";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
import { store } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { setUpdating, setStatistic } from "../../redux/slices/statisticsSlice";
import IconButton from "@mui/material/IconButton";
import GroupsSelector from "../statistics/GroupsSelector";
import StatisticAutocomolete from "./StatisticAutocomolete";
import WidgetsAutocomplete from "./WidgetsAutocomplete";

import { Box } from "@material-ui/core";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        if (element.name === "description" || element.name == "link") {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const tagsEnum = [
  { label: "network", value: "network" },
  { label: "device", value: "device" },
  { label: "group and district", value: "group_and_district" },
  { label: "condominium", value: "condominium" },
];
const typeEnum = [
  { label: "single value", value: "single_value" },
  { label: "pie chart", value: "pie_chart" },
  { label: "line chart", value: "line_chart" },
];
const CreateWidget = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [createWidget] = store.useCreateWidgetMutation();
  const [createdWidgetId, setCreatedWidgetid] = useState(null);
  const statistics = useSelector((state) => state?.statisticsSlice?.statistics);
  const statisticsEnum = statistics?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));
  const [getStatistics] = store.useLazyGetStatisticsQuery();
  const [formData, setFormData] = useState({});
  useEffect(() => {
    getStatistics();
  }, []);
  useEffect(() => {
    dispatch(setBreadCrumbs("Widgets/create"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  const onSubmitClick = (val) => {
    const attributes = {
      ...val,
      refresh_rate: val.refresh_rate * 1000,
      tags: [val.tags],
    };
    createWidget(attributes)
      .unwrap()
      .then((data) => setCreatedWidgetid(data.data.attributes.id))
      ?.catch((error) => {
        console.error("Error occurred:", error);
      });
  };
  useEffect(() => {
    if (createdWidgetId) {
      navigate(`/widgets/${createdWidgetId}`);
    }
  }, [createdWidgetId]);
  const schema = {
    title: "New statistic",
    type: "object",
    properties: {
      name: {
        id: 0,
        type: "string",
        title: t("name"),
      },

      refresh_rate: {
        id: 2,
        type: "number",
        title: t("refresh_rate"),
      },
      type: {
        id: 3,
        type: "string",
        title: t("type"),
        data: [...typeEnum],
      },
      tags: {
        id: 4,
        type: "string",
        title: t("tags"),
        data: [...tagsEnum],
      },
      statistic_id: {
        id: 5,
        type: "number",
        title: t("statistic"),
        data: [...statisticsEnum],
      },
      groups: {
        id: 6,
        type: "string",
        title: t("groups"),
      },
      link: {
        id: 8,
        type: "string",
        title: t("link"),
      },
      description: {
        id: 1,
        type: "string",
        title: t("description"),
      },
    },
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,

    description: {
      "ui:options": {
        widget: "textarea",
        minRows: 15,
      },
    },
    type: {
      "ui:field": "autocomplete",
    },
    tags: {
      "ui:field": "autocomplete",
    },
    statistic_id: {
      "ui:field": "statistics",
    },
    groups: {
      "ui:field": "groups",
    },
    "ui:submitButtonOptions": {
      submitText: t("create"),
    },
  };
  const fields = {
    autocomplete: WidgetsAutocomplete,
    groups: GroupsSelector,
    statistics: StatisticAutocomolete,
  };
  const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-.\/?%&=]*)?$/;
  const customValidate = (formData, errors) => {
    if (!formData?.name) {
      errors?.name?.addError("is a required property");
    }
    if (!formData?.description) {
      errors?.description?.addError("is a required property");
    }
    if (!formData?.type) {
      errors?.type?.addError("is a required property");
    }
    if (!formData?.refresh_rate) {
      errors?.refresh_rate?.addError("is a required property");
    }
    if (!formData?.statistic_id) {
      errors?.statistic_id?.addError("is a required property");
    }
    if (formData?.groups.length < 1) {
      errors?.groups?.addError("is a required property");
    }
    if (!formData?.tags) {
      errors?.tags?.addError("is a required property");
    }

    return errors;
  };
  return (
    <>
      <Typography
        variant="h4"
        sx={{ color: theme?.palette?.primary?.main, mb: 4 }}
      >
        {t("create_widget")}
      </Typography>
      <Form
        schema={schema}
        fields={fields}
        formData={formData}
        customValidate={customValidate}
        showErrorList={false}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        onSubmit={(values) => {
          onSubmitClick(values.formData);
        }}
        validator={validator}
        noHtml5Validate
        uiSchema={uiSchema}
      />
    </>
  );
};

export default CreateWidget;
