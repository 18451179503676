import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import theme from "../theme";
import {
  opendModal,
  closeModal,
  setConfirmed,
  unsetConfirmed,
} from "../redux/slices/trashSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};
const TrashModal = ({ title, text }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const open = useSelector((state) => state?.trashSlice?.open);
  const handleClose = () => {
    dispatch(closeModal());
  };
  const onConfirmClick = () => {
    dispatch(setConfirmed());
    handleClose();
  };
  return (
    <Box>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            {title}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            align="center"
          >
            {text}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", pt: 2 }}>
            <Button
              sx={{
                background: theme.palette.error.main,
                "&:hover": {
                  background: theme.palette.error.main,
                },
              }}
              onClick={onConfirmClick}
            >
              {t("yes")}
            </Button>
            <Button onClick={handleClose}>{t("no")}</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default TrashModal;
