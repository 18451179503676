import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { store } from "../../redux/store";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { CircularProgress, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import debounce from "lodash.debounce";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
const BalanceBlockSelect = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [getCondominumList] = store.useLazyGetCondominiumListQuery();
  const [getCondominiumShow, { isFetching }] =
    store.useLazyCondominiumShowQuery();
  const condominumList = useSelector(
    (state) => state?.userSlice?.condominumList
  );
  const [associateAdmin, { isSuccess: assocSuccess }] =
    store.useUserCondAssocMutation();
  const [getUserShow, { isSuccess: userSuccess }] =
    store.useLazyGetUserShowQuery();
  const updating = useSelector((state) => state?.widgetSlice?.updating);
  const blocks = useSelector((state) => state?.condominiumSlice?.condominiums);
  const options = blocks?.map((item) => ({
    title: item?.attributes?.fields?.name,
    value: item?.id,
  }));

  const [getCondominiums] = store.useLazyCondominiumIndexQuery();
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const debValue = debounce((e) => {
    console.log("debik");
    setSearchValue(e.target.value);
  }, 500);
  useEffect(() => {
    if (value && searchValue) {
      setSearchValue("");
      getCondominiums({ page: 1, per_page: 10 });
    }
  }, [value]);

  useEffect(() => {
    getCondominiums({ page: 1, per_page: 10 });
  }, []);

  useEffect(() => {
    console.log("condix", options);
  }, [options]);

  useEffect(() => {
    if (searchValue) {
      setLoading(true);
      getCondominiums({ name: searchValue }).then(() => {
        setLoading(false);
      });
    }
  }, [searchValue]);

  useEffect(() => {
    if (value?.value) {
      props.onChange(value?.value);
      getCondominiumShow(value?.value);
    }
    if (value?.value == null) {
      props.onChange(null);
    }
  }, [value]);

  useEffect(() => {
    console.log("searchValue", searchValue);
  }, [searchValue]);
  useEffect(() => {
    console.log("valiuxa", value);
  }, [value]);

  return (
    <>
      <Autocomplete
        value={value}
        id="multiple-limit-tags"
        options={options ?? []}
        getOptionLabel={(option) => option.title}
        onChange={(e, newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => {
          return (
            <>
              <TextField
                onChange={debValue}
                onClick={() => console.log("huivpopeblin")}
                {...params}
                value={value}
                /*     error={props?.rawErrors?.length > 0}
                required={props.required}
                label={props.schema.title} */
                label={t("condominium")}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      {loading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </>
          );
        }}
      />
    </>
  );
};
export default BalanceBlockSelect;
