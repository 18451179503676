import * as React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function IwdLinearProgress({ modal }) {
  const [progress, setProgress] = useState(0);
  const [max, setMax] = useState(null);
  const importInfo = useSelector(
    (state) => state?.meterSlice?.readingImportInfo
  );
  const maxVal = importInfo?.filter((item) => item?.action == "count_messages");
  const progressInfo = importInfo?.filter(
    (item) => item?.action == "imported_messages"
  );
  const endCommunication = importInfo.findIndex((el) => el?.status === "end");
  useEffect(() => {
    if (maxVal.length > 0) {
      setMax(maxVal?.[0]?.count_messages);
    }
  }, [maxVal]);

  useEffect(() => {}, [max]);
  function calculatePercentage(current) {
    if (max === 0) {
      throw new Error("Max value cannot be zero");
    }
    setProgress((current / max) * 100);
  }
  useEffect(() => {
    if (progressInfo.length > 0) {
      calculatePercentage(progressInfo?.[progressInfo?.length - 1]?.count);
    }
    if (endCommunication !== -1) {
      setProgress(100);
    }
    if (!modal) {
      setProgress(0);
    }
  }, [progressInfo, endCommunication, modal]);
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}
