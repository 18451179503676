import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import validator from "@rjsf/validator-ajv8";
import { Form } from "@rjsf/mui";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { setReadingRange } from "../../redux/slices/meterSlice";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { Button } from "@mui/material";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import FormAccordion from "../form/formComponents/Accordion";
import { useLazyGetMeterReadingGraphQuery } from "../../redux/slices/meterReadingGraphApi";
import MeterReadingsGraph from "./MeterReadingsGraph";
import { getDateDifferenceInDays } from "../../utils/api_params";
import { useSearchParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import ImportHistoryButton from "../../pages/ImportHistoryButton";
import {
  setTo,
  setFrom,
  setConsumptionsTo,
} from "../../redux/slices/meterReadingSlice";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import { useSelector, useDispatch } from "react-redux";
import { store } from "../../redux/store";
import { Chip } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import DatePicker from "../form/formComponents/DatePicker";
import MeterDatePicker from "../form/formComponents/MeterDatePicker";
import theme from "../../theme";
import { prepareParams } from "../../utils";
import ExportMessageButton from "../form/formComponents/ExportMessagesButton";
import ColorMeterReadingGraph from "./ColorMeterReadingGraph";

// import { clearData } from "../../redux/slices/meterReadingSlice";

const MeterReadingsTab = ({
  meter,
  apartamentMeterId,
  enteredFromCondominium,
  impulsiveMeterId,
  enteredFromParent,
}) => {
  const dispatch = useDispatch();

  const muiTheme = createTheme({
    props: {
      MuiTextField: {
        variant: "outlined",
      },
    },
  });

  const [formData, setFormData] = useState({});
  const [msgTo, setMsgTo] = useState(null);
  const heatUnit = useSelector((state) => state?.meterSlice?.heatUnit);
  const [initialUnit, setInitialUnit] = useState(null);
  const [unit, setUnit] = useState(null);
  const coldUnit = useSelector((state) => state?.meterSlice?.coldUnit);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dates = {
    from: new Date(),
    to: new Date(),
  };
  const newFrom = new Date(
    Date.UTC(
      dates.from.getFullYear(),
      dates.from.getMonth(),
      dates.from.getDate() - 7
    )
  );
  const newTo = new Date(
    Date.UTC(dates.to.getFullYear(), dates.to.getMonth(), dates.to.getDate())
  );
  const now = new Date();
  /*  now.setDate(now.getDate() + 1); */
  now.setHours(0, 0, 0, 0);
  let newNow = new Date();
  newNow.setDate(newNow.getDate() + 1);
  newNow.setHours(0, 0, 0, 0);
  const fromDate = new Date(now);
  fromDate.setDate(fromDate.getDate() - 7);
  fromDate.setHours(0, 0, 0, 0);
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [refreshParams, setRefreshParams] = useState(false);
  const is1280x720 = useMediaQuery(
    "(max-width: 1280px) and (max-height: 720px)"
  );
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const [to, setTo] = useState(now);
  const [from, setFrom] = useState(fromDate);
  const [firstRender, setFirstRender] = useState(true);
  const [getMeterReadingGraph, { isLoading }, lastPromiseInfo] =
    store.useLazyGetMeterReadingGraphQuery();
  const [getDeviceMessages, { isLoading: isLoadingTable }] =
    store.useLazyGetDeviceMessagesQuery();
  const paramsList = {
    page: page,
    per_page: perPage,
    from: searchParams.get("from"),
    to: searchParams.get("to"),
  };
  const convertUnit = () => {
    if (unit == "m³") {
      setUnit("liters");
    } else {
      setUnit("m³");
    }
  };
  const schema = {
    title: "Filter form",
    type: "object",
    properties: {
      insertion_date: {
        title: t("inserted_at"),
        type: "object",
        properties: {
          from: {
            type: "string",
            default: paramsList.from ?? from.toISOString(),
          },
          to: {
            type: "string",
            default: paramsList.to ?? now.toISOString(),
          },
        },
      },
    },
  };
  const fields = {
    datepicker: MeterDatePicker,
  };
  useEffect(() => {}, [meter]);
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: <SearchIcon />,
    },

    insertion_date: {
      "ui:field": "datepicker",
    },
  };
  useEffect(() => {
    console.log(
      "datesNewFr",
      newFrom.toISOString(),
      "datesNewTo",
      newTo.toISOString()
    );
  }, [newFrom, newTo]);
  const data = useSelector((state) => state.deviceMessageSlice.data);
  const readingGraphData = useSelector((state) => state.meterReadingSlice.data);

  const meta = useSelector((state) => state.deviceMessageSlice.meta);
  useEffect(() => {}, [[data]]);
  const onClearClick = () => {
    setFormData((prev) => ({
      ...prev,
      insertion_date: {
        from: null,
        to: null,
      },
    }));

    const params = prepareParams({});
    if (!enteredFromCondominium && !enteredFromParent) {
      navigate({
        pathname: `/meters/${meter.id}`,
        search: params.toString(),
      });
    }

    /*  setRefreshParams(true); */
  };
  const heatColumns = [
    {
      field: "messageDate",
      headerName: t("readTime"),
      editable: false,

      editable: false,
      flex: 2,
      renderCell: (params) => {
        return <>{t("dt_n", { val: new Date(params.row.messageDate) })}</>;
      },
    },
    {
      field: "rawValue",
      headerName: t("display_value"),

      editable: false,
      flex: 1,
    },
    /*  {
      field: "unit",
      headerName: t("unit"),
      editable: false,

      editable: false,
      flex: 1,
    }, */
    {
      field: "k",
      headerName: t("k"),
      editable: false,

      editable: false,
      flex: 1,
    },

    {
      field: "value",
      headerName: t("value"),
      editable: false,

      editable: false,
      flex: 1,
    },

    {
      field: "alarms",
      headerName: t("alarms"),
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (params) => {
        if (params.row.alarms === undefined) {
          return <>--</>;
        } else {
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {params.row.alarms?.map((item) => (
                <Chip
                  key={item}
                  style={{
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.error.contrastText,
                    margin: "2px",
                  }}
                  label={item}
                />
              ))}
            </Box>
          );
        }
      },
    },
  ];
  const columns = [
    {
      field: "messageDate",
      headerName: t("readTime"),
      editable: false,

      editable: false,
      flex: 2,
      renderCell: (params) => {
        return <>{t("dt_n", { val: new Date(params.row.messageDate) })}</>;
      },
    },

    {
      field: "strValue",
      headerName: t("volume"),
      editable: false,

      editable: false,
      flex: 1,
    },
    {
      field: "unit",
      headerName: t("unit"),
      editable: false,

      editable: false,
      flex: 1,
    },
    {
      field: "alarms",
      headerName: t("alarms"),
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (params) => {
        if (params.row.alarms === undefined) {
          return <>--</>;
        } else {
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {params.row.alarms?.map((item) => (
                <Chip
                  key={item}
                  style={{
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.error.contrastText,
                    margin: "2px",
                  }}
                  label={item}
                />
              ))}
            </Box>
          );
        }
      },
    },
  ];

  const heatMeterColumns = [
    {
      field: "messageDate",
      headerName: t("readTime"),
      editable: false,

      editable: false,
      flex: 2,
      renderCell: (params) => {
        return <>{t("date_val", { val: new Date(params.row.messageDate) })}</>;
      },
    },
    {
      field: "calorie",
      headerName: t("Calorie"),

      editable: false,
      flex: 1,
    },
    {
      field: "frigorie",
      headerName: t("Frigorie"),
      editable: false,

      editable: false,
      flex: 1,
    },
    {
      field: "unit",
      headerName: t("unit"),
      editable: false,

      editable: false,
      flex: 1,
    },
    {
      field: "alarms",
      headerName: t("alarms"),
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (params) => {
        if (params.row.alarms === undefined) {
          return <>--</>;
        } else {
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {params.row.alarms?.map((item) => (
                <Chip
                  key={item}
                  style={{
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.error.contrastText,
                    margin: "2px",
                  }}
                  label={item}
                />
              ))}
            </Box>
          );
        }
      },
    },
  ];
  const valueConverter = (val) => {
    if (initialUnit == "m³" && unit == "m³") {
      return val;
    }
    if (initialUnit == "m³" && unit == "liters") {
      return val * 1000;
    }
    if (initialUnit == "liters" && unit == "liters") {
      return val;
    }
    if (initialUnit == "liters" && unit == "m³") {
      return val / 1000;
    }
    if (!val) {
      return "";
    }
  };
  const readingRows =
    unit &&
    data?.map((item, i) => {
      let id = uuidv4();
      return {
        id: id,
        messageDate: item?.lastReadDate ?? item?.messageDate ?? "--",
        strValue:
          meter?.attributes?.class !== "PulseCounter"
            ? item.value !== null && item?.value !== undefined
              ? valueConverter(item.value)
              : "--"
            : t("number", {
                val: item.value,
              }),
        unit:
          meter?.attributes?.class !== "PulseCounter"
            ? unit == "m³"
              ? "m³"
              : "L"
            : item?.unit,
        alarms: item?.alarms ?? item?.attributes?.values?.values?.value?.alarms,
        dayDiff: getDateDifferenceInDays(item?.messageDate),
      };
    });
  const heatReadingRows = data?.map((item, i) => {
    let id = uuidv4();
    return {
      id: id,
      messageDate: item?.lastReadDate ?? item?.messageDate ?? "--",
      value: item.value !== null && item.value ? item?.value?.toFixed(0) : "--",
      rawValue:
        item?.last_unconverted_reading !== null
          ? item?.last_unconverted_reading?.value?.toFixed(0)
          : "--",
      /*    unit: item?.unit, */
      k:
        item.k !== null
          ? t("number", {
              val: item.k,

              maximumFractionDigits: 3,
            })
          : "--",
      alarms: item?.alarms ?? item?.attributes?.values?.values?.value?.alarms,
      dayDiff: getDateDifferenceInDays(item?.messageDate),
    };
  });
  useEffect(() => {}, [data]);
  const heatMeterRows = data?.map((item, i) => {
    let id = uuidv4();
    return {
      id: id,
      messageDate: item?.attributes?.values?.read_time,
      calorie:
        item?.attributes?.values?.values?.value?.heat_last_reading?.value !==
          null &&
        Object.keys(
          item?.attributes?.values?.values?.value?.heat_last_reading || {}
        ).length !== 0
          ? `${
              heatUnit === "Wh"
                ? `${t("number", {
                    val:
                      item?.attributes?.values?.values?.value?.heat_last_reading
                        ?.value / 1000,
                  })}`
                : `${t("number", {
                    val: item?.attributes?.values?.values?.value
                      ?.heat_last_reading?.value,
                  })}`
            } `
          : "--",
      frigorie:
        item?.attributes?.values?.values?.value?.cold_last_reading?.value !==
          null &&
        Object.keys(
          item?.attributes?.values?.values?.value?.cold_last_reading || {}
        ).length !== 0
          ? `${
              coldUnit === "Wh"
                ? `${t("number", {
                    val:
                      item?.attributes?.values?.values?.value?.cold_last_reading
                        ?.value / 1000,
                  })}`
                : `${t("number", {
                    val: item?.attributes?.values?.values?.value
                      ?.cold_last_reading?.value,
                  })}`
            } `
          : "--",

      unit:
        coldUnit === "Wh"
          ? "kWh"
          : item?.attributes?.values?.values?.value?.cold_last_reading?.unit,
      alarms: item?.alarms ?? item?.attributes?.values?.values?.value?.alarms,
      dayDiff: getDateDifferenceInDays(item?.messageDate),
    };
  });
  useEffect(() => {}, [heatMeterRows]);
  const params = {
    from_date: formData?.insertion_date?.from,
    to_date: msgTo?.toISOString(),
    page: page,
    per_page: perPage,
    message_type: "reading",
    sort: "message_date",
    order: "desc",
  };
  useEffect(() => {}, [from, to]);
  useEffect(() => {
    const messagesToDate = new Date(
      Date.UTC(
        dates.to.getFullYear(),
        dates.to.getMonth(),
        dates.to.getDate() + 1
      )
    );
    const messageDateToParams = new Date(searchParams.get("to"));
    messageDateToParams.setDate(messageDateToParams.getDate() + 1);

    setMsgTo(searchParams.get("to") ? messageDateToParams : messagesToDate);
    if (!readingGraphData) {
      getMeterReadingGraph({
        digitalTwinId: meter.attributes.digital_twin_id,
        deviceId: impulsiveMeterId ?? apartamentMeterId ?? meter.id,
        from: searchParams.get("from") ?? newFrom.toISOString(),
        to: searchParams.get("to") ?? newTo.toISOString(),
      });
    }
    if (!data) {
      getDeviceMessages({
        digitalTwinId: meter.attributes.digital_twin_id,
        deviceId: impulsiveMeterId ?? apartamentMeterId ?? meter.id,
        from: searchParams.get("from") ?? newFrom.toISOString(),
        to: searchParams.get("to")
          ? messageDateToParams.toISOString()
          : messagesToDate.toISOString(),
        page: page,
        perPage: perPage,
      });
    }

    setFirstRender(false);
  }, [apartamentMeterId, meter?.id]);

  useEffect(() => {
    if (apartamentMeterId) {
      const messagesToDate = new Date(
        Date.UTC(
          dates.to.getFullYear(),
          dates.to.getMonth(),
          dates.to.getDate() + 1
        )
      );
      const messageDateToParams = new Date(searchParams.get("to"));
      messageDateToParams.setDate(messageDateToParams.getDate() + 1);
      setMsgTo(searchParams.get("to") ? messageDateToParams : messagesToDate);
      getMeterReadingGraph({
        digitalTwinId: meter.attributes.digital_twin_id,
        deviceId: impulsiveMeterId ?? apartamentMeterId ?? meter.id,
        from: searchParams.get("from") ?? newFrom.toISOString(),
        to: searchParams.get("to") ?? newTo.toISOString(),
      });

      getDeviceMessages({
        digitalTwinId: meter.attributes.digital_twin_id,
        deviceId: impulsiveMeterId ?? apartamentMeterId ?? meter.id,
        from: searchParams.get("from") ?? newFrom.toISOString(),
        to: searchParams.get("to")
          ? messageDateToParams.toISOString()
          : messagesToDate.toISOString(),
        page: page,
        perPage: perPage,
      });
    }
  }, [apartamentMeterId]);

  useEffect(() => {
    if (!firstRender) {
      const messagesDateTo = new Date(formData?.insertion_date?.to);
      messagesDateTo.setDate(messagesDateTo.getDate() + 1);
      setMsgTo(messagesDateTo);
      getDeviceMessages({
        digitalTwinId: meter.attributes.digital_twin_id,
        deviceId: impulsiveMeterId ?? apartamentMeterId ?? meter.id,
        from: formData?.insertion_date?.from,
        to: messagesDateTo.toISOString(),
        page: page,
        perPage: perPage,
      });
      getMeterReadingGraph({
        digitalTwinId: meter.attributes.digital_twin_id,
        deviceId: impulsiveMeterId ?? apartamentMeterId ?? meter.id,
        from: formData?.insertion_date?.from,
        to: formData?.insertion_date?.to,
      });
    }
  }, [page]);
  useEffect(() => {
    if (refreshParams) {
      const messagesDateTo = new Date(formData.insertion_date.to);
      messagesDateTo.setDate(messagesDateTo.getDate() + 1);
      setMsgTo(messagesDateTo);
      getMeterReadingGraph({
        digitalTwinId: meter.attributes.digital_twin_id,
        deviceId: impulsiveMeterId ?? apartamentMeterId ?? meter.id,
        from: formData?.insertion_date?.from,
        to: formData?.insertion_date?.to,
      });
      getDeviceMessages({
        digitalTwinId: meter.attributes.digital_twin_id,
        deviceId: impulsiveMeterId ?? apartamentMeterId ?? meter.id,
        from: formData?.insertion_date?.from,
        to: messagesDateTo.toISOString(),
        page: page,
        perPage: perPage,
      });
    }

    setRefreshParams(false);
  });
  useEffect(() => {}, [apartamentMeterId]);
  const hanldeSubimt = ({ formData: selectdIntervalId }) => {
    var newFrom = new Date(now);
    switch (selectdIntervalId) {
      case 1:
        newFrom.setDate(newFrom.getDate() - 7);
        setFrom(newFrom);
        break;
      case 2:
        newFrom.setMonth(newFrom.getMonth() - 1);
        setFrom(newFrom);
        break;
      case 3:
        newFrom.setMonth(newFrom.getMonth() - 3);
        setFrom(newFrom);
        break;
      case 4:
        newFrom.setMonth(newFrom.getMonth() - 6);
        setFrom(newFrom);
        break;
      case 5:
        newFrom.setFullYear(newFrom.getFullYear() - 1);
        setFrom(newFrom);
        break;
      default:
        newFrom.setDate(newFrom.getDate() - 7);
        setFrom(newFrom);
        break;
    }
  };

  const onSubmitClick = (obj) => {
    /*    if (firstSearch) {
      setFirstSearch(false);
    } else { */
    obj["page"] = page;
    obj["per_page"] = perPage;

    const params = prepareParams(obj);
    if (!enteredFromCondominium && !enteredFromParent) {
      navigate({
        pathname: `/meters/${meter.id}`,
        search: params.toString(),
      });
    }
    dispatch(setReadingRange(null));
    setPage(1);
    setRefreshParams(true);
    /*  } */
  };
  useEffect(() => {}, [meter]);
  const huivrota = {
    ...data,
    dayDiff: "piska",
  };
  useEffect(() => {}, [data]);
  const styleSelector = (params) => {
    const datastr = params.row.readTime;
    const date = new Date();

    if (params?.row?.alarms?.length > 0 && params?.row?.alarms !== "--") {
      return `super-app-theme--Orange`;
    }
    if (params?.row?.dayDiff > 5) {
      return `super-app-theme--PartiallyFilled`;
    }
    if (params?.row?.strValue == "--") {
      return `super-app-theme--Rejected`;
    }
  };
  const dinamicWidthSelector = () => {
    if (is1280x720 && appBarIsOpen && !enteredFromCondominium) {
      return 1330;
    }
    if (is1280x720 && appBarIsOpen && enteredFromCondominium) {
      return 1272;
    }
    if (is1280x720 && !appBarIsOpen && !enteredFromCondominium) {
      return 1714;
    }
    if (is1280x720 && !appBarIsOpen && enteredFromCondominium) {
      return 1426;
    }
    if (!is1280x720 && appBarIsOpen && !enteredFromCondominium) {
      return 1503;
    }
    if (!is1280x720 && appBarIsOpen && enteredFromCondominium) {
      return 1272;
    }
    if (!is1280x720 && !appBarIsOpen && !enteredFromCondominium) {
      return 1771;
    }
    if (!is1280x720 && !appBarIsOpen && enteredFromCondominium) {
      return 1445;
    }
  };

  useEffect(() => {
    if (heatMeterRows && meter?.attributes?.fields?.type == "13") {
      dispatch(
        setReadingRange({
          start: heatMeterRows[0],
          end: heatMeterRows[heatMeterRows.length - 1],
        })
      );
    }
    if (readingRows && meter?.attributes?.fields?.type == "07") {
      dispatch(
        setReadingRange({
          start: readingRows[0],
          end: readingRows[readingRows.length - 1],
        })
      );
    }
  }, [heatMeterRows, readingRows]);

  useEffect(() => {
    console.log("ridrows", data);
  }, [data]);

  useEffect(() => {
    if (
      meter?.attributes?.fields?.type == "07" &&
      data?.length > 0 &&
      meter?.attributes?.class !== "PulseCounter"
    ) {
      debugger;
      const unit = data?.[0]?.unit;
      setUnit(unit == "m^3" ? "m³" : "liters");
      setInitialUnit(unit == "m^3" ? "m³" : "liters");
    }
  }, [data]);
  useEffect(() => {
    if (
      meter?.attributes?.fields?.type == "07" &&
      data?.length > 0 &&
      meter?.attributes?.class == "PulseCounter"
    ) {
      setUnit(true);
    }
  }, [meter, data]);

  return (
    <>
      <Box>
        <FormAccordion width={() => dinamicWidthSelector()}>
          <Form
            schema={schema}
            uiSchema={uiSchema}
            fields={fields}
            formData={formData}
            showErrorList={false}
            onChange={({ formData: newFormData }) => setFormData(newFormData)}
            onSubmit={(values) => {
              onSubmitClick(values.formData);
            }}
            validator={validator}
          >
            <Box width={() => () => dinamicWidthSelector()}>
              <Tooltip title={t("clear")}>
                <Button
                  onClick={() => onClearClick(formData)}
                  sx={{ mr: 2 }}
                  variant="contained"
                >
                  <BackspaceIcon />
                </Button>
              </Tooltip>
              <Tooltip title={t("search")}>
                <Button
                  disabled={
                    formData?.insertion_date?.from == null ||
                    formData?.insertion_date?.to == null
                  }
                  variant="contained"
                  onClick={() => onSubmitClick(formData)}
                >
                  <SearchIcon />
                </Button>
              </Tooltip>
            </Box>
          </Form>
        </FormAccordion>
        {meter?.id && (
          <Box width={() => dinamicWidthSelector()}>
            <Box sx={{ float: "right" }} display={"flex"}>
              {meter?.attributes?.fields?.type == "07" &&
                data?.length > 0 &&
                meter?.attributes?.class !== "PulseCounter" &&
                unit && (
                  <IwdAuthWrapper
                    children={
                      <Button
                        onClick={convertUnit}
                        size="small"
                        sx={{ mt: 3, mr: 2, height: 37 }}
                        variant="contained"
                      >
                        {unit == "m³"
                          ? `${t("convert_in_l")}`
                          : `${t("convert_in_m3")}`}
                      </Button>
                    }
                    section={"reading_converter"}
                  />
                )}
              <Box sx={{ mt: 3, mr: 2 }}>
                {meter?.attributes?.fields?.type == "08" && (
                  <IwdAuthWrapper
                    section={"import_hca_history_btn"}
                    children={
                      <ImportHistoryButton id={meter.id} mode={"meter"} />
                    }
                  />
                )}
              </Box>
              <ExportMessageButton
                params={params}
                digitaltwinId={meter.attributes.digital_twin_id}
                meeterId={meter.id}
                styleProp={{ float: "right", mt: 3 }}
              />
            </Box>
          </Box>
        )}
        {data?.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: dinamicWidthSelector(),
              mt: 10,
            }}
          >
            {meter?.attributes?.fields?.type == "07" && (
              <Box sx={{ flexGrow: 1, width: "50%", mt: 12, height: 451 }}>
                <StyledDataGrid
                  getRowClassName={(params) => styleSelector(params)}
                  disableColumnMenu
                  rows={readingRows?.length > 0 ? readingRows : []}
                  columns={columns}
                  pageSize={perPage}
                  // rowsPerPageOptions={[perPage]}
                  // paginationMode="client"
                  onPageChange={(pageNumber) => {
                    setPage(pageNumber + 1);
                  }}
                  page={page - 1}
                  rowCount={meta?.count}
                  loading={isLoadingTable}
                  paginationMode="server"
                  getRowHeight={() => "auto"}

                  // sortingMode="server"
                  // onSortModelChange={handleSortModelChange}

                  // disableSelectionOnClick
                  // experimentalFeatures={{ newEditingApi: true }}
                />
              </Box>
            )}
            {meter?.attributes?.fields?.type == "08" && (
              <Box sx={{ flexGrow: 1, width: "50%", mt: 12, height: 451 }}>
                <StyledDataGrid
                  getRowClassName={(params) => styleSelector(params)}
                  disableColumnMenu
                  rows={heatReadingRows?.length > 0 ? heatReadingRows : []}
                  columns={heatColumns}
                  pageSize={perPage}
                  // rowsPerPageOptions={[perPage]}
                  // paginationMode="client"
                  onPageChange={(pageNumber) => {
                    setPage(pageNumber + 1);
                  }}
                  page={page - 1}
                  rowCount={meta?.count}
                  loading={isLoadingTable}
                  paginationMode="server"
                  getRowHeight={() => "auto"}

                  // sortingMode="server"
                  // onSortModelChange={handleSortModelChange}

                  // disableSelectionOnClick
                  // experimentalFeatures={{ newEditingApi: true }}
                />
              </Box>
            )}
            {meter?.attributes?.fields?.type == "13" && (
              <Box sx={{ flexGrow: 1, width: "50%", mt: 12, height: 451 }}>
                <StyledDataGrid
                  getRowClassName={(params) => styleSelector(params)}
                  disableColumnMenu
                  rows={heatMeterRows?.length > 0 ? heatMeterRows : []}
                  columns={heatMeterColumns}
                  pageSize={perPage}
                  // rowsPerPageOptions={[perPage]}
                  // paginationMode="client"
                  onPageChange={(pageNumber) => {
                    setPage(pageNumber + 1);
                  }}
                  page={page - 1}
                  rowCount={meta?.count}
                  loading={isLoadingTable}
                  paginationMode="server"
                  getRowHeight={() => "auto"}

                  // sortingMode="server"
                  // onSortModelChange={handleSortModelChange}

                  // disableSelectionOnClick
                  // experimentalFeatures={{ newEditingApi: true }}
                />
              </Box>
            )}
            {meter?.attributes?.fields?.type !== "13" && (
              <Box sx={{ width: "50%", mt: 1, height: 650 }}>
                <Box sx={{ mt: 10 }}>
                  <MeterReadingsGraph unit={unit} meter={meter} />
                </Box>
              </Box>
            )}
            {meter?.attributes?.fields?.type == "13" && (
              <Box sx={{ width: "50%", mt: 1, height: 650 }}>
                <Box sx={{ mt: 10 }}>
                  <ColorMeterReadingGraph meter={meter} />
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          <Typography
            align="center"
            sx={{ color: theme.palette.primary.main, mt: 10 }}
            variant="h4"
          >
            {t("no_available_data")}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default MeterReadingsTab;
