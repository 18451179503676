import { useEffect, useState } from "react";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { setDigitalTwinName } from "../../redux/slices/digitalTwinSlice";
import { store } from "../../redux/store";
import { setMessage } from "../../redux/slices/snackbarSlice";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { errorTypeActions } from "../../redux/slices/errorTypeSlice";
import { useSelector, useDispatch } from "react-redux";
const ErrorTypeIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [trashModal, setTrashModal] = useState(false);
  const [trashId, setTrashId] = useState(null);
  const [getErrorTypes] = store.useLazyGetErrorTypesQuery();
  const [deleteErrorType, { isSuccess: deleteSuccess }] =
    store.useDeleteErrorTypeMutation();
  const errorTypes = useSelector((state) => state?.errorTypeSlice?.errorTypes);
  const errorTypeTrashId = useSelector(
    (state) => state?.errorTypeSlice?.trashId
  );
  const errorTypesCount = useSelector(
    (state) => state?.errorTypeSlice?.errorTypesCount
  );
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { t } = useTranslation();
  useEffect(() => {
    getErrorTypes({ page: page, per_page: perPage });
  }, [deleteSuccess, page]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
    }
  }, [deleteSuccess]);
  const onTrashClick = (id) => {
    setTrashId(id);
    setTrashModal(true);
  };
  useEffect(() => {
    dispatch(setBreadCrumbs("errors_types"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  useEffect(() => {
    if (errorTypeTrashId) {
      deleteErrorType(errorTypeTrashId);

      dispatch(errorTypeActions.setErrorTypeTrashId(null));
    }
  }, [errorTypeTrashId]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "left",
      sortable: false,
      flex: 2,
      /*      hide: "true", */
    },
    {
      field: "name",
      headerName: t("name"),
      headerAlign: "left",
      sortable: false,
      flex: 2,
    },
    {
      field: "inserted",
      headerName: t("inserted_at"),
      headerAlign: "left",
      sortable: false,
      flex: 2,
    },
    {
      field: "updated",
      headerName: t("updated_at"),
      headerAlign: "left",
      sortable: false,
      flex: 2,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,

      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IconButton
              onClick={() => onTrashClick(params.value)}
              sx={{ float: "right" }}
            >
              <DeleteIcon sx={{ ml: 2, fill: theme.palette.error.main }} />
            </IconButton>
            <Link to={`/errortypes/${params.value}`}>
              <VisibilityIcon
                sx={{ float: "right", mt: 1, fill: theme.palette.primary.main }}
              />
            </Link>
          </Box>
        );
      },
    },
  ];
  const tableRows = errorTypes?.map((item) => ({
    id: item?.id ?? "--",
    name: item?.name ?? "--",
    inserted: item?.inserted_at
      ? t("dt", { val: new Date(item?.inserted_at) })
      : "--",
    updated: item?.updated_at
      ? t("dt", { val: new Date(item?.updated_at) })
      : "--",
    actions: item?.id,
  }));
  useEffect(() => {}, [errorTypes]);
  return (
    <>
      <>
        {/*  <Typography
          variant="h4"
          sx={{ mb: 2, color: theme.palette.primary.main }}
        >
          {t("errors_types")}
        </Typography> */}
        <DeleteConfirmModal
          trashModal={trashModal}
          setTrashModal={setTrashModal}
          text={t("error_type_delete_text")}
          title={t("error_type_delete_title")}
          deleteFunc={() => errorTypeActions.setErrorTypeTrashId(trashId)}
        />

        <Box sx={{ height: 681 }}>
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <Button
              sx={{ mt: 2, mb: 1 }}
              onClick={() => navigate("/errortypes/create")}
            >
              {t("create_errors_types")}
            </Button>
          </Box>
          <StyledDataGrid
            disableColumnMenu
            rows={tableRows ?? []}
            columns={columns ?? []}
            rowCount={errorTypesCount}
            pageSize={perPage}
            rowsPerPageOptions={[perPage]}
            disableSelectionOnClick
            onPageChange={(pageNumber) => {
              setPage(pageNumber + 1);
            }}
            page={page - 1}
            // loading={isLoadingTable}
            paginationMode="server"
            getRowHeight={() => "auto"}
          />
        </Box>
      </>
    </>
  );
};

export default ErrorTypeIndex;
