import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { TextField, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import NetworkAdrWindget from "./NetworkAdrWidget";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { useTranslation } from "react-i18next";
import ProfileAdrWidget from "./ ProfileAdrWidget";
import { setSeverity } from "../../redux/slices/snackbarSlice";
import { setNetworkRegion } from "../../redux/slices/loraSlice";
import Autocomplete from "@mui/material/Autocomplete";
import { store } from "../../redux/store";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import NetworkChannelWidget from "./NetworkChannelWidget";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        if (
          element.name === "network_adr" ||
          element.name === "initial_channels" ||
          element.name === "profile_adr"
        ) {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const LorawanCommandsTab = ({ fields, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [firstRender, setFirstRender] = useState(true);
  const [option, setOption] = useState("Area");

  const [postCommands, { isSuccess: postSuccess }] =
    store.usePostCommandsMutation();
  const networkEnum = fields?.networks?.map((network) => ({
    const: network?.name,
  }));
  const groupsEnum = fields?.groups?.map((network) => ({
    const: network?.name,
  }));
  const regionEnum = [
    { const: "EU 863-870MHz" },
    { const: "US 902-928MHz" },
    { const: "US 902-928MHz (Private Hybrid)" },
    { const: "China 779-787MHz" },
    { const: "EU 433MHz" },
    { const: "Australia 915-928MHz" },
    { const: "China 470-510MHz" },
    { const: "Asia 923MHz" },
    { const: "South Korea 920-923MHz" },
    { const: "India 865-867MHz" },
    { const: "Russia 864-870MHz" },
  ];
  const applicationEnum = [{ const: "semtech-mote" }];

  const uifields = {
    networkadr: NetworkAdrWindget,
    channel: NetworkChannelWidget,
    profileadr: ProfileAdrWidget,
  };
  const amdinEnum = [{ const: "admin" }];
  const codingRateEnum = [
    { const: "4/5" },
    { const: "4/6" },
    { const: "4/7" },
    { const: "4/8" },
  ];
  const tx_window = [{ const: "Auto" }, { const: "RX1" }, { const: "RX2" }];
  const fcntEnum = [
    { const: "Strict 16-bit" },
    { const: "Strict 32-bit" },
    { const: "Reset on zero" },
    { const: "Disabled" },
  ];
  const joinEnum = [
    { const: "Denied" },
    { const: "Allowed" },
    { const: "Allowed with old Nonce" },
  ];
  const logEnum = [
    { const: "true" },
    { const: "false" },
    { const: "undefined" },
  ];
  const options = [
    { label: "Area" },
    { label: "Network" },
    { label: "Groups" },
    { label: "Profile" },
  ];
  const areaScheme = {
    title: "New Campaign",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: t("name"),
      },

      region: {
        type: "string",
        title: t("region"),
        oneOf: [...regionEnum],
      },
      /* 
      administrators: {
        $id: "10",
        title: t("administrators"),
        type: "string",
        oneOf: [...amdinEnum],
      },
      stack_channel: {
        $id: "10",
        title: t("stack_channel"),
        type: "string",
      }, */
      log_ignored: {
        $id: "10",
        title: t("log_ignored"),
        type: "string",
        oneOf: [...logEnum],
        default: "true",
      },
    },
    required: ["name", "region"],
  };
  useEffect(() => {
    if (formData.region) {
      dispatch(setNetworkRegion(formData?.region));
    }
  }, [formData?.region]);
  const uiAreaSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,

    "ui:submitButtonOptions": {
      submitText: t("create"),
    },
    network_adr: {
      "ui:field": "networkadr",
    },
    initial_channels: {
      "ui:field": "channel",
    },
    profile_adr: {
      "ui:field": "profileadr",
    },
  };
  const networkScheme = {
    title: "New Campaign",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: t("name"),
      },
      netid: {
        type: "string",
        title: t("netid"),
      },

      region: {
        type: "string",
        title: t("region"),
        oneOf: [...regionEnum],
      },
      coding_rate: {
        type: "string",
        title: t("coding_rate"),
        oneOf: [...codingRateEnum],
      },
      rx1: {
        type: "string",
        title: t("rx1"),
      },
      rx2: {
        type: "string",
        title: t("rx2"),
      },
      rx1_delay: {
        type: "string",
        title: t("rx1_delay"),
      },
      rx2_delay: {
        type: "string",
        title: t("rx2_delay"),
      },
      gateway_power: {
        type: "string",
        title: t("gateway_power"),
      },
      network_adr: {
        type: "object",
        properties: {
          max_eirp: {
            type: "string",
          },
          max_power: {
            type: "string",
          },
          min_power: {
            type: "string",
          },
          data_rate: {
            type: "string",
          },
          duty_cicle: {
            type: "string",
          },
          initial_rx1: {
            type: "string",
          },
          initial_rx2: {
            type: "string",
          },
          initial_rx2f: {
            type: "string",
          },
        },
      },
      initial_channels: {
        type: "object",
      },
    },
    required: [
      "name",
      "netid",
      "region",
      "coding_rate",
      "rx1",
      "rx2",
      "rx1_delay",
      "rx2_delay",
      "gateway_power",
    ],
  };
  const groupScheme = {
    title: "New Campaign",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: t("name"),
      },

      network: {
        type: "string",
        title: t("network"),
        oneOf: networkEnum ? [...networkEnum] : [],
      },
      sub_id: {
        type: "string",
        title: t("sub_id"),
      },

      /*    administrators: {
        $id: "10",
        title: t("administrators"),
        type: "string",
        oneOf: [...amdinEnum],
      },
      stack_channel: {
        $id: "10",
        title: t("stack_channel"),
        type: "string",
      }, */
      can_join: {
        $id: "10",
        title: t("can_join"),
        type: "string",
        oneOf: [...logEnum],
        default: "true",
      },
    },
    required: ["name", "network"],
  };
  const profileScheme = {
    title: "New Campaign",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: t("name"),
      },

      group: {
        type: "string",
        title: t("group"),
        oneOf: groupsEnum ? [...groupsEnum] : [],
      },
      application: {
        type: "string",
        title: t("application"),
        oneOf: [...applicationEnum],
      },

      app_identifier: {
        type: "string",
        title: t("app_identifier"),
      },
      join: {
        $id: "10",
        title: t("join"),
        type: "string",
        oneOf: [...joinEnum],
      },
      fcnt_check: {
        $id: "10",
        title: t("fcnt_check"),
        type: "string",
        oneOf: [...fcntEnum],
      },
      tx_window: {
        $id: "10",
        title: t("tx_window"),
        type: "string",
        oneOf: [...tx_window],
      },
      profile_adr: {
        type: "object",
        properties: {
          arp_mode: {
            type: "string",
          },
          power: {
            type: "string",
          },

          data_rate: {
            type: "string",
          },

          max_data_rate: {
            type: "string",
          },
          channels: {
            type: "string",
          },
          duty_cycle: {
            type: "string",
          },
          rx1: {
            type: "string",
          },
          rx2: {
            type: "string",
          },
          rx2f: {
            type: "string",
          },
          request_status: {
            type: "string",
          },
        },
      },
    },
    required: ["name", "group"],
  };
  const [schema, setSchema] = useState(areaScheme);
  const [uiSchema, setUiSchema] = useState(uiAreaSchema);
  useEffect(() => {
    setFirstRender(false);
    if (!firstRender && option == "Area") {
      setFormData({});
      dispatch(setNetworkRegion(null));
      setSchema(areaScheme);
    } else if (!firstRender && option == "Network") {
      dispatch(setNetworkRegion(null));
      setFormData({});
      setSchema(networkScheme);
    } else if (!firstRender && option == "Groups") {
      dispatch(setNetworkRegion(null));
      setFormData({});
      setSchema(groupScheme);
    } else if (!firstRender && option == "Profile") {
      dispatch(setNetworkRegion(null));
      setFormData({});
      setSchema(profileScheme);
    }
  }, [option]);
  useEffect(() => {}, [formData, option]);
  const submitHandler = () => {
    const areaAttributes = {
      values: {
        command: "create_area",
        content: {
          /*  admins: [formData?.administrators], */
          log_ignored:
            formData.log_ignored === "undefined"
              ? null
              : formData.log_ignored === "true"
              ? true
              : false,
          name: formData?.name,
          region: formData?.region,
          /*      stack_channel: formData?.stack_channel, */
        },
      },
    };
    const groupAttributes = {
      values: {
        command: "create_group",
        content: {
          /*   admins: formData?.administrators ? [formData?.administrators] : null, */
          can_join:
            formData.can_join === "undefined"
              ? null
              : formData.can_join === "true"
              ? true
              : false,
          name: formData?.name,
          sub_id: formData?.sub_id,
          /*    stack_channel: formData?.stack_channel, */
          network: formData?.network,
        },
      },
    };
    const networkAttributes = {
      values: {
        command: "create_network",
        content: {
          /* cflist: null, */
          gw_power: formData?.gateway_power,
          init_chans: formData?.initial_channels?.channel,
          join1_delay: formData?.rx1,
          join2_delay: formData?.rx2,
          rx1_delay: formData?.rx1_delay,
          rx2_delay: formData?.rx1_delay,
          max_datar: formData?.network_adr?.max_data_rate,
          max_eirp: formData?.network_adr?.max_eirp,
          name: formData?.name,
          max_power: formData?.network_adr?.max_power,
          min_power: formData?.network_adr?.min_power,
          netid: formData?.netid,
          region: formData?.region,
          tx_codr: formData?.coding_rate,
          dcycle_init: formData?.network_adr?.duty_cycle,
          rxwin_init: {
            rx1_dr_offset: formData?.network_adr?.initial_rx1,
            rx2_dr: formData?.network_adr?.initial_rx2,
            rx2_freq: formData?.network_adr?.initial_rx2f,
          },
        },
      },
    };
    const profileAttributes = {
      values: {
        command: "create_profile",
        content: {
          ...formData,
        },
      },
    };
    switch (option) {
      case "Area":
        postCommands({
          attributes: areaAttributes,
          commandName: "configure",
          digitalTwinId: window.LORA,
          digitalTwinInstance: id,
        });
        break;
      case "Groups":
        postCommands({
          attributes: groupAttributes,
          commandName: "configure",
          digitalTwinId: window.LORA,
          digitalTwinInstance: id,
        });
        break;
      case "Network":
        postCommands({
          attributes: networkAttributes,
          commandName: "configure",
          digitalTwinId: window.LORA,
          digitalTwinInstance: id,
        });
        break;
      case "Profile":
        postCommands({
          attributes: profileAttributes,
          commandName: "configure",
          digitalTwinId: window.LORA,
          digitalTwinInstance: id,
        });
        break;
    }
  };
  const onSubmitClick = () => {
    submitHandler();
  };
  return (
    <>
      <Autocomplete
        fullWidth
        disablePortal
        value={option}
        onChange={(event, newValue) => {
          setOption(newValue.label);
        }}
        id="combo-box-demo"
        options={options}
        renderInput={(params) => (
          <TextField {...params} /* label="Command" */ />
        )}
      />
      <Box sx={{ mt: 4 }}>
        <Form
          schema={schema}
          fields={uifields}
          formData={formData}
          onChange={(changeEvent) => setFormData(changeEvent.formData)}
          onSubmit={onSubmitClick}
          validator={validator}
          uiSchema={uiSchema}
          /* widgets={widgets} */
          showErrorList={false}
          /*   customValidate={customValidate} */
          noHtml5Validate
        />
      </Box>
    </>
  );
};
export default LorawanCommandsTab;
