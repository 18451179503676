import { useEffect, useState } from "react";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { store } from "../../redux/store";
import { useMemo } from "react";
import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import theme from "../../theme";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { Chip } from "@material-ui/core";

const DigitalTwinShow = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [dt, setDt] = useState();
  const [firstRender, setFirstRender] = useState(true);
  const [filteredValue, setFilteredValue] = useState("");
  const { t } = useTranslation();
  const digitalTwin = useSelector(
    (state) => state?.digitalTwinSlice?.digitalTwin
  );
  const digitalTwinName = useSelector(
    (state) => state?.digitalTwinSlice?.digitalTwinName
  );
  const mappedDt = digitalTwin?.map((dt) => ({
    label: dt?.attributes?.name,
  }));
  const dispatch = useDispatch();
  const { id } = useParams();
  const [getDigitalTwin] = store.useLazyGetDigitalTwinAttributesShowQuery();

  useEffect(() => {
    getDigitalTwin(id);
  }, [id]);

  useEffect(() => {
    if (digitalTwin && firstRender) {
      setDt(digitalTwin);
      setFirstRender(false);
    }
  }, [digitalTwin]);
  useEffect(() => {
    if (filteredValue) {
      setDt(
        digitalTwin?.filter(
          (item) => item?.attributes?.name == filteredValue.label
        )
      );
    } else {
      setDt(digitalTwin);
    }
  }, [filteredValue]);
  useEffect(() => {
    if (digitalTwinName) {
      dispatch(
        setBreadCrumbs({
          id: digitalTwinName,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [digitalTwinName]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: true, // Enable sorting by ID
      flex: 3,
    },
    {
      field: "name",
      headerName: t("name"),
      type: "string",
      sortable: true, // Enable sorting by Name
      editable: false,
      flex: 4,
    },
    {
      field: "category",
      headerName: t("category"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 4,
    },
    {
      field: "access",
      headerName: t("access"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 4,
    },
    {
      field: "type",
      headerName: t("type"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        if (params.value === undefined) {
          return <>--</>;
        } else {
          return (
            <>
              <Chip label={params.value} />
            </>
          );
        }
      },
    },
  ];

  const tableRows = useMemo(
    () =>
      dt?.map((item, i) => ({
        id: i,
        access: t(item?.attributes?.access) ?? "--",
        name: item?.attributes?.name,
        category: item?.attributes?.category,
        type: item?.attributes?.type,
      })),
    [dt]
  );

  useEffect(() => {}, [tableRows]);

  return (
    <>
      {dt && (
        <>
          <Typography
            variant="h4"
            sx={{ color: theme.palette.primary.main, mb: 2 }}
          >
            {digitalTwinName}
          </Typography>
          <Autocomplete
            sx={{ mb: 3 }}
            disablePortal
            id="combo-box-demo"
            options={mappedDt}
            onChange={(event, newValue) => {
              setFilteredValue(newValue);
            }}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="attribute " />
            )}
          />
          <Box sx={{ height: "70vh", width: "99%", mt: 2 }}>
            <StyledDataGrid
              disableColumnMenu
              rows={tableRows ?? []}
              columns={columns ?? []}
              disableSelectionOnClick
              page={page - 1}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default DigitalTwinShow;
