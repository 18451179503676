import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { setBalancType } from "../../redux/slices/balanceSlice";
import { store } from "../../redux/store";
import SearchOnServer from "../../components/form/formComponents/SearchOnServer";
import { Button, Grid, Typography } from "@mui/material";
import BalanceTransferList from "./BalanceTransferList";
import theme from "../../theme";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { Box } from "@mui/system";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      rowSpacing={3}
      spacing={2}
    >
      {props.properties?.map((element, i) => {
        if (element.name === "meters" || element.name === "balance_type") {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const CreateBalance = () => {
  const condominiumShow = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  );
  const navigate = useNavigate();
  const { id } = useParams();
  const [createdId, setCreatedId] = useState(null);
  const [createBalance] = store.useCreateBalanceMutation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const balanceTypes = [
    { const: "water" },
    { const: "cooling" },
    { const: "heating" },
    { const: "water_heating" },
  ];

  const [formData, setFormData] = useState({});

  const scm = {
    type: "object",
    properties: {
      name: {
        $id: "5",
        type: "string",
        title: t("name"),
      },
      description: {
        $id: "5",
        type: "string",
        title: t("description"),
      },
      /* code: {
        $id: "5",
        type: "string",
        title: t("code"),
      }, */
      limit: {
        $id: "5",
        type: "number",
        title: t("limit"),
      },
      balance_type: {
        $id: "5",
        type: "string",
        title: t("balance_type"),
        oneOf: [...balanceTypes],
      },
      roles: {
        $id: "10",
        type: "array",
        title: t("roles"),
        roles: true,
        multiple: true,
        url: "role",
      },
      meters: {
        $id: "5",
        type: "object",
        properties: {
          in_meters: {
            type: "array",
          },
          out_meters: {
            type: "array",
          },
        },
      },
    },
  };

  const [schema, setSchema] = useState(scm);
  useEffect(() => {
    if (condominiumShow) {
      dispatch(
        setBreadCrumbs({
          id: condominiumShow?.name,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [condominiumShow]);
  useEffect(() => {
    if (formData.balance_type === "water_heating") {
      const props = { ...scm.properties };
      props["delta_t"] = {
        type: "number",
        default: 35,
        title: t("delta_t"),
      };
      props["conversion_value"] = {
        type: "number",
        default: 860,
        title: t("conversion_value"),
      };
      const newSchema = { ...schema, properties: props };
      setSchema(newSchema);
    } else {
      const props = { ...scm.properties };
      delete props.delta_t;
      delete props.conversion_value;
      const newSchema = { ...schema, properties: props };
      setSchema(newSchema);
    }
  }, [formData.balance_type]);

  const fields = {
    transfer: BalanceTransferList,
    serverSearch: SearchOnServer,
  };
  const uiSchema = {
    "ui:order": [
      "name",
      "description",
      "code",
      "limit",
      "roles",
      "balance_type",
      "delta_t",
      "conversion_value",
      "meters",
    ],

    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    roles: {
      "ui:field": "serverSearch",
    },
    "ui:submitButtonOptions": {
      submitText: t("create"),
    },
    meters: {
      "ui:field": "transfer",
    },
  };

  useEffect(() => {
    console.log("forma4ka", formData);
  }, [formData]);

  useEffect(() => {
    if (formData.balance_type) {
      switch (formData.balance_type) {
        case "water":
          dispatch(setBalancType(["AFS", "ACS"]));
          break;

        case "cooling":
          dispatch(setBalancType(["Frigorie"]));
          break;
        case "heating":
          dispatch(setBalancType(["Calorie"]));
          break;
        case "water_heating":
          dispatch(setBalancType(["Calorie", "ACS"]));
          break;
      }
    }
  }, [formData?.balance_type]);

  const balanceTypeSelector = (type) => {
    switch (type) {
      case t("water"):
        return "water";

      case t("cooling"):
        return "cooling";
      case t("heating"):
        return "heating";
      case t("water_heating"):
        return "water_heating";
    }
  };

  const onSubmitClick = () => {
    const attributes = {
      condominium_id: +id,
      name: formData.name,
      description: formData.description,
      in_meters: formData?.meters?.in_meters,
      out_meters: formData?.meters?.out_meters,
      balance_type:
        /* balanceTypeSelector(formData?.balance_type) */ formData?.balance_type,
      code: formData?.code,
      limit: formData?.limit,
      roles: formData?.roles,
      meta: {
        block_name: condominiumShow?.name,
        address: condominiumShow?.address,
        region: condominiumShow?.province,
      },
      coefficients: {
        delta_t: formData?.delta_t,
        conversion_value: formData?.conversion_value,
      },
    };
    createBalance(attributes)
      .unwrap()
      .then((data) => setCreatedId(data?.data?.attributes?.id))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (createdId) {
      navigate(`/condominiums/${id}/balance/${createdId}`);
    }
  }, [createdId]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography
        sx={{ mb: 4, color: theme.palette.primary.main }}
        variant="h4"
      >
        {t("create_balance")}
      </Typography>
      <Form
        schema={schema}
        fields={fields}
        formData={formData}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        onSubmit={(values) => onSubmitClick()}
        validator={validator}
        uiSchema={uiSchema}
        /* widgets={widgets} */
        showErrorList={false}
        /*   customValidate={customValidate} */
        noHtml5Validate
      />
    </Box>
  );
};
export default CreateBalance;
