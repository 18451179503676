import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import theme from "../theme";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

const DeleteConfirmModal = ({
  trashModal,
  setTrashModal,
  trashId,
  title,
  text,
  deleteFunc,
  reduxMode = false,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const handleClose = () => {
    if (!reduxMode) {
      setTrashModal(false);
    } else {
      dispatch(setTrashModal(false));
    }
  };

  const onConfirmClick = () => {
    dispatch(deleteFunc(trashId));
    handleClose();
  };

  return (
    <Box>
      <Modal open={trashModal} onClose={handleClose}>
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            {title}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            align="center"
          >
            {text}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pt: 2,
            }}
          >
            <Button
              sx={{
                background: theme.palette.error.main,
                "&:hover": {
                  background: theme.palette.error.main,
                },
              }}
              onClick={() => onConfirmClick()}
            >
              {t("yes")}
            </Button>
            <Button onClick={handleClose}>{t("no")}</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DeleteConfirmModal;
