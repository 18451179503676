import { useEffect, useState } from "react";

import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { store } from "../../redux/store";
import Chip from "@mui/material/Chip";

import { setTrashId as setStatisticTrashId } from "../../redux/slices/statisticsSlice";
import { Box, Typography, Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { setMessage } from "../../redux/slices/snackbarSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";

const Statistics = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [trashModal, setTrashModal] = useState(false);
  const [trashId, setTrashId] = useState(null);
  const statistics = useSelector((state) => state?.statisticsSlice?.statistics);
  const [getStatistics] = store.useLazyGetStatisticsQuery();
  const [deleteStatistic, { isSuccess: deleteSuccess }] =
    store.useDeleteStatisticMutation();
  const statisticsCount = useSelector(
    (state) => state?.statisticsSlice?.statisticsCount
  );
  const statisticTrashid = useSelector(
    (state) => state?.statisticsSlice?.trashId
  );
  useEffect(() => {
    getStatistics({ page: page, per_page: perPage });
  }, [page]);
  useEffect(() => {
    if (statisticTrashid) {
      deleteStatistic(statisticTrashid);
    }
  }, [statisticTrashid]);
  useEffect(() => {
    setStatisticTrashId(null);
  }, []);
  useEffect(() => {
    dispatch(setBreadCrumbs("Statistics"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  useEffect(() => {
    if (deleteSuccess) {
      getStatistics({ page: page, per_page: perPage });
      dispatch(setStatisticTrashId(null));
      dispatch(setMessage(t("successfully_deleted")));
    }
  }, [deleteSuccess]);
  const onTrashClick = (id) => {
    setTrashId(id);
    setTrashModal(true);
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "name",
      headerName: t("name"),
      width: 90,
      sortable: false,
      flex: 2,
    },
    {
      field: "groups",
      headerName: t("groups"),
      width: 90,
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        if (params.value !== "--") {
          return (
            <Box display={"flex"} flexWrap="wrap" width={"100%"}>
              {params.value?.map((item) => (
                <Chip sx={{ mt: 1 }} label={item} />
              ))}
            </Box>
          );
        }
      },
    },
    {
      field: "insertion_date",
      headerName: t("insertion_date"),
      width: 90,
      sortable: false,
      flex: 2,
    },
    {
      field: "connection_type",
      headerName: t("connection_type"),
      width: 90,
      sortable: false,
      flex: 1,
    },
    {
      field: "type",
      headerName: t("type"),
      width: 90,
      sortable: false,
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems="center">
            <IconButton sx={{ mt: 0.5, ml: 1 }}>
              <Link to={`/statistics/${params?.id}`}>
                <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
              </Link>
            </IconButton>
            {/*  <MessagePopover
                message={params?.value.values.values.value}
              ></MessagePopover> */}
            <IconButton onClick={() => onTrashClick(params?.id)}>
              <DeleteIcon sx={{ fill: theme.palette.error.main }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const rows = statistics?.map((item) => ({
    id: item.id,
    name: item?.name,
    groups: item?.groups,
    insertion_date: t("dt", { val: new Date(item.inserted_at) }),
    connection_type: item.connection_type,
    type: item.type,
    actions: item.id,
  }));

  return (
    <>
      <DeleteConfirmModal
        trashModal={trashModal}
        setTrashModal={setTrashModal}
        text={t("statistic_delete_text")}
        title={t("statistic_delete_title")}
        deleteFunc={() => setStatisticTrashId(trashId)}
      />

      {rows && (
        <Box sx={{ height: 700, width: "100%", mt: 2 }}>
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <Button
              onClick={() => navigate("/statistics/create")}
              sx={{ mb: 3 }}
            >
              {t("add_statistic")}
            </Button>
          </Box>
          <StyledDataGrid
            disableColumnMenu
            rows={rows}
            columns={columns}
            pageSize={perPage}
            rowCount={statisticsCount}
            rowsPerPageOptions={[perPage]}
            disableSelectionOnClick
            onPageChange={(pageNumber) => {
              setPage(pageNumber + 1);
            }}
            page={page - 1}
            rowHeight={60}
            paginationMode="server"
            getRowHeight={() => "auto"}
          />
        </Box>
      )}
    </>
  );
};
export default Statistics;
