import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { store } from "../../redux/store";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAssociateGatewayMutation } from "../../redux/slices/condominiumApi";
import { useDispatch, useSelector } from "react-redux";
import { cleanGatewayData } from "../../redux/slices/gatewaySlice";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  setSubCondominiumGatewayModal,
  setTreeExpanded,
  setTreeItem,
} from "../../redux/slices/condominiumSlice";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { Typography, useTheme } from "@mui/material";
import { Avatar, TextField } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";

import { useTranslation } from "react-i18next";
import { setBtnDetector } from "../../redux/slices/condominiumSlice";
import { setGatewayTrashId } from "../../redux/slices/gatewaySlice";
import { loadingOff, loadingOn } from "../../redux/slices/loadingSlice";
import { Link } from "react-router-dom";
import { fromPairs } from "lodash";

const GatewayIndexTable = ({
  data,
  hideAccordion,
  perPage,
  page,
  setPage,
  fromCondominium,
  paramsList,
  setField,
  setOrder,
}) => {
  const theme = useTheme();
  const { id } = useParams();
  const condominiumId = useSelector(
    (state) => state?.condominiumSlice?.condominiumId
  );
  const [sortModel, setSortModel] = useState([]);
  const apartamentName = useSelector(
    (state) => state.condominiumSlice.apartamentName
  );
  const subCondominiumId = useSelector(
    (state) => state?.condominiumSlice?.subCondominiumId
  );
  const gatewayModal = useSelector(
    (state) => state?.condominiumSlice?.subCondominiumGatewayModal
  );
  const treeExpanded = useSelector(
    (state) => state?.condominiumSlice?.treeExpanded
  );
  const condominiumName = useSelector(
    (state) => state?.condominiumSlice?.condominiumName
  );
  const subCondominiumName = useSelector(
    (state) => state?.condominiumSlice?.subCondominiumName
  );
  const condominiumIdentifier = useSelector(
    (state) => state?.condominiumSlice?.condominiumIdentifier
  );
  const subCondominiumIdentifier = useSelector(
    (state) => state?.condominiumSlice?.subCondominiumIdentifier
  );
  const dispatch = useDispatch();
  const [updateCondominiumInfo, { isSuccess: updateSuccess }] =
    store.useUpdateCondominiumIfnoMutation();
  const [getIndexGateway] = store.useLazyGetIndexGatewayQuery();

  const [associateGateway, { isSuccess }] = store.useAssociateGatewayMutation();
  const [deleteGateway, { isSuccess: deleteSuccess }] =
    store.useDeleteGatewayMutation();
  const gatewayTrashId = useSelector((state) => state?.gatewaySlice?.trashId);
  const [trashId, setTrashId] = useState(null);
  const [trashModal, setTrashModal] = useState(null);
  const { t } = useTranslation();
  const [getSubconId] = store.useLazyGetSubCondominiumIdQuery();
  const [serial, setSerial] = React.useState(null);
  const [twinId, setTwinId] = React.useState(null);
  const navigate = useNavigate();

  const [getCindominiumShow] = store.useLazyCondominiumShowQuery();
  const [instanceId, setInstanceId] = React.useState(null);
  const [meterName, setMeterName] = useState(null);
  const metaCount = useSelector((state) => state?.gatewaySlice?.metaCount);
  const onAssociateClick = (params) => {
    /*  dispatch(loadingOn()); */
    setSerial(params.serial);
    setMeterName(params.name);
    dispatch(setTreeItem(serial));

    setTwinId(params.twinId);
    setInstanceId(params.id);
    const meterId = params.id;
    dispatch(setBtnDetector(6));
    associateGateway({
      id: subCondominiumIdentifier,
      attributes: { device_id: +meterId },
    });
  };

  /*   useEffect(() => {
    if (fromCondominium && subCondominiumIdentifier && gatewayModal === true) {
      getSubconId(subCondominiumIdentifier);
    }
  }, [fromCondominium, gatewayModal, subCondominiumIdentifier]); */
  const updateInfo = () => {
    const attributes = {
      fields: {
        coordinates: {
          coordinates: [9.999937060242765, 10.100290313998679],
          type: "Point",
        },
        class: "gateway",

        serial_number: serial,

        condominium_id: condominiumId,
        condominium_name: condominiumName,
        sub_condominium_id: subCondominiumId,

        sub_condominium_name: subCondominiumName,
        condominium_identifier: condominiumIdentifier,
        sub_condominium_identifier: subCondominiumIdentifier,
      },
      meta_data: {},
    };
    updateCondominiumInfo({
      twinId: twinId,
      instanceId: instanceId,
      attributes: attributes,
    });
  };
  React.useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        updateInfo();
      }, 2000);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (gatewayTrashId) {
      deleteGateway(gatewayTrashId);
    }
  }, [gatewayTrashId]);
  useEffect(() => {
    if (deleteSuccess) {
      getIndexGateway({ ...paramsList });
      dispatch(setMessage(t("successfully_deleted")));
      dispatch(setGatewayTrashId(null));
    }
  }, [deleteSuccess]);
  React.useEffect(() => {
    if (updateSuccess && meterName) {
      setTimeout(() => {
        dispatch(setMessage("Gateway associato con successo"));

        const newPath = window.location.pathname + `/apartments/${meterName}`;
        navigate(newPath);
        dispatch(setSubCondominiumGatewayModal(false));
        getCindominiumShow(id);
        dispatch(
          setTreeExpanded([...treeExpanded, subCondominiumName, apartamentName])
        );
        dispatch(loadingOff());
      }, 1000);
    }
  }, [updateSuccess, meterName]);
  const onDeleteClick = (id) => {
    setTrashModal(true);
    setTrashId(id);
  };
  useEffect(() => {}, [data]);
  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: false, flex: 1 },

    {
      field: "name",
      headerName: t("name"),
      type: "number",
      sortable: true,
      headerAlign: "left",

      flex: 4,
    },

    {
      field: "digital_twin_name",
      headerName: t("type"),
      type: "number",
      sortable: true,
      headerAlign: "left",

      flex: 2,
    },
    {
      field: "condominium_name",
      headerName: t("building"),
      type: "number",
      sortable: true,
      headerAlign: "left",

      flex: 4,
      renderCell: (params) => {
        if (params?.value?.id) {
          return (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Link to={`/condominiums/${params?.value?.id}`}>
                <Typography>{params?.value?.name}</Typography>
              </Link>
              {/* 
              <IconButton
                onClick={() => navigate(`/condominiums/${params?.value?.id}`)}
              >
                <VisibilityIcon />
              </IconButton> */}
            </Box>
          );
        } else {
          return "--";
        }
      },
    },
    {
      field: "serial_number",
      headerName: t("serialNumber"),
      type: "number",
      sortable: true,
      headerAlign: "left",
      flex: 2,
    },

    {
      field: "last_message_type",
      headerName: t("last_message_type"),
      type: "number",
      sortable: true,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "number_of_devices_seen_last_communication",
      headerName: t("number_of_devices_seen_last_communication"),
      type: "number",
      sortable: true,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "last_seen_date",
      headerName: t("last_seen_date"),
      type: "number",
      sortable: true,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            {!fromCondominium && (
              <>
                <IconButton
                  sx={{ float: "right" }}
                  onClick={() => onDeleteClick(params.value.id)}
                >
                  <DeleteIcon sx={{ ml: 2, fill: theme.palette.error.main }} />
                </IconButton>
                <Link
                  to={`/gateways/${params.value.id}`}
                  onClick={() => dispatch(cleanGatewayData())}
                >
                  <VisibilityIcon
                    sx={{
                      float: "right",
                      mt: 1,
                      fill: theme.palette.primary.main,
                    }}
                  />
                </Link>
              </>
            )}
            {fromCondominium && (
              <Box sx={{ width: "100%" }}>
                <IconButton
                  sx={{ float: "right" }}
                  onClick={() => onAssociateClick(params.value)}
                >
                  <AddIcon sx={{ ml: 2 }} />
                </IconButton>
              </Box>
            )}
          </Box>
        );
      },
    },
  ];
  const tableRows = data?.map((item) => ({
    id: item.id ? item.id : "--",

    name: item?.attributes?.fields?.name,
    digital_twin_name: item?.attributes?.fields?.digital_twin_name,
    condominium_name: {
      name: item?.attributes?.fields?.condominium_name ?? null,
      id:
        item?.attributes?.fields?.condominium_id &&
        !Array.isArray(item?.attributes?.fields?.condominium_id)
          ? item?.attributes?.fields?.condominium_id
          : null,
    },
    serial_number: item?.attributes?.serial_number
      ? item?.attributes?.serial_number
      : "--",
    last_message_type: item?.attributes?.fields?.last_message_type ?? "--",

    last_seen_date: item?.attributes?.fields?.last_seen_date
      ? t("dt", { val: new Date(item?.attributes?.fields?.last_seen_date) })
      : "--",
    number_of_devices_seen_last_communication:
      item?.attributes?.fields.number_of_devices_seen_last_communication ??
      "--",
    actions: {
      id: item.id,
      serial:
        item.attributes.fields.serial ?? item.attributes.fields.serial_number,
      twinId: item?.attributes?.digital_twin_id,
      name: item.attributes.fields.name ?? item.attributes.fields.name,
    },
  }));
  const handleSortModelChange = (newModel) => {
    setField(newModel[0]?.field);
    setOrder(newModel[0]?.sort);

    setSortModel(newModel);
    getIndexGateway({
      ...paramsList,
      field: newModel[0]?.field,
      order: newModel[0]?.sort,
    });
  };
  return (
    <Box sx={{ height: hideAccordion ? "39vh" : "60vh", width: "99%" }}>
      <DeleteConfirmModal
        trashModal={trashModal}
        setTrashModal={setTrashModal}
        text={t("gateway_delete_text")}
        title={t("gateway_delete_title")}
        deleteFunc={() => setGatewayTrashId(trashId)}
      />
      <StyledDataGrid
        disableColumnMenu
        rows={tableRows ? [...tableRows] : []}
        columns={columns}
        rowCount={metaCount}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        onSortModelChange={handleSortModelChange}
        disableSelectionOnClick
        sortingMode="server"
        onPageChange={(pageNumber) => {
          /* !response.isFetching &&  */ setPage(pageNumber + 1);
        }}
        page={page - 1}
        // loading={isLoadingTable}
        paginationMode="server"
        getRowHeight={() => "auto"}
      />
    </Box>
  );
};

export default GatewayIndexTable;
