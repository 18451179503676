import { useEffect, useState } from "react";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { setUpdating, setStatistic } from "../../redux/slices/statisticsSlice";
import SecurityWidget from "../form/formComponents/customWidgets/SecurityWidget";
import IconButton from "@mui/material/IconButton";
import GroupsSelector from "./GroupsSelector";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import theme from "../../theme";
import DexWidget from "./DexWidget";
import ParametersHandler from "./ParametersHandler";
import { setStatisticTrashModal } from "../../redux/slices/statisticsSlice";
import { setTrashId as setStatisticTrashId } from "../../redux/slices/statisticsSlice";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import StatisticParametersHandler from "./StatisticParametersHandler";
import SqlHighliter from "./SqlHighliter";
import { store } from "../../redux/store";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { Box } from "@material-ui/core";

const ObjectFieldTemplate = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => (
        <Grid key={element.id} alignItems="center" item xs={6}>
          {element.content}
        </Grid>
      ))}
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button type="submit" variant="contained" color="primary">
              {t("update")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={() => dispatch(setStatisticTrashModal(true))}
              sx={{
                background: theme.palette.error.main,
                "&:hover": {
                  background: theme.palette.error.main,
                },
              }}
            >
              {t("delete")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const StatisticShow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateStatistic, { isSuccess: updateSuccess }] =
    store.useUpdateStatisticMutation();
  const [getDex] = store.useLazyGetDexIndexQuery();
  const [deleteStatistic, { isSuccess: deleteSuccess }] =
    store.useDeleteStatisticMutation();
  const statistic = useSelector((state) => state?.statisticsSlice?.statistic);
  const dexIndex = useSelector((state) => state?.dexDataSourceSlice?.dexIndex);
  const [trashModal, setTrashModal] = useState(false);
  const [getStatistic] = store.useLazyGetStatisticShowQuery();
  const statisticTrashModal = useSelector(
    (state) => state?.statisticsSlice?.statisticTrashModal
  );
  const statisticTrashid = useSelector(
    (state) => state?.statisticsSlice?.trashId
  );
  const { id } = useParams();
  useEffect(() => {
    getStatistic(id);
  }, []);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const connectionTypeEnum = [{ const: "Postgres" }];
  const p = [{ const: "Postgress" }, { const: "popa" }];
  useEffect(() => {
    getDex();
    dispatch(setUpdating(true));
    return () => {
      dispatch(setUpdating(false));
      dispatch(setStatistic(null));
    };
  }, []);
  useEffect(() => {
    if (updateSuccess) {
      dispatch(setMessage(t("update_success")));
    }
  }, [updateSuccess]);
  useEffect(() => {
    if (statisticTrashid) {
      deleteStatistic(statisticTrashid);
      dispatch(setStatisticTrashId(null));
    }
  }, [statisticTrashid]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      navigate("/statistics");
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (statistic) {
      dispatch(
        setBreadCrumbs({
          id: statistic?.name,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [statistic]);

  const typeEnum = [{ const: "raw_sql" }, { const: "dex" }];
  const schema = {
    title: "New statistic",
    type: "object",
    properties: {
      name: {
        id: 0,
        type: "string",
        title: t("name"),
        default: statistic?.name,
      },
      description: {
        id: 1,
        type: "string",
        title: t("description"),
        default: statistic?.description,
      },
      connection_type: {
        id: 2,
        type: "string",
        title: t("connection_type"),
        oneOf: [...connectionTypeEnum],
        default: statistic?.connection_type,
      },
      type: {
        id: 3,
        type: "string",
        title: t("type"),
        oneOf: [...typeEnum],
        default: statistic?.type,
      },
      sql: {
        id: 4,
        type: "string",
        title: t("sql"),
        default: statistic?.sql,
      },
      connection_parameters: {
        id: 5,
        type: "object",
        title: t("connection_parameters"),
      },
      groups: {
        id: 6,
        type: "string",
        title: t("groups"),
      },
    },
    /*  required: [
      "name",
      "description",
      "connection_type",
      "type",
      "sql",
      "connection_parameters",
      "groups",
    ], */
  };
  const defaultDexValue = dexIndex
    ?.filter((item) => item?.id == statistic?.raw)
    ?.map((item) => ({
      label: `${item.attributes?.name}`,
      value: item.attributes?.id?.toString(),
    }));

  useEffect(() => {}, [formData]);

  const dexSchema = {
    title: "New statistic",
    type: "object",
    properties: {
      name: {
        id: 0,
        type: "string",
        title: t("name"),
        default: statistic?.name,
      },
      description: {
        id: 1,
        type: "string",
        title: t("description"),
        default: statistic?.description,
      },

      type: {
        id: 3,
        type: "string",
        title: t("type"),
        oneOf: [...typeEnum],
        default: statistic?.type,
      },
      raw: {
        mapFn: (result) =>
          result?.data?.data?.map((item) => ({
            key: item.attributes?.id,
            label: `${item.attributes?.name}`,
            value: item.attributes?.id?.toString(),
          })),
        /*    stateSelector: classStateSelector, */

        url: "dex",
        type: "string",
        title: t("dex"),
        dispatched: true,
        default: defaultDexValue?.[0],
      },

      groups: {
        id: 6,
        type: "string",
        title: t("groups"),
      },
    },
    /*  required: [
      "name",
      "description",
      "connection_type",
      "type",
      "sql",
      "connection_parameters",
      "groups",
    ], */
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,

    description: {
      "ui:options": {
        widget: "textarea",
        minRows: 15,
      },
    },

    sql: {
      "ui:options": {
        widget: "textarea",
        minRows: 15,
      },
    },
    connection_parameters: {
      "ui:field": "parameters",
    },
    groups: {
      "ui:field": "gropus",
    },
    "ui:submitButtonOptions": {
      submitText: t("update"),
    },
    "ui:order": [
      "name",
      "type",
      "groups",
      "connection_type",
      "description",
      "sql",

      "connection_parameters",
    ],
  };
  const dexuiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,

    description: {
      "ui:options": {
        widget: "textarea",
        minRows: 15,
      },
    },

    sql: {
      "ui:options": {
        widget: "textarea",
        minRows: 15,
      },
    },
    raw: {
      "ui:field": "search",
    },
    connection_parameters: {
      "ui:field": "parameters",
    },
    groups: {
      "ui:field": "gropus",
    },
    "ui:submitButtonOptions": {
      submitText: t("update"),
    },
    "ui:order": ["name", "type", "groups", "raw", "description"],
  };
  const fields = {
    parameters: StatisticParametersHandler,
    gropus: GroupsSelector,
    search: DexWidget,
  };
  const onSubmitClick = (val) => {};

  useEffect(() => {}, [formData]);
  const isObjectEmpty = (objectName) => {
    if (formData.type !== "dex") {
      return (
        Object?.keys(objectName)?.length === 0 &&
        objectName?.constructor === Object
      );
    }
  };
  const customValidate = (formData, errors) => {
    if (!formData?.name) {
      errors?.name?.addError("is a required property");
    }
    if (!formData?.description) {
      errors?.description?.addError("is a required property");
    }
    if (!formData?.sql && formData.type !== "dex") {
      errors?.sql?.addError("is a required property");
    }
    if (!formData?.connection_type) {
      errors?.connection_type?.addError("is a required property");
    }
    if (!formData?.type) {
      errors?.type?.addError("is a required property");
    }
    if (formData?.groups?.length < 1) {
      errors?.groups?.addError("is a required property");
    }
    if (
      isObjectEmpty(formData?.connection_parameters) &&
      formData.type !== "dex"
    ) {
      errors?.connection_parameters?.addError("is a required property");
    }

    return errors;
  };
  return (
    <>
      <DeleteConfirmModal
        trashModal={statisticTrashModal}
        setTrashModal={setStatisticTrashModal}
        reduxMode={true}
        text={t("statistic_delete_text")}
        title={t("statistic_delete_title")}
        deleteFunc={() => setStatisticTrashId(id)}
      />
      {statistic && defaultDexValue && (
        <>
          <Typography
            variant="h4"
            sx={{ color: theme?.palette?.primary?.main, mb: 4 }}
          >
            {`${t(t("statistic"))} : ${statistic?.name}`}
          </Typography>
          <Form
            schema={formData.type === "dex" ? dexSchema : schema}
            fields={fields}
            formData={formData}
            customValidate={customValidate}
            showErrorList={false}
            onChange={(changeEvent) => setFormData(changeEvent.formData)}
            ObjectFieldTemplate={(props) => (
              <ObjectFieldTemplate {...props} setTrashModal={setTrashModal} />
            )}
            onSubmit={(values) => {
              updateStatistic({
                attributes: values.formData,
                id: statistic.id,
              });
            }}
            validator={validator}
            noHtml5Validate
            uiSchema={formData.type === "dex" ? dexuiSchema : uiSchema}
            children={true}
          />
        </>
      )}
    </>
  );
};
export default StatisticShow;
