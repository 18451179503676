import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import Chip from "@mui/material/Chip";
import { useDispatch, useSelector } from "react-redux";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { store } from "../../redux/store";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";

const GatewayMessages = ({ idFromCondominium, id, twinId }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const gatewayId = useSelector((state) => state?.gatewaySlice?.gatewayId);
  const messages = useSelector((state) => state?.gatewaySlice?.messages);
  const messagesFirstRender = useSelector(
    (state) => state?.gatewaySlice?.messagesFirstRender
  );
  const messagesMeta = useSelector(
    (state) => state?.gatewaySlice?.messagesMeta
  );
  const [getGatewayMessages, { isSuccess }] =
    store.useLazyGetGatewayMessagesQuery();
  const { t } = useTranslation();
  useEffect(() => {
    if (messagesFirstRender && gatewayId) {
      getGatewayMessages({
        twinId: twinId,
        instanceId: gatewayId,
        params: {
          page: page,
          per_page: perPage,
          order: "desc",
          sort: "message_date",
        },
      });
    }
  }, [messagesFirstRender, gatewayId]);
  useEffect(() => {
    if (!messagesFirstRender) {
      getGatewayMessages({
        twinId: twinId,
        instanceId: gatewayId,
        params: {
          page: page,
          per_page: perPage,
          order: "desc",
          sort: "message_date",
        },
      });
    }
  }, [page, messagesFirstRender]);
  useEffect(() => {}, [idFromCondominium]);
  const columns = [
    {
      field: "id",
      headerName: "ID",

      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "battery_level",
      headerName: t("battery_level"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,

      headerAlign: "left",
    },
    {
      field: "gateway_status",
      headerName: t("gateway_status"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },
    {
      field: "network_quality",
      headerName: t("network_quality"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },
    /*     {
      field: "uptime",
      headerName: t("uptime"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      cellClassName: "word-wrap-cell",
    }, */

    {
      field: "last_message_type",
      headerName: t("last_message_type"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },

    {
      field: "network_technology_in_use",
      headerName: t("network_technology_in_use"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },
    {
      field: "handled_meters",
      headerName: t("handled_meters"),
      type: "тгьиук",
      sortable: false,
      headerAlign: "left",
      flex: 1,
    },
    {
      field: "unhandled_meters",
      headerName: t("unhandled_meters"),
      type: "тгьиук",
      sortable: false,
      headerAlign: "left",
      flex: 1,
    },
    {
      field: "number_of_devices_seen_last_communication",
      headerName: t("number_of_devices_seen_last_communication"),
      type: "number",
      headerAlign: "left",
      flex: 1,
    },
    {
      field: "alarms",
      headerName: t("alarms"),
      type: "string",

      flex: 1,
      editable: false,
      renderCell: (params) => {
        if (params.value !== "--") {
          return params.value?.map((item) => (
            <Chip
              sx={{
                background: theme.palette.error.light,
                color: theme.palette.error.contrastText,
              }}
              label={item}
            />
          ));
        }
      },
    },

    {
      field: "message_date",
      headerName: t("message_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      width: 100,
      wrapText: true,
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <Tooltip title={`${params?.value?.longTime}`}>
            <Typography>{params?.value?.shortTime}</Typography>
          </Tooltip>
        );
      },
    },
  ];
  useEffect(() => {}, [messages]);
  /*  const tableRows = messages?.map((item, i) => ({
    id: i,
    modem_mode: item?.attributes?.values?.values.modem_mode,
    modem_operator: item?.attributes?.values?.values.modem_operator,
    modem_rssi: item?.attributes?.values?.values.modem_rssi,
    uptime: secondsToDhms(item?.attributes?.values?.values.uptime),
    version_hw: item?.attributes?.values?.values.version_hw,
    version_sw: item?.attributes?.values?.values.version_sw,
    message_date: t("dt", { val: new Date(item?.attributes?.message_date) }),
  })); */
  const tableRows = messages?.map((item, i) => ({
    id: i,
    battery_level: item?.attributes?.values?.values.battery_level
      ? `${item?.attributes?.values?.values.battery_level}%`
      : "--",
    gateway_status: item?.attributes?.values?.values.gateway_status ?? "--",
    last_message_type:
      item?.attributes?.values?.values.last_message_type ?? "--",

    network_technology_in_use:
      item?.attributes?.values?.values.network_technology_in_use ?? "--",
    handled_meters:
      item?.attributes?.values?.values?.last_message_type === "data"
        ? !isNaN(item?.attributes?.values?.values.handled_meters)
          ? item?.attributes?.values?.values.handled_meters
          : item?.attributes?.values?.values.handled_meters?.length
        : "--",
    unhandled_meters:
      item?.attributes?.values?.values?.last_message_type === "data"
        ? !isNaN(item?.attributes?.values?.values.unhandled_meters) // nelle versione vecchie mi arriva il array nella versione nuova invece mi arriva un numero
          ? item?.attributes?.values?.values.unhandled_meters
          : item?.attributes?.values?.values.unhandled_meters?.length
        : "--",
    number_of_devices_seen_last_communication:
      item?.attributes?.values?.values
        ?.number_of_devices_seen_last_communication ?? "--",
    alarms: item?.values?.values?.alarms ?? "--",
    network_quality:
      item?.attributes?.values?.values?.network_quality &&
      item?.attributes?.values?.values?.network_quality_type
        ? `${item?.attributes?.values?.values?.network_quality} ${item?.attributes?.values?.values?.network_quality_type}`
        : "--",
    message_date: {
      shortTime: t("date_val", {
        val: new Date(item?.attributes?.message_date),
      }),
      longTime: t("dt", {
        val: new Date(item?.attributes?.message_date),
      }),
    },
  }));

  return (
    <Box sx={{ height: 700, width: "100%" }}>
      <StyledDataGrid
        disableColumnMenu
        rows={tableRows ?? []}
        columns={columns ?? []}
        rowCount={messagesMeta}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        disableSelectionOnClick
        onPageChange={(pageNumber) => {
          /*  !response.isFetching &&  */
          setPage(pageNumber + 1);
        }}
        page={page - 1}
        // loading={isLoadingTable}
        paginationMode="server"
        /*  getRowHeight={() => "auto"} */
      />
    </Box>
  );
};
export default GatewayMessages;
